import { Box, Button, Pagination, useIsMobile } from '@hyphen/hyphen-components';
import { useGetProjectsQuery } from '../../services/projects';
import EmptyList from '../common/EmptyList';
import ProjectListItem from './ProjectListItem';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useOrganization } from '../../providers/OrganizationProvider';
import { Skeleton } from '../common/Skeleton';
import { useOrganizationAbilityContext } from '../auth/OrganizationAbilityProvider';
import { EntityNames } from '../../types/executionContext';

const PAGE_SIZE = 50;

export const ProjectsList = () => {
  const [pageNum, setPageNumber] = useState<number>(1);
  const ability = useOrganizationAbilityContext();
  const isMobile = useIsMobile();

  const canCreateProject = useMemo(() => ability.can('create', EntityNames.Project), [ability]);

  const { id: orgId, isLoading: isOrganizationLoading } = useOrganization();
  const { data, isLoading: isProjectsLoading } = useGetProjectsQuery({
    pageSize: PAGE_SIZE,
    pageNum,
    organizationId: orgId,
  });

  const isLoading = isOrganizationLoading || isProjectsLoading;

  const hasProjects = useMemo(() => (data?.data.length ?? 0) > 0, [data]);

  const gridStyle = useMemo(
    () => (isLoading || hasProjects ? { gridTemplateColumns: 'repeat(auto-fill, minmax(320px, 1fr))' } : {}),
    [isLoading, hasProjects],
  );

  return (
    <Box gap={{ base: '2xl', tablet: '4xl' }} alignItems="flex-start">
      {isLoading ? (
        <Skeleton width="130px" height="40px" />
      ) : (
        canCreateProject && (
          <Button asChild variant="primary">
            <Link to={`/${orgId}/projects/create`}>Create Project</Link>
          </Button>
        )
      )}

      <Box display="grid" width="100" gap={{ base: 'xl', desktop: '2xl' }} direction="row" style={gridStyle}>
        {isLoading ? (
          <Skeleton width="100" height={isMobile ? '89px' : '105px'} />
        ) : hasProjects ? (
          data?.data.map((project) => <ProjectListItem key={project.id} project={project} />)
        ) : (
          <EmptyList
            title="No Projects"
            description="Use apps to manage cloud resources, environment variables, and feature toggles"
            iconName="blocks"
          ></EmptyList>
        )}
        {data && data.total > PAGE_SIZE && (
          <Pagination
            activePage={pageNum}
            itemsPerPage={PAGE_SIZE}
            onChange={setPageNumber}
            totalItemsCount={data?.total ?? 0}
            isCompact
            arePagesVisible
            numberOfPagesDisplayed={3}
          />
        )}
      </Box>
    </Box>
  );
};
