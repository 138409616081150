import { ConnectionsList } from '../integrations/connections/ConnectionsList';
import { Helmet } from 'react-helmet';
import { App } from '../../services/apps';

interface AppConnectionsProps {
  app?: App;
  isLoading: boolean;
}

export default function AppConnections({ app, isLoading }: AppConnectionsProps) {
  if (isLoading || !app) {
    return <Helmet title="Loading Connections" />;
  }

  return (
    <>
      <Helmet title={`${app.name} Integration Connections`} />
      <ConnectionsList
        organizationId={app.organization.id}
        filter={{
          entity: {
            type: 'App',
            id: app.id,
            name: app.name,
          },
          entityType: 'App',
        }}
      />
    </>
  );
}
