// Type definitions
type VarOperand = { var: string };
type SimpleValue = string | number | boolean;
type ArrayValue = string[];

// Tuple types for different operations
type StandardOperation = [VarOperand, SimpleValue];
type ArrayInOperation = [VarOperand, ArrayValue];
type ContainsOperation = [SimpleValue, VarOperand];

type OperandType =
  | {
      var: string;
    }
  | string
  | number
  | boolean
  | string[];

export type LogicCondition = {
  '=='?: StandardOperation;
  '!='?: StandardOperation;
  '>='?: StandardOperation;
  '<='?: StandardOperation;
  in?: ArrayInOperation | ContainsOperation;
  [key: string]: [OperandType, OperandType] | undefined;
};

type Logic = {
  and?: LogicCondition[];
};

export type ParsedCondition = {
  key: string;
  operator: string;
  value: string;
};

export enum Operator {
  equal = '==',
  notEqual = '!=',
  in = 'in',
  contains = 'contains',
  greaterEqual = '>=',
  lessEqual = '<=',
}

export type ConditionInput =
  | {
      key: string;
      operator: Operator.equal | Operator.notEqual | Operator.greaterEqual | Operator.lessEqual | Operator.contains;
      value: SimpleValue;
    }
  | {
      key: string;
      operator: Operator.in;
      value: string;
    };

export const toJsonLogic = (conditions: ConditionInput[]): string => {
  const logicConditions: LogicCondition[] = conditions.map((condition) => {
    if (condition.operator === Operator.contains) {
      return {
        in: [String(condition.value), { var: condition.key }],
      };
    } else if (condition.operator === Operator.in) {
      return {
        in: [{ var: condition.key }, condition.value.split(',').map((v) => v.trim())],
      };
    } else {
      return {
        [condition.operator]: [{ var: condition.key }, condition.value],
      };
    }
  });

  const logic: Logic = {
    and: logicConditions,
  };

  return JSON.stringify(logic);
};

export const parseJsonLogic = (logic: string): ParsedCondition[] => {
  let parsedLogic: Logic;

  try {
    parsedLogic = JSON.parse(logic);
  } catch (error) {
    throw new Error('Invalid JSON logic format. Please check the target syntax.');
  }

  const conditions = parsedLogic.and || [];

  return conditions.map((condition: LogicCondition) => {
    const operator = Object.keys(condition)[0];
    const operands = condition[operator] as [OperandType, OperandType];

    if (!operands || !Array.isArray(operands) || operands.length !== 2) {
      throw new Error('Invalid logic format');
    }

    if (operator === 'in') {
      // Case 1: Regular 'in' operator with array
      if (isVarOperand(operands[0]) && Array.isArray(operands[1])) {
        return {
          key: operands[0].var,
          operator: 'in',
          value: operands[1].join(','),
        };
      }
      // Case 2: String contains operation
      else if (typeof operands[0] === 'string' && isVarOperand(operands[1])) {
        return {
          key: operands[1].var,
          operator: 'contains',
          value: operands[0],
        };
      }
    }

    // Handle standard operators
    if (isVarOperand(operands[0])) {
      return {
        key: operands[0].var,
        operator,
        value: String(operands[1]),
      };
    }

    throw new Error('Invalid logic format');
  });
};

// Type guard functions
function isVarOperand(operand: OperandType): operand is VarOperand {
  return typeof operand === 'object' && operand !== null && 'var' in operand;
}
