import {
  Drawer,
  DrawerContent,
  Box,
  useBreakpoint,
  Spinner,
  Heading,
  Card,
  useOpenClose,
  toast,
  DrawerHeader,
  DrawerCloseButton,
  DrawerTitle,
} from '@hyphen/hyphen-components';
import { useNavigate, useParams } from 'react-router-dom';
import { Organization } from '../../services/organization';
import { useOrganization } from '../../providers/OrganizationProvider';
import { useOrganizationAbilityContext } from '../auth/OrganizationAbilityProvider';
import { useCallback } from 'react';
import { useDeleteMemberMutation, useGetMemberQuery } from '../../services/member';
import { ApiError } from '../ApiError';
import EditOrgMemberForm from './EditOrgMemberForm';
import { DeleteItemCard } from '../common/DeleteItemCard';
import DeleteMemberConfirmationModal from './DeleteMemberConfirmationModal';
import MemberConnections from './MemberConnections';
import { Helmet } from 'react-helmet';

export default function OrgMemberDrawer() {
  const { memberId } = useParams();
  const { organization = {} as Organization } = useOrganization();
  const navigate = useNavigate();
  const { isOpen: isDeleteModalOpen, handleOpen: openDeleteModal, handleClose: closeDeleteModal } = useOpenClose();
  const [deleteMember, { isLoading: isDeleting, error: deleteError }] = useDeleteMemberMutation();
  const { isPhone, isTablet } = useBreakpoint();
  const ability = useOrganizationAbilityContext();

  const {
    data: member,
    isLoading,
    error,
  } = useGetMemberQuery({
    organizationId: organization.id,
    memberId: memberId || '',
  });

  const canDeleteMember = member && ability.can('delete', member);
  const canUpdateMember = member && ability.can('update', member);

  const handleDismiss = useCallback(() => {
    navigate(`/${organization.id}/settings/members`);
  }, [navigate, organization.id]);

  const handleDelete = useCallback(async () => {
    try {
      const result = await deleteMember({ memberId: memberId || '', organizationId: organization.id });
      if (!result.error) {
        toast.success(`Member removed`);
        closeDeleteModal();
        handleDismiss();
      } else {
        toast.error(`Remove member failed`);
      }
    } catch (e) {
      toast.error(`Remove member failed`);
    }
  }, [deleteMember, memberId, organization.id, closeDeleteModal, handleDismiss]);

  const drawerTitle = error
    ? 'Member not found'
    : isLoading
    ? 'Loading...'
    : member?.firstName || member?.lastName
    ? `${member.firstName} ${member.lastName}`
    : member?.email;

  return (
    <>
      <Helmet title={drawerTitle}></Helmet>
      <Drawer
        isOpen
        onDismiss={handleDismiss}
        ariaLabel="environment details"
        width={isPhone ? '90%' : isTablet ? '70%' : '60%'}
      >
        <DrawerHeader>
          <DrawerTitle gap="xs">
            {drawerTitle}
            <Box fontSize="sm" color="secondary" fontWeight="normal">
              {member?.email}
            </Box>
          </DrawerTitle>
          <DrawerCloseButton onClose={handleDismiss} />
        </DrawerHeader>
        <DrawerContent gap={{ base: '2xl', tablet: '3xl' }}>
          {isLoading && <Spinner />}
          {error && <ApiError error={error} />}
          {!isLoading && member && (
            <>
              {canUpdateMember && (
                <Card style={{ flexShrink: '0' }}>
                  <Card.Section gap="2xs">
                    <Heading as="h4" size="sm">
                      Organization Membership Info
                    </Heading>
                    <Box as="p" color="secondary" fontSize="sm">
                      Contact info that appears to other members of this organization.
                    </Box>
                  </Card.Section>
                  <Card.Section>
                    <EditOrgMemberForm member={member} />
                  </Card.Section>
                </Card>
              )}
              <MemberConnections member={member} organization={organization} />
              {canDeleteMember && (
                <DeleteItemCard
                  itemLabel="Member"
                  description="Deleting a member removes their access to this organizations resources."
                  onDelete={openDeleteModal}
                />
              )}
            </>
          )}
          {canDeleteMember && member && (
            <DeleteMemberConfirmationModal
              memberEmail={member.email}
              organizationName={organization.name}
              isOpen={isDeleteModalOpen}
              onClose={closeDeleteModal}
              onConfirm={handleDelete}
              isLoading={isDeleting}
              error={deleteError}
            />
          )}
        </DrawerContent>
      </Drawer>
    </>
  );
}
