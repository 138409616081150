import { IntegrationSetupForm } from '../common/IntegrationSetupForm';
import { githubIntegrationConfig, IntegrationType } from '../../../../types/integrations';
import * as yup from 'yup';
import { useSearchParams } from 'react-router-dom';

interface GithubSetupFormProps {
  organizationId: string;
}

const githubSetupFormSchema = yup.object().shape({
  installationId: yup.string().required('Required'),
  verificationEmail: yup.string().email('Invalid email').required('Required'),
});

export const GithubSetupForm = ({ organizationId }: GithubSetupFormProps) => {
  const [searchParams] = useSearchParams();

  searchParams.get('installationId');
  const GithubFormConfig = [
    {
      id: 'installationId',
      type: 'text',
      label: 'GitHub Installation ID',
      initialValue: searchParams.get('installationId') || '',
    },
    {
      id: 'verificationEmail',
      type: 'email',
      label: 'Backup Verification Email',
      initialValue: '',
      helpText:
        'If an integration connection cannot be automatically setup, an email will be sent to this address where it can be verified manually.',
    },
  ];

  return (
    <IntegrationSetupForm
      organizationId={organizationId}
      validationSchema={githubSetupFormSchema}
      formConfig={GithubFormConfig}
      configMapper={(values: githubIntegrationConfig) => ({
        installationId: values.installationId,
        verificationEmail: values.verificationEmail,
      })}
      integrationType={IntegrationType.github}
    />
  );
};
