import { Favorite } from '../types/members';

export const getFavoriteUrl = (organizationId: string, favorite: Favorite): string => {
  switch (favorite.type) {
    case 'Project':
      return `/${organizationId}/${favorite.alternateId}`;
    case 'App':
      return `/${organizationId}/${favorite.project?.alternateId}/app/${favorite.id}`;
    case 'Team':
      return `/${organizationId}/teams/${favorite.id}`;
    case 'LinkCode':
      return `/${organizationId}/link/${favorite.id}`;
    case 'Toggle':
      return `/${organizationId}/${favorite.project?.alternateId}/toggles/${favorite.key}`;
    default:
      return `/${organizationId}`;
  }
};
