import { Box, Card } from '@hyphen/hyphen-components';
import React from 'react';
import { Link } from 'react-router-dom';
import { ToggleType } from '../../types/toggle';

const DefaultReturnValueCard = React.memo(
  ({
    returnType = ToggleType.Boolean,
    defaultReturnValue,
    canUpdateToggle = false,
  }: {
    returnType?: ToggleType;
    defaultReturnValue: any;
    canUpdateToggle?: boolean;
  }) => (
    <Box as="li" fontSize="sm" direction="row" alignItems="center" gap="lg">
      <Card shadow="0">
        <Box
          direction={{ base: 'column', tablet: 'row' }}
          alignItems={{ tablet: 'center' }}
          padding="2xl"
          gap={{ base: 'md', tablet: '3xl', desktop: '4xl' }}
        >
          <Box alignItems="flex-start" gap="sm">
            {canUpdateToggle ? <Link to="settings">Default Return Value</Link> : 'Default Return Value'}
          </Box>
          <Box
            padding="md"
            fontSize="sm"
            as="code"
            background="primary"
            flex="auto"
            fontFamily="monospace"
            color={
              returnType === ToggleType.Boolean && defaultReturnValue === true
                ? 'success'
                : returnType === ToggleType.Boolean && defaultReturnValue === false
                ? 'danger'
                : 'base'
            }
          >
            <pre>{defaultReturnValue !== undefined ? defaultReturnValue.toString() : 'N/A'}</pre>
          </Box>
        </Box>
      </Card>
    </Box>
  ),
);

export default DefaultReturnValueCard;
