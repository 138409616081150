import { toast, useOpenClose } from '@hyphen/hyphen-components';
import { ConfirmModal } from '../common/ConfirmModal';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { DeleteItemCard } from '../common/DeleteItemCard';
import { useOrganizationAbilityContext } from '../auth/OrganizationAbilityProvider';
import { useDeleteToggleMutation } from '../../services/toggle';
import { useOrganization } from '../../providers/OrganizationProvider';
import { useToggle } from '../../providers/ToggleProvider';

export default function DeleteToggle() {
  const { toggle } = useToggle();
  const { id: orgId } = useOrganization();
  const [deleteToggle, { isLoading: isDeleting, error }] = useDeleteToggleMutation();
  const ability = useOrganizationAbilityContext();

  const navigate = useNavigate();

  const { isOpen: isDeleteModalOpen, handleOpen: openDeleteModal, handleClose: closeDeleteModal } = useOpenClose();

  const handleDelete = useCallback(async () => {
    if (!toggle) return;

    const { error } = await deleteToggle({
      organizationId: orgId,
      projectId: toggle.project.alternateId,
      toggleKey: toggle.key,
    });
    if (!error) {
      toast.success('Toggle deleted');
      closeDeleteModal();
      navigate(`/${orgId}/${toggle.project.alternateId}/toggles`, { replace: true });
    }
  }, [toggle, deleteToggle, orgId, closeDeleteModal, navigate]);

  if (!toggle) return null;

  const canDeleteToggle = ability.can('delete', toggle);

  if (!canDeleteToggle) return null;

  return (
    <>
      <DeleteItemCard itemLabel="Toggle" onDelete={openDeleteModal} />
      <ConfirmModal
        message={`Are you sure you want to delete ${toggle.key}?`}
        confirmButtonLabel="Delete"
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={handleDelete}
        isLoading={isDeleting}
        error={error}
        customErrorMessages={{ default: 'Delete toggle failed' }}
      />
    </>
  );
}
