import React, { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { useGetMemberQuery } from '../services/member';
import { useOrganization } from './OrganizationProvider';
import { useGetExecutionContextQuery } from '../services/executionContext';
import { Organization } from '../services/organization';
import { Member } from '../types/members';

interface IOrganizationMemberContext {
  isLoading: boolean;
  member: Member | undefined;
}

export const OrganizationMemberContext = createContext<IOrganizationMemberContext>({
  isLoading: true,
  member: undefined,
});

export const OrganizationMemberProvider = (props: PropsWithChildren) => {
  const { children } = props;
  const { organization = {} as Organization } = useOrganization();
  const [memberId, setMemberId] = useState<string | null>(null);

  const { data: executionContextData, isLoading: isExecutionContextLoading } = useGetExecutionContextQuery(
    organization.id,
    {
      skip: !organization.id,
    },
  );

  useEffect(() => {
    if (executionContextData?.member.id) {
      setMemberId(executionContextData.member.id);
    }
  }, [executionContextData]);

  const { data: member, isLoading: isMemberLoading } = useGetMemberQuery(
    { memberId: memberId || '', organizationId: organization.id },
    { skip: !memberId },
  );

  const value = useMemo(() => {
    return {
      isLoading: isExecutionContextLoading || isMemberLoading,
      member,
    };
  }, [isExecutionContextLoading, isMemberLoading, member]);

  return <OrganizationMemberContext.Provider value={value}>{children}</OrganizationMemberContext.Provider>;
};

export const useOrganizationMember = () => useContext(OrganizationMemberContext);
