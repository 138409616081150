import { Box, BoxProps } from '@hyphen/hyphen-components';
import classNames from 'classnames';

export const Skeleton = ({
  children,
  background = 'secondary',
  radius = 'sm',
  width = '8xl',
  ...rest
}: BoxProps) => {
  const classes = classNames('pulse', rest.className);

  return (
    <Box className={classes} background={background} radius={radius} width={width} {...rest}>
      {children}
    </Box>
  );
};
