import React from 'react';
import { Box, Button, DropdownMenu, DropdownMenuContent, DropdownMenuTrigger } from '@hyphen/hyphen-components';
import { Link, To } from 'react-router-dom';
import BadgePopover, { BadgePopoverProps } from './BadgePopover';

interface ListItemProps {
  title: {
    label: string;
    link?: To;
  };
  badge?: BadgePopoverProps;
  subtitle?: React.ReactNode;
  infoText?: React.ReactNode;
  children?: React.ReactNode;
  dropdown?: React.ReactNode;
  reserveDropdownBtnSpace?: boolean;
}

export const ListItem = ({
  badge,
  children,
  title,
  subtitle,
  infoText,
  dropdown,
  reserveDropdownBtnSpace = false,
}: ListItemProps) => {
  const renderBadge = () =>
    badge && (
      <Box width="72px">
        <BadgePopover {...badge} />
      </Box>
    );

  const renderTitle = () => (
    <Box fontSize={{ base: 'sm', tablet: 'md' }} fontWeight="bold" direction="row" gap="lg">
      {title?.link ? <Link to={title.link}>{title.label}</Link> : title?.label}
    </Box>
  );

  const renderSubtitle = () =>
    subtitle && (
      <Box fontSize={{ base: 'xs', tablet: 'sm' }} color="secondary" className="truncate" width="100">
        {subtitle}
      </Box>
    );

  const renderInfoText = () =>
    infoText && (
      <Box flex="auto" alignItems="flex-start" fontSize={{ base: 'xs', tablet: 'sm' }} color="secondary">
        {infoText}
      </Box>
    );

  const renderDropdown = () => {
    if (dropdown) {
      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="tertiary" size="sm" iconPrefix="dots" aria-label="Open menu" />
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" side="bottom">
            {dropdown}
          </DropdownMenuContent>
        </DropdownMenu>
      );
    }
    if (reserveDropdownBtnSpace) {
      return <Box width="36px" height="24px" />;
    }
    return null;
  };

  return (
    <Box className="row-item" borderWidth="sm 0 0 0" borderColor="subtle">
      <Box
        alignItems="center"
        direction="row"
        gap={{ base: 'lg', tablet: '2xl' }}
        padding={{ base: 'xl 2xl', tablet: '2xl' }}
      >
        <Box
          flex="auto"
          direction={{ base: 'column', desktop: 'row' }}
          gap={{ base: 'md', tablet: 'lg', desktop: '2xl' }}
          alignItems={{ desktop: 'center' }}
        >
          {renderBadge()}
          <Box
            childGap="xs"
            minWidth={{ base: '100', desktop: '40' }}
            width={infoText || children ? { base: '100', desktop: '40' } : undefined}
            alignItems="flex-start"
          >
            {renderTitle()}
            {renderSubtitle()}
          </Box>
          {renderInfoText()}
          {children}
        </Box>
        {renderDropdown()}
      </Box>
    </Box>
  );
};
