import { MicrosoftTeamsIntegration } from '../../../types/integrations';
import { IntegrationCard } from './common/IntegrationCard';

interface MicrosoftTeamsDetailsProps {
  integration: MicrosoftTeamsIntegration;
}

export const MicrosoftTeamsDetails = ({ integration }: MicrosoftTeamsDetailsProps) => {
  return (
    <IntegrationCard
      status="Connected"
      details={[
        { label: 'Tenant Id', value: integration.config.azureTenantId },
        { label: 'Tenant Name', value: integration.config.name },
        { label: 'Default Team Id', value: integration.config.defaultTeamId },
      ]}
      integration={integration}
    />
  );
};

export default MicrosoftTeamsDetails;
