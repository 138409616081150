import { Box, Popover, Icon, toast, useOpenClose, BoxProps } from '@hyphen/hyphen-components';

interface ClickToCopyProps extends Omit<BoxProps, 'children'> {
  /**
  The text to be copied to the clipboard.
   */
  text: string;
  /**
   * The text to be displayed on the button if different than the text to be copied.
   */
  displayText?: string;
  /**
   * Show/hide the copy icon.
   */
  hideIcon?: boolean;
}

const ClickToCopy = (props: ClickToCopyProps) => {
  const { displayText, text, hideIcon = false, fontSize = 'sm', ...restProps } = props;
  const { isOpen: isCopyPopoverOpen, handleClose: closeCopyPopover, handleOpen: openCopyPopover } = useOpenClose();

  const handleCopy = () => {
    toast.success('Copied to clipboard');
    navigator.clipboard.writeText(text || '');
  };

  return (
    <Popover
      content="copy"
      isOpen={isCopyPopoverOpen}
      placement="top"
      offsetFromTarget={4}
      contentContainerProps={{
        padding: 'md',
        color: 'inverse',
        background: 'inverse',
        fontSize: 'xs',
      }}
    >
      <Box
        as="button"
        onMouseOver={openCopyPopover}
        onMouseOut={closeCopyPopover}
        onFocus={openCopyPopover}
        onBlur={closeCopyPopover}
        direction="row"
        gap="xs"
        padding="sm"
        radius="xs"
        fontSize={fontSize}
        color="base"
        background="secondary"
        borderWidth="0"
        cursor="pointer"
        fontFamily="monospace"
        onClick={handleCopy}
        textAlign="left"
        hover={{
          background: 'tertiary',
        }}
        {...restProps}
      >
        <Box flex="auto">{displayText || text}</Box>
        {!hideIcon && <Icon name="copy-document" color="secondary" />}
      </Box>
    </Popover>
  );
};

export default ClickToCopy;
