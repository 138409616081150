import React from 'react';
import {
  Box,
  BoxProps,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerProvider,
  DrawerTitle,
  DrawerTrigger,
  useBreakpoint,
} from '@hyphen/hyphen-components';
import DateTimeDisplay from '../DateTime';
import { CodeBlock } from '../Codeblock';
import { timeAgo } from '../../utils/dateUtils';
import { ToggleUsage } from '../../types/toggle';

interface ToggleUsageDrawerProps {
  display: 'context';
  evaluationEvent: ToggleUsage;
}

const ToggleUsageDrawer: React.FC<ToggleUsageDrawerProps> = ({ display, evaluationEvent }) => {
  const { isPhone, isTablet } = useBreakpoint();

  return (
    <DrawerProvider>
      <Drawer width={isPhone ? '90%' : isTablet ? '70%' : '60%'} ariaLabel="evaluationContext">
        <DrawerHeader>
          <Box gap="sm">
            <DrawerTitle>{`${evaluationEvent.data.toggle.key}`} Usage Context</DrawerTitle>
            <Box direction="row" gap="sm" alignItems="center" fontSize="sm">
              {evaluationEvent.context.application}
              <Box
                textAlign="left"
                borderWidth="0"
                padding="sm"
                background="secondary"
                direction="row"
                gap="xs"
                alignItems="center"
                radius="md"
                fontSize="sm"
                fontWeight="normal"
              >
                <Box fontFamily="monospace" flex="auto">
                  {evaluationEvent.context.environment}
                </Box>
              </Box>
            </Box>
          </Box>
          <DrawerCloseButton />
        </DrawerHeader>
        <DrawerContent alignItems="stretch" gap={{ base: '2xl', desktop: '3xl' }}>
          <Box direction="row" gap="lg">
            <div>{timeAgo(evaluationEvent.meta.createdAt)}</div> |
            <DateTimeDisplay value={evaluationEvent.meta.createdAt} />
          </Box>
          <UsageDetail title="Targeting Key" content={evaluationEvent.context.targetingKey} />
          <UsageDetail title="Value" content={evaluationEvent.data.toggle.value} />
          {<UsageDetail title="Reason" content={evaluationEvent.data.toggle.reason} maxHeight="8xl" />}
          <UsageDetail
            minHeight="8xl"
            title="Context"
            content={evaluationEvent.context}
            flex="auto"
          />
        </DrawerContent>
      </Drawer>
      <DrawerTrigger asChild>
        <Box
          as="button"
          color="base"
          background="transparent"
          borderWidth="0"
          padding="sm"
          fontFamily="monospace"
          className="truncate"
          radius="sm"
          textAlign="left"
          hover={{ background: 'secondary' }}
          cursor="pointer"
        >
          {JSON.stringify(evaluationEvent[display])?.substring(0, 20)}&hellip;
        </Box>
      </DrawerTrigger>
    </DrawerProvider>
  );
};

export default ToggleUsageDrawer;

const UsageDetail = ({ title, content, ...restProps }: { title: string; content: any } & BoxProps) => (
  <Box gap="sm" {...restProps}>
    <Box fontWeight="medium">{title}</Box>
    {content === false || content ? (
      <CodeBlock>{JSON.stringify(content, null, 2)}</CodeBlock>
    ) : (
      <Box fontFamily="monospace" fontSize="sm">
        n/a
      </Box>
    )}
  </Box>
);