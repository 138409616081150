import { NewRelicIntegration } from '../../../types/integrations';
import { IntegrationCard } from './common/IntegrationCard';

interface NewRelicDetailsProps {
  integration: NewRelicIntegration;
}

export const NewRelicDetails = ({ integration }: NewRelicDetailsProps) => {
  return (
    <IntegrationCard
      status="Connected"
      details={[
        { label: 'Account Name', value: integration.config.accountName },
        { label: 'Account ID', value: integration.config.accountId.toString() },
      ]}
      integration={integration}
    />
  );
};

export default NewRelicDetails;
