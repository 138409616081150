import { Box, Button, Card, Spinner } from '@hyphen/hyphen-components';
import { useGetTeamsQuery } from '../../services/teams';
import { Link } from 'react-router-dom';
import { TeamItem } from './TeamItem';
import EmptyList from '../common/EmptyList';
import { useOrganizationAbilityContext } from '../auth/OrganizationAbilityProvider';
import { EntityNames } from '../../types/executionContext';

interface TeamsListProps {
  organizationId: string;
}

export const TeamsList = ({ organizationId }: TeamsListProps) => {
  const { isLoading, data: teams } = useGetTeamsQuery({ organizationId: organizationId });
  const teamsData = teams?.data || [];
  const ability = useOrganizationAbilityContext();
  const canCreateATeam = ability.can('create', EntityNames.Team);

  if (isLoading || !teams) {
    return <Spinner />;
  }

  return (
    <>
      {canCreateATeam && (
        <Box gap="md" direction="row" alignItems="center">
          <Link to={`/${organizationId}/teams/create`}>
            <Button size="md" disabled={isLoading}>
              Create Team
            </Button>
          </Link>
        </Box>
      )}
      {teamsData.length > 0 ? (
        <Card>
          {teamsData.map((team) => (
            <TeamItem key={team.id} team={team} />
          ))}
        </Card>
      ) : (
        <EmptyList title="No Teams" description="Create a team to get started" />
      )}
    </>
  );
};
