import React, { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { Environment, useGetEnvironmentsQuery } from '../services/environments';
import { ListResponse } from '../services/types';
import { useOrganization } from './OrganizationProvider';

export interface ProjectEnvironment {
  alternateId: string;
  color: string;
  id: string;
}

export interface ProjectEnvironments {
  [projectId: string]: {
    [envName: string]: ProjectEnvironment;
  };
}

interface IProjectEnvironmentsContext {
  projectEnvironmentsData: ListResponse<Environment> | undefined;
  projectEnvironments: ProjectEnvironments | undefined;
  projectEnvironmentsError: any;
  projectEnvironmentsIsLoading: boolean;
}

const ProjectEnvironmentsContext = createContext<IProjectEnvironmentsContext>({
  projectEnvironmentsData: undefined,
  projectEnvironments: undefined,
  projectEnvironmentsError: undefined,
  projectEnvironmentsIsLoading: true,
});

export interface ProjectEnvironmentsProviderProps extends PropsWithChildren {}

export const ProjectEnvironmentsProvider = ({ children }: ProjectEnvironmentsProviderProps) => {
  const { id: orgId } = useOrganization();

  const {
    data: projectEnvironmentsData,
    error: projectEnvironmentsError,
    isLoading: projectEnvironmentsIsLoading,
  } = useGetEnvironmentsQuery({
    organizationId: orgId,
  });

  const projectEnvironments = useMemo(() => {
    const environments: ProjectEnvironments = {};
    projectEnvironmentsData?.data?.forEach((env) => {
      if (!environments[env.project.id]) {
        environments[env.project.id] = {};
      }
      environments[env.project.id][env.name] = {
        alternateId: env.alternateId,
        color: env.color,
        id: env.id,
      };
    });
    return environments;
  }, [projectEnvironmentsData]);

  return (
    <ProjectEnvironmentsContext.Provider
      value={{
        projectEnvironments,
        projectEnvironmentsData,
        projectEnvironmentsError,
        projectEnvironmentsIsLoading,
      }}
    >
      {children}
    </ProjectEnvironmentsContext.Provider>
  );
};

export const useProjectEnvironments = () => {
  const context = useContext(ProjectEnvironmentsContext);
  if (!context) {
    throw new Error('useProjectEnvironments must be used within a ProjectEnvironmentProvider');
  }
  return context;
};
