import { Badge, BadgeVariant } from '@hyphen/hyphen-components';
import { useMemo } from 'react';
import { Connection } from '../../../types/connections';

const ConnectionStatusBadge = ({ status }: { status: Connection['status'] }) => {
  const badgeVariant: BadgeVariant = useMemo(() => {
    switch (status) {
      case 'Ready':
        return 'green';
      case 'Pending':
      case 'Verifying':
        return 'yellow';
      default:
        return 'red';
    }
  }, [status]);

  const badgeMessage = useMemo(() => {
    switch (status) {
      case 'Ready':
        return 'Connected';
      case 'Pending':
        return 'Pending';
      case 'Verifying':
        return 'Verifying';
      default:
        return 'Error';
    }
  }, [status]);

  return <Badge variant={badgeVariant} message={badgeMessage} size="sm" />;
};

export default ConnectionStatusBadge;
