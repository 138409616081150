import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { CreatePaymentMethodForm } from './CreatePaymentMethodForm';
import { Appearance } from '@stripe/stripe-js';
// @ts-ignore - Design tokens
import uiTokens from '@hyphen/hyphen-design-tokens/build/json/variables';

export interface CreatePaymentMethodProps {
  clientSecret: string;
  returnUrl: string;
}

export function CreatePaymentMethod({ clientSecret, returnUrl }: CreatePaymentMethodProps) {
  const stripeKey = process.env.REACT_APP_STRIPE_KEY || '';
  const stripePromise = loadStripe(stripeKey);

  const borderColor = 'rgba(255, 255, 255, 0.1)';

  const appearance: Appearance = {
    theme: 'stripe',
    labels: 'floating',
    variables: {
      borderRadius: uiTokens.size['border-radius'].sm.value,
      colorDanger: '#ffcccb',
      colorBackground: '#ffffff0d',
      colorText: '#ffffffb3',
      colorTextSecondary: '#ffffff80',
      colorTextPlaceholder: '#ffffff4d',
      colorPrimary: '#ffffffb3',
    },
    rules: {
      '.Tab': {
        border: `1px solid ${borderColor}`,
        borderRadius: 'var(--borderRadius)',
      },
      '.Input': {
        border: `1px solid ${borderColor}`,
        borderRadius: 'var(--borderRadius)',
        padding: `${uiTokens.size.spacing.xs.value} ${uiTokens.size.spacing.lg.value}`,
      },
      '.Input:focus': {
        // border: `1px solid ${uiTokens.color.border.active[colorScheme]}`,
        // borderColor: uiTokens.color.border.hover[colorScheme],
      },
    },
  };

  return (
    <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
      <CreatePaymentMethodForm returnUrl={returnUrl} />
    </Elements>
  );
}
