import { QueryParams } from '../services/types';

export interface Code {
  id: string;
  code: string;
  long_url: string;
  domain: string;
  title: string;
  createdAt: string;
  tags?: string[];
}

export interface CodeStats {
  clicks: {
    total: number;
    unique: number;
    byDay: {
      date: string;
      total: number;
      unique: number;
    }[];
  };
  referrals: {
    url: string;
    total: number;
  }[];
  browsers: {
    name: string;
    total: number;
  }[];
  devices: {
    name: string;
    total: number;
  }[];
  locations: {
    country: string;
    total: number;
    unique: number;
  }[];
}

export interface CreateCodeRequestBody {
  domain: string;
  long_url: string;
  title?: string;
  tags?: string[];
  code?: string;
}

export interface GetCodesQueryParams extends QueryParams {
  tags?: string[];
  search?: string;
  organizationId?: string;
}

export interface UpdateCodeRequestBody {
  long_url?: string;
  title?: string;
  tags?: string[];
}

export enum Status {
  Pending = 'pending',
  Ready = 'ready',
}

export enum DnsStatus {
  Pending = 'pending',
  Verified = 'verified',
}

export enum SslStatus {
  Pending = 'pending',
  Generating = 'generating',
  Enabled = 'enabled',
}

export interface Domain {
  id: string;
  domain: string;
  status: Status;
  dnsStatus: DnsStatus;
  sslStatus: SslStatus;
  createdAt: string;
}

export interface DNS {
  ipAddress: string[];
  hosts: string[];
}

export interface QRCodeRequestBody {
  title?: string;
  organizationId?: string;
  options?: {
    size?: string;
    color?: string;
    backgroundColor?: string;
    logo?: string;
  };
}

export interface QRCode {
  id: string;
  qrCode: string;
  qrLink: string;
  title: string;
}
