import { IntegrationConnectionId } from '@hyphen/nucleus/dist/types';
import { QueryParams } from '../services/types';
import { EntityType } from './executionContext';
import { IntegrationType } from './integrations';
import { Organization } from '../services/organization';
import { Project } from '../services/projects';

export enum ConnectionType {
  DistributionList = 'DistributionList',
  Channel = 'Channel',
  PermissionGroup = 'PermissionGroup',
  Folder = 'Folder',
  Tag = 'Tag',
  CloudWorkspace = 'CloudWorkspace',
  CodeRepository = 'CodeRepository',
  ProjectBoard = 'ProjectBoard',
  CodeRegistry = 'CodeRegistry',
  User = 'User',
  IncidentManagement = 'IncidentManagement',
}

export type Connection = {
  id: string;
  type: ConnectionType;
  entity: {
    id: string;
    type: EntityType;
    name: string;
  };
  organizationIntegration: {
    id: string;
    type: IntegrationType;
  };
  status: 'Ready' | 'Pending' | 'Error' | 'Verifying';
  errors?: string[];
  config: Record<string, any>;
  input?: string;
  organization: Pick<Organization, 'id' | 'name'>;
  project?: Pick<Project, 'id' | 'name' | 'alternateId'>;
};

export type NewConnection = {
  type: ConnectionType;
  entity: {
    id: string;
  };
  input?: string;
};

export interface GetConnectionsQueryParams extends QueryParams {
  organizationId: string;
  entityIds?: string[];
  types?: ConnectionType[];
  integrationTypes?: IntegrationType[];
}

export type ConnectionAccess = {
  id: string;
  integrationType: IntegrationType;
  entityConnection: {
    id: IntegrationConnectionId;
    type: ConnectionType;
    entity: {
      id: string;
      type: EntityType;
      name: string;
    };
  };
  assignmentConnection: {
    id: IntegrationConnectionId;
    type: ConnectionType;
    entity: {
      id: string;
      type: EntityType;
      name: string;
    };
  };
  organization: Pick<Organization, 'id' | 'name'>;
  status: 'Pending' | 'Deleting' | 'Ready' | 'Deleted' | 'CreationError' | 'DeletingError';
  errors?: string[];
  config: Record<string, any>;
};

export interface GetConnectionsAccessQueryParams extends QueryParams {
  organizationId: string;
  entityConnectionIds?: string[];
  assignmentConnectionIds?: string[];
  entityIds?: string[];
  assignmentIds?: string[];
}
