import React from 'react';
import { Box, Button, FormikSelectInputNative, FormikTextInput, toast } from '@hyphen/hyphen-components';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { InferType } from 'yup';
import { useCreateQrCodeMutation } from '../../services/link/codes';
import { Code, QRCodeRequestBody } from '../../types/domain';
import { useOrganization } from '../../providers/OrganizationProvider';
import { ApiError } from '../ApiError';
import { PreviewQRCode } from './PreviewQRCode';

const createQRSchema = yup.object().shape({
  title: yup.string(),
  size: yup.string().optional(),
  color: yup.string().optional(),
  backgroundColor: yup.string().optional(),
  logo: yup
    .mixed<File>()
    .test('fileSize', 'The file size must be less than 1MB', (file) => {
      return file && file.size <= 1024 * 1024;
    })
    .test('fileDimensions', 'The image must be no larger than 1000x1000 pixels', (file) => {
      if (!file) return true;

      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = () => {
          const img = new Image();
          img.onload = () => {
            resolve(img.width <= 1000 && img.height <= 1000);
          };
          img.onerror = () => resolve(false);
          if (typeof reader.result === 'string') {
            img.src = reader.result;
          }
        };
        reader.onerror = () => resolve(false);
        reader.readAsDataURL(file);
      });
    }),
});

export type CreateQRSchema = InferType<typeof createQRSchema>;

const initialValues: CreateQRSchema = {
  title: '',
  logo: undefined,
  size: 'small',
  color: '#000000',
  backgroundColor: '#ffffff',
};

const options = [
  {
    value: 'small',
    label: 'Small (300x300)',
  },
  {
    value: 'medium',
    label: 'Medium (512x512)',
  },
  {
    value: 'large',
    label: 'Large (1024x1024)',
  },
];

type CreateQRFormProps = {
  showQr: (arg0: boolean) => void;
  codeId: string;
  codeData: Code;
};

const customErrorMessages = {
  default: 'Failed to create QR Code',
};

export const CreateQRForm = ({ showQr, codeId, codeData }: CreateQRFormProps) => {
  const [createQrsCode, { error }] = useCreateQrCodeMutation();
  const { organization } = useOrganization();

  const handleCreateQR = async (values: CreateQRSchema) => {
    const body: QRCodeRequestBody = { ...values, organizationId: organization?.id };
    const { error, data } = await createQrsCode({ codeId, data: body });

    if (!error && data) {
      toast.success('QR Code created successfully');
      showQr(false);
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={createQRSchema} onSubmit={handleCreateQR}>
      {({ errors, isSubmitting, setFieldValue }) => (
        <Box
          background="secondary"
          padding="4xl"
          radius="md"
          direction={{ base: 'column', tablet: 'row' }}
          gap={{ base: '2xl', tablet: '4xl' }}
        >
          <Box width={{ base: '100', desktop: '50' }}>
            <Form noValidate>
              <Box gap="2xl">
                <Field id="title" name="title" label="Title" component={FormikTextInput} error={errors.title} />
                <Field id="size" name="size" label="Size" component={FormikSelectInputNative} options={options} />
                <Box>
                  <label htmlFor="logo" className="upload-label">
                    Upload Logo
                  </label>
                  <input
                    type="file"
                    id="logo"
                    disabled={isSubmitting}
                    className="upload-input"
                    accept="image/*"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const file = event.currentTarget.files?.[0];
                      if (file) {
                        setFieldValue('logo', file);
                      }
                    }}
                  />
                  <ErrorMessage name="logo" component="div" className="error-message" />
                </Box>
                <Box>
                  <Box as="label" htmlFor="color" margin="0 0 xs" fontSize="sm">
                    Foreground Color
                  </Box>
                  <Field type="color" name="color" id="color" disabled={isSubmitting} />
                </Box>
                <Box>
                  <Box as="label" htmlFor="backgroundColor" margin="0 0 xs" fontSize="sm">
                    Background color
                  </Box>
                  <Field type="color" name="backgroundColor" id="backgroundColor" disabled={isSubmitting} />
                </Box>
                {error ? <ApiError error={error} customMessages={customErrorMessages} /> : null}
                <Box direction="row" gap="sm">
                  <Button variant="primary" isLoading={isSubmitting} type="submit">
                    Create QR Code
                  </Button>
                  <Button variant="tertiary" isDisabled={isSubmitting} onClick={() => showQr(false)}>
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Form>
          </Box>
          <Box>
            <PreviewQRCode code={codeData} />
          </Box>
        </Box>
      )}
    </Formik>
  );
};
