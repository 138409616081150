import { Fragment } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import {
  Box,
  Button,
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  Heading,
} from '@hyphen/hyphen-components';
import LogicalOperator from './LogicalOperator';
import RuleFields from './RuleFields';
import { InferType } from 'yup';
import * as Yup from 'yup';
import ReturnValueField from './ReturnValueField';
import { ToggleReturnType, ToggleType } from '../../types/toggle';
import { Operator } from '../../utils/parseJsonLogic';

const STANDARD_CONTEXTS = ['app', 'environment', 'ipAddress', 'targetingKey', 'user.email', 'user.id', 'user.name'];

const targetFormSchema = Yup.object().shape({
  id: Yup.string(),
  parsedLogic: Yup.array()
    .of(
      Yup.object().shape({
        key: Yup.string().required('Key is required'),
        operator: Yup.string()
          .oneOf(Object.values(Operator), 'Operator must be one of ==, !=, in, contains, >=, <=')
          .required('Operator is required'),
        value: Yup.string()
          .required('Value is required')
          .when(['key', 'operator'], {
            is: (key: string, operator: string) => key === 'user.email' && operator === Operator.in,
            then: (schema) =>
              schema.test('is-valid-emails', 'Must be a comma separated list of valid email addresses', (value) =>
                value
                  ? value.split(',').every((email: string) => Yup.string().email().isValidSync(email.trim()))
                  : false,
              ),
            otherwise: (schema) =>
              schema.when(['key', 'operator'], {
                is: (key: string, operator: string) => key === 'user.email' && operator !== Operator.contains,
                then: (schema) => schema.email('Must be a valid email address'),
                otherwise: (schema) => schema,
              }),
          })
          .when('operator', {
            is: (operator: string) => operator === Operator.in,
            then: (schema) =>
              schema.test('is-valid-list', 'Must be a comma separated list of values', (value) =>
                value ? value.split(',').every((item) => item.trim().length > 0) : false,
              ),
          }),
        contextFieldType: Yup.string().oneOf(['', 'custom', 'userCustom']),
      }),
    )
    .min(1, 'At least one context field is required')
    .required('At least one context is required'),
  defaultValue: Yup.mixed().required('Return value is required'),
});

export type TargetFormSchema = InferType<typeof targetFormSchema>;

interface TargetFormProps {
  initialValues: TargetFormSchema;
  returnType: ToggleReturnType;
  isLoading: boolean;
  onSubmit: (values: TargetFormSchema) => void;
}

const TargetForm = ({ initialValues, returnType, isLoading, onSubmit }: TargetFormProps) => {
  const handleSubmit = (values: TargetFormSchema) => {
    // RadioGroup only handles values as strings
    // Convert string boolean values back to boolean
    if (returnType === ToggleType.Boolean) {
      values.defaultValue = values.defaultValue === 'true';
    }
    onSubmit(values);
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,
        defaultValue:
          returnType === ToggleType.Boolean ? String(initialValues.defaultValue) : initialValues.defaultValue,
      }}
      enableReinitialize
      validationSchema={targetFormSchema}
      validateOnBlur={false}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, values, errors }) => (
        <Form noValidate className="display-flex g-3xl flex-direction-column">
          <Box gap="lg" flex="auto" alignItems="flex-start">
            <Heading as="h2" size="sm">
              1. Define target criteria
            </Heading>
            <Box width="100" padding="0 0 0 lg">
              <FieldArray name="parsedLogic">
                {({ remove, push }) => (
                  <Box as="ol" gap="lg" padding="0" width="100">
                    {values.parsedLogic.map((_context: any, index: number) => (
                      <Fragment key={`context.${index}`}>
                        <RuleFields index={index} onRemove={() => remove(index)} />
                        {index < values.parsedLogic.length - 1 && (
                          <LogicalOperator isDragging={false} operator="AND" />
                        )}
                      </Fragment>
                    ))}
                    <Box display="block">
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button variant="secondary" iconSuffix="caret-sm-down" size="sm">
                            Add Context Field
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="start" side="bottom">
                          <Box maxHeight="7xl" overflow="auto">
                            {STANDARD_CONTEXTS.map((context) => (
                              <DropdownMenuItem
                                key={context}
                                onSelect={() =>
                                  push({ key: context, operator: '==', value: '', contextFieldType: '' })
                                }
                                disabled={values.parsedLogic.some((logic: any) => logic.key === context)}
                              >
                                {context}
                              </DropdownMenuItem>
                            ))}
                          </Box>
                          <DropdownMenuSeparator />
                          <DropdownMenuItem
                            onSelect={() =>
                              push({
                                key: '',
                                operator: '==',
                                value: '',
                                contextFieldType: 'custom',
                              })
                            }
                          >
                            customAttribute
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            onSelect={() =>
                              push({
                                key: '',
                                operator: '==',
                                value: '',
                                contextFieldType: 'userCustom',
                              })
                            }
                          >
                            user.customAttribute
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                      {errors.parsedLogic && typeof errors.parsedLogic === 'string' && (
                        <Box margin="md 0 0 0" color="danger" fontSize="sm">
                          {errors.parsedLogic}
                        </Box>
                      )}
                    </Box>
                  </Box>
                )}
              </FieldArray>
            </Box>
          </Box>
          <Box gap="lg" flex="auto">
            <Heading as="h2" size="sm">
              2. If all criteria are met, return the following
            </Heading>
            <Box flex="auto" padding="0 0 0 lg">
              <ReturnValueField
                toggleType={returnType}
                defaultValue={returnType === ToggleType.Boolean ? String(values.defaultValue) : values.defaultValue}
                setFieldValue={setFieldValue}
                errors={errors}
                isSubmitting={false}
              />
            </Box>
          </Box>
          <Box alignItems="flex-start">
            <Button variant="primary" type="submit" isLoading={isLoading}>
              Save
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default TargetForm;
