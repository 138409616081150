import {
  SidebarMenuItem,
  SidebarMenuButton,
  DropdownMenu,
  DropdownMenuTrigger,
  SidebarMenuAction,
  Icon,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  Box,
} from '@hyphen/hyphen-components';
import { Link } from 'react-router-dom';
import { useFavoriteHandler } from '../../hooks/useFavoriteHandler';
import { useOrganizationMember } from '../../providers/OrganizationMemberProvider';
import { useOrganization } from '../../providers/OrganizationProvider';
import { NavLink } from 'react-router-dom';
import { Favorite } from '../../types/members';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { EntityIcon } from '../common/EntityIcon';

interface FavoriteItemProps {
  favorite: Favorite;
  onClick: () => void;
  link: string;
}

const FavoriteItem = ({ favorite, link, onClick }: FavoriteItemProps) => {
  const { member } = useOrganizationMember();
  const { id: orgId } = useOrganization();
  const { handleFavorite } = useFavoriteHandler({
    favoriteId: favorite.id,
    member: member!,
    orgId: orgId,
  });

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: favorite.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div className="group fade-in" ref={setNodeRef} style={style}>
      <SidebarMenuItem>
        <Box
          position="absolute"
          className="drag-handle opacity-0 group-hover:opacity-100 transition-opacity"
          cursor="ns-resize"
          style={{ top: '50%', transform: 'translateY(-50%)' }}
          {...attributes}
          {...listeners}
        >
          <Icon name="grab" size="lg" />
        </Box>
        <SidebarMenuButton onClick={onClick} asChild className="p-left-xl">
          <NavLink to={link} className="navlink" end>
            <EntityIcon type={favorite.type} size="lg" color="tertiary" />
            {'name' in favorite ? favorite.name : favorite.key}
          </NavLink>
        </SidebarMenuButton>
        <DropdownMenu>
          <DropdownMenuTrigger className="opacity-0 group-hover:opacity-100 transition-opacity" asChild>
            <SidebarMenuAction>
              <Box background="secondary" padding="2xs">
                <Icon name="dots" />
                <span className="sr-only">More</span>
              </Box>
            </SidebarMenuAction>
          </DropdownMenuTrigger>
          <DropdownMenuContent side="bottom" align="end">
            <DropdownMenuItem asChild>
              <Link to={link}>View</Link>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem onClick={handleFavorite}>Remove</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </div>
  );
};

export default FavoriteItem;
