import React, { createContext, PropsWithChildren, useContext } from 'react';
import { useOrganization } from './OrganizationProvider';
import { useParams } from 'react-router-dom';
import { Connection } from '../types/connections';
import { Organization } from '../services/organization';
import { useGetConnectionQuery } from '../services/connections';
import { IntegrationConnectionId, OrganizationId, OrganizationIntegrationId } from '@hyphen/nucleus/dist/types';

interface IConnectionContext {
  isLoading: boolean;
  error: any;
  connection?: Connection;
  params: {
    integrationId?: OrganizationIntegrationId;
    connectionId?: IntegrationConnectionId;
    organizationId?: OrganizationId;
  };
}

const ConnectionContext = createContext<IConnectionContext>({
  isLoading: true,
  error: undefined,
  connection: undefined,
  params: {},
});

export interface ConnectionProviderProps extends PropsWithChildren {}

export const ConnectionProvider = ({ children }: ConnectionProviderProps) => {
  const { organization = {} as Organization } = useOrganization();
  const { integrationId, connectionId } = useParams<{ integrationId: string; connectionId: string }>();
  const {
    isLoading: isConnectionLoading,
    data: connection = {} as Connection,
    error: connectionError,
  } = useGetConnectionQuery(
    {
      organizationId: organization.id,
      integrationId: integrationId!,
      connectionId: connectionId!,
    },
    { skip: !organization.id || !integrationId || !connectionId },
  );

  return (
    <ConnectionContext.Provider
      value={{
        isLoading: isConnectionLoading,
        error: connectionError,
        connection,
        params: {
          connectionId: connectionId as IntegrationConnectionId,
          organizationId: organization.id as OrganizationId,
          integrationId: integrationId as OrganizationIntegrationId,
        },
      }}
    >
      {children}
    </ConnectionContext.Provider>
  );
};

export const useConnection = () => {
  const context = useContext(ConnectionContext);
  if (!context) {
    throw new Error('useConnection must be used within a ConnectionProvider');
  }
  return context;
};
