import { Box, Card, Alert, Spinner } from '@hyphen/hyphen-components';
import { LabelValue } from '../LabelValue';
import { Organization, useGetOrganizationBillingAccountQuery } from '../../services/organization';
import { useOrganization } from '../../providers/OrganizationProvider';
import { useEffect, useState } from 'react';
import { useAuth } from '../auth/authProvider';
import { Link } from 'react-router-dom';

export const ViewBillingAccount = () => {
  const { organization = {} as Organization } = useOrganization();
  const { user } = useAuth();
  const { isLoading, data: billingAccount } = useGetOrganizationBillingAccountQuery(organization.id);

  const [isOwner, setIsOwner] = useState(false);

  useEffect(() => {
    if (billingAccount) {
      const isOwner = billingAccount?.owners?.some((owner) => owner.userId === user?.userId);
      setIsOwner(isOwner);
    }
  }, [billingAccount, user?.userId]);

  return (
    <>
      {isLoading && <Spinner />}
      {billingAccount && (
        <Box width="100" maxWidth="9xl" gap="4xl" fontSize="sm">
          <Card>
            <Box direction="row" justifyContent="space-between" alignItems="center">
              <Card.Header title="Billing Profile" />
              {isOwner && (
                <Link to={`/billing/${billingAccount.id}`} state={{ backOrgId: organization.id }}>
                  <Box fontSize={{ base: 'xs', desktop: 'sm' }} fontWeight="bold" padding="2xl">
                    Manage
                  </Box>
                </Link>
              )}
            </Box>
            <Card.Section gap="xl">
              <LabelValue label="Account Name" value={billingAccount.name} />
              <LabelValue label="Phone" value={billingAccount.phone} />
              <LabelValue label="Email" value={billingAccount.email} />
              <LabelValue
                label="Address"
                value={
                  <>
                    <Box as="p">{billingAccount.address?.streetAddress}</Box>
                    <Box as="p">
                      {[
                        billingAccount.address?.locality,
                        billingAccount.address?.region,
                        billingAccount.address?.postalCode,
                        billingAccount.address?.country,
                      ]
                        .filter(Boolean) // Remove undefined or null values
                        .join(', ')}
                    </Box>
                  </>
                }
              />
            </Card.Section>
          </Card>
          {billingAccount.paymentMethods.length ? (
            <Card>
              <Card.Header title="Payment Method" />
              <Card.Section gap="xl">
                <LabelValue
                  label={billingAccount.paymentMethods[0].type === 'Card' ? 'Credit Card' : 'Bank Account'}
                  value={
                    billingAccount.paymentMethods[0].type === 'Card'
                      ? `************${billingAccount.paymentMethods[0].last4}`
                      : '****************'
                  }
                />
              </Card.Section>
            </Card>
          ) : (
            <Alert hasIcon variant="warning" title="No payment method found" />
          )}
        </Box>
      )}
    </>
  );
};
