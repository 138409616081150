import { useIsMobile } from '@hyphen/hyphen-components';
import { Route, Routes, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PageHeader from '../../../components/PageHeader';
import { ScrollableMainLayout } from '../../../components/ScrollableMainLayout';
import { useOrganization } from '../../../providers/OrganizationProvider';
import { Organization } from '../../../services/organization';
import { useGetAppQuery } from '../../../services/apps';
import ClickToCopy from '../../../components/common/ClickToCopy';
import AppOverview from './AppOverview';
import AppSettings from './AppSettings';
import Error from '../../../Error';
import { TabNav, TabNavLink } from '../../../components/TabNav';
import { useOrganizationAbilityContext } from '../../../components/auth/OrganizationAbilityProvider';
import EnvEnvironmentDrawer from '../../../components/env/EnvEnvironmentDrawer';
import AppConnections from '../../../components/app/AppConnections';
import { BreadCrumbPortal } from '../../../components/common/Breadcrumb';
import { useMemo } from 'react';
import { Skeleton } from '../../../components/common/Skeleton';

export default function AppContainer() {
  const { appId } = useParams<{ appId: string }>();
  const { organization = {} as Organization } = useOrganization();
  const { data: app, error, isLoading } = useGetAppQuery({ appId: appId || '', organizationId: organization.id });
  const isMobile = useIsMobile();
  const ability = useOrganizationAbilityContext();

  const canUpdateApp = !isLoading && app && ability.can('update', app);

  const pageHeaderProps = useMemo(() => {
    if (isLoading) {
      return {
        title: <Skeleton height={isMobile ? '25px' : '40px'} width="200px" />,
        description: <Skeleton height="30px" width="100px" />,
        favoriteId: '',
      };
    }
    return {
      title: app?.name,
      description: <ClickToCopy text={app?.alternateId || ''} fontSize="xs" />,
      favoriteId: app?.id,
    };
  }, [isLoading, app?.name, app?.alternateId, app?.id, isMobile]);

  if (!isLoading && error) return <Error />;

  return (
    <ScrollableMainLayout fullWidth>
      <Helmet title={`${app?.name}`} />
      <BreadCrumbPortal entity={app} />
      <PageHeader {...pageHeaderProps} />

      {isLoading ? (
        <Skeleton width="332px" height="48px" />
      ) : (
        <TabNav className="fade-in">
          <TabNavLink to="">Overview</TabNavLink>
          <TabNavLink to="connections">Connections</TabNavLink>
          {canUpdateApp && <TabNavLink to="settings">Settings</TabNavLink>}
        </TabNav>
      )}

      <Routes>
        <Route path="/*" element={<AppOverview isLoading={isLoading} app={app} />}>
          <Route path=":pevrId/*" element={<EnvEnvironmentDrawer />} />
        </Route>
        <Route path="connections/*" element={<AppConnections app={app} isLoading={isLoading} />} />
        <Route path="settings/*" element={<AppSettings isLoading={isLoading} app={app} />} />
      </Routes>
    </ScrollableMainLayout>
  );
}
