import { Box, Card, Button } from '@hyphen/hyphen-components';
import { useState, useMemo } from 'react';
import ToggleUsageItem from './ToggleUsageItem';
import { ToggleSummary } from '../../types/toggle';
import { Skeleton } from '../common/Skeleton';
import EmptyList from '../common/EmptyList';

const INITIAL_MAX_ITEMS = 10;

interface ProjectToggleUsageListProps {
  orgId: string;
  project: ToggleSummary['project'] | undefined;
  toggles: ToggleSummary['toggles'] | undefined;
  showAllToggles?: boolean;
  isLoading: boolean;
}

const ProjectToggleUsageList = ({
  orgId,
  project,
  toggles,
  showAllToggles = false,
  isLoading,
}: ProjectToggleUsageListProps) => {
  const [showAll, setShowAll] = useState(showAllToggles);

  const toggleKeys = useMemo(() => Object.keys(toggles || {}), [toggles]);
  const visibleToggles = useMemo(
    () => (showAll ? toggleKeys : toggleKeys.slice(0, INITIAL_MAX_ITEMS)),
    [showAll, toggleKeys],
  );

  if (isLoading) {
    return (
      <Card>
        {Array.from({ length: INITIAL_MAX_ITEMS }).map((_, index) => (
          <Box
            key={index}
            className="row-item"
            borderWidth="sm 0 0 0"
            padding="xl lg"
            gap="sm"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Skeleton height="16px" width="200px" />
            <Skeleton height="20px" width="50px" />
            <Skeleton height="16px" width="160px" />
            <Skeleton height="16px" width="160px" />
          </Box>
        ))}
      </Card>
    );
  }

  if (toggleKeys.length === 0) return <EmptyList title="No feature toggles" iconName="logo-toggle" />;

  return (
    <Card className="fade-in" flex="auto" fontSize="sm">
      {project && toggles && (
        <ToggleUsageItems orgId={orgId} project={project} visibleToggles={visibleToggles} toggles={toggles} />
      )}
      {!showAllToggles && toggleKeys.length > INITIAL_MAX_ITEMS && (
        <Box borderWidth="sm 0 0 0" alignItems="flex-start" padding="lg">
          <Button onClick={() => setShowAll(!showAll)} variant="secondary" size="sm">
            {showAll ? 'Show Less' : `Show All (${toggleKeys.length})`}
          </Button>
        </Box>
      )}
    </Card>
  );
};

export default ProjectToggleUsageList;

interface ToggleUsageItemsProps {
  orgId: string;
  project: ToggleSummary['project'];
  visibleToggles: string[];
  toggles: ToggleSummary['toggles'];
}

const ToggleUsageItems = ({ orgId, project, visibleToggles, toggles }: ToggleUsageItemsProps) => (
  <>
    {visibleToggles.map((toggleKey) => (
      <ToggleUsageItem
        key={toggleKey}
        orgId={orgId}
        project={project}
        toggleKey={toggleKey}
        toggleData={toggles[toggleKey]}
      />
    ))}
  </>
);
