import { Box, Spinner } from '@hyphen/hyphen-components';
import { useEffect } from 'react';
import { IAuthContext } from './authProvider';

type AuthPageProps = {
  isLoading: IAuthContext['isLoading'];
  isAuthenticated: IAuthContext['isAuthenticated'];
  login: IAuthContext['login'];
};

function AuthPage({ isLoading, isAuthenticated, login }: AuthPageProps) {
  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      login();
    }
  }, [isAuthenticated, login, isLoading]);

  if (isLoading) {
    return (
      <Box
        alignItems="center"
        background="primary"
        color="base"
        fontSize="sm"
        height="100vh"
        justifyContent="center"
        padding="lg"
      >
        <Spinner />
      </Box>
    );
  }

  if (!isLoading && !isAuthenticated) {
    return (
      <Box
        alignItems="center"
        background="primary"
        color="base"
        fontSize="sm"
        height="100vh"
        justifyContent="center"
        padding="lg"
      >
        Redirecting to log in page...
      </Box>
    );
  }
  return null;
}

export default AuthPage;
