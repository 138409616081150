import { Helmet } from 'react-helmet';
import { useEffect, useState, useMemo } from 'react';
import { Box, Spinner } from '@hyphen/hyphen-components';
import PageHeader from '../../components/PageHeader';
import { ScrollableMainLayout } from '../../components/ScrollableMainLayout';
import { EnvCliInstructions } from '../../components/env/EnvCliInstructions';
import { AppsList } from '../../components/env/AppsList';
import { useOrganization } from '../../providers/OrganizationProvider';
import { App, useGetAppsQuery } from '../../services/apps';
import { Organization } from '../../services/organization';
import { ListResponse } from '../../services/types';
import { ReactSVG } from 'react-svg';
import { ProjectEnvironmentsProvider, useProjectEnvironments } from '../../providers/ProjectEnvironmentsProvider';
import { BreadCrumbPortal } from '../../components/common/Breadcrumb';

const PAGE_SIZE = 50;

const Envs = () => {
  const [pageNum, setPageNumber] = useState<number>(1);
  const { organization = {} as Organization } = useOrganization();
  const {
    data: apps = {} as ListResponse<App>,
    error,
    isLoading,
  } = useGetAppsQuery({ pageSize: PAGE_SIZE, pageNum, organizationId: organization.id }) || {};

  const {
    projectEnvironmentsData: environments,
    projectEnvironmentsError: environmentsError,
    projectEnvironmentsIsLoading: environmentsIsLoading,
  } = useProjectEnvironments();

  useEffect(() => {
    if (!isLoading && !error && apps && !environmentsError && !environmentsIsLoading && environments) {
      setPageNumber(apps.pageNum);
    }
  }, [apps.pageNum, error, isLoading, environmentsError, environmentsIsLoading, environments, apps]);

  const appsWithEnvironments = useMemo(
    () => ({
      pageNum: apps.pageNum,
      pageSize: apps.pageSize,
      total: apps.total,
      data:
        apps?.data?.map((app) => ({
          ...app,
          environments: environments?.data?.filter((env) => env.project.id === app.project.id) || [],
        })) || [],
    }),
    [apps, environments],
  );

  return (
    <ScrollableMainLayout fullWidth>
      <Helmet>
        <title>ENV Secrets Manager</title>
      </Helmet>

      <PageHeader
        title={
          <Box width="6xl">
            <ReactSVG
              src="/images/env-logo.svg"
              beforeInjection={(svg) => svg.setAttribute('style', 'width: 100%; height: 100%')}
            />
          </Box>
        }
        description="End-to-end encrypted environment secrets management"
      />
      <BreadCrumbPortal />
      {isLoading || environmentsIsLoading ? (
        <Box gap="md" direction="row" alignItems="center">
          <Spinner size="md" />
          Loading...
        </Box>
      ) : apps?.data?.length === 0 ? (
        <EnvCliInstructions />
      ) : (
        <AppsList apps={appsWithEnvironments} pageNum={pageNum} setPageNumber={setPageNumber} pageSize={PAGE_SIZE} />
      )}
    </ScrollableMainLayout>
  );
};

function EnvDashboard() {
  return (
    <ProjectEnvironmentsProvider>
      <Envs />
    </ProjectEnvironmentsProvider>
  );
}

export default EnvDashboard;
