import { Box, Button, Card } from '@hyphen/hyphen-components';
import { Link } from 'react-router-dom';
import { ListItem } from '../../../components/ListItem';
import { useOrganizationAbilityContext } from '../../../components/auth/OrganizationAbilityProvider';
import { useProject } from '../../../providers/ProjectProvider';
import { Skeleton } from '../../../components/common/Skeleton';
import { useMemo } from 'react';
import EmptyList from '../../../components/common/EmptyList';

export default function ProjectOverview() {
  const { project, isLoading } = useProject();
  const ability = useOrganizationAbilityContext();

  const canUpdateProject = useMemo(
    () => (!isLoading && project ? ability.can('update', project) : false),
    [project, ability, isLoading],
  );

  const hasApps = useMemo(() => (project?.apps?.length ?? 0) > 0, [project]);

  return (
    <Box alignItems="flex-start" gap="xl">
      {isLoading ? (
        <Skeleton width="88px" height="32px" />
      ) : canUpdateProject ? (
        <Button asChild variant="primary" size="sm">
          <Link to={`/${project?.organization.id}/app/create?projectId=${project?.id}`}>Create App</Link>
        </Button>
      ) : null}

      <Box
        width="100"
        display="grid"
        gap={{ base: 'xl', desktop: '2xl' }}
        direction="row"
        style={{
          ...(isLoading || hasApps ? { gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))' } : {}),
        }}
      >
        {isLoading ? (
          <>
            {Array.from({ length: 5 }).map((_, index) => (
              <Skeleton key={index} width="100" height="58px" />
            ))}
          </>
        ) : hasApps ? (
          project?.apps?.map((app) => (
            <Card key={app.id} className="fade-in">
              <ListItem
                title={{
                  label: app.name,
                  link: `/${project?.organization?.id}/${project?.alternateId}/app/${app.alternateId}`,
                }}
                reserveDropdownBtnSpace={false}
              />
            </Card>
          ))
        ) : (
          <EmptyList title="No project apps" iconName="block" />
        )}
      </Box>
    </Box>
  );
}
