import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { InferType } from 'yup';
import { Box, Button, FormikSelectInputNative, FormikTextInput, toast } from '@hyphen/hyphen-components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useOrganization } from '../../providers/OrganizationProvider';
import { useCreateAppMutation } from '../../services/apps';
import { DependentFormikField } from '../common/form/DependentFormikField';
import { ApiError } from '../ApiError';
import { useGetProjectsQuery } from '../../services/projects';
import { generateSlug } from '../../utils/generateSlug';
import { forbiddenWords } from '../../utils/validations';

const createAppSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  alternateId: yup
    .string()
    .matches(/^[a-zA-Z0-9-_]+$/, 'Invalid Alternate ID')
    .notOneOf(forbiddenWords, `Can not contain the following words: ${forbiddenWords.join(', ')}`)
    .required('Alternate ID is required'),
  projectId: yup.string().required('Project is required'),
});

type CreateAppSchema = InferType<typeof createAppSchema>;

export const CreateAppForm = ({ organizationId }: { organizationId: string }) => {
  const navigate = useNavigate();
  const { organization } = useOrganization();
  const [createEnvApp, { error }] = useCreateAppMutation();
  const [searchParams] = useSearchParams();

  const projectId = searchParams.get('projectId') ?? undefined;

  const { data, isLoading } = useGetProjectsQuery({ pageSize: 100, pageNum: 1, organizationId: organizationId });

  const handleCreateApp = async (values: CreateAppSchema) => {
    const body = {
      name: values.name,
      alternateId: values.alternateId,
    };
    const { error, data } = await createEnvApp({ organizationId, projectId: values.projectId, body });
    if (!error) {
      toast.success('App created', { duration: 5000 });
      navigate(`/${organization?.id}/${data.project.alternateId}/app/${data.alternateId}`);
    }
  };

  const projectOptions =
    data?.data.map((project) => ({
      label: project.name,
      value: project.id,
    })) ?? [];

  const initialValues = {
    projectId: projectId ?? data?.data[0].id ?? '',
    name: '',
    alternateId: '',
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={createAppSchema}
      onSubmit={handleCreateApp}
    >
      {({ errors, isSubmitting }) => (
        <Form noValidate>
          <Box gap="4xl">
            <Field
              label="Project"
              name="projectId"
              id="projectId"
              options={projectOptions}
              component={FormikSelectInputNative}
              error={errors.projectId}
              isRequired
              isDisabled={isLoading}
            />
            <Field id="name" name="name" label="Name" component={FormikTextInput} error={errors.name} isRequired />
            <DependentFormikField
              id="alternateId"
              helpText="Used as the name on the command line, alphanumeric and dashes only, no spaces"
              name="alternateId"
              label="Alternate ID"
              component={FormikTextInput}
              error={errors.alternateId}
              isRequired
              dependentField="name"
              targetField="alternateId"
              generateValue={generateSlug}
            />
            <Box direction="row" gap="sm">
              <Button variant="primary" isLoading={isSubmitting} type="submit">
                Create App
              </Button>
              <Button variant="tertiary" isDisabled={isSubmitting} onClick={() => navigate(-1)}>
                Cancel
              </Button>
            </Box>
            {error ? <ApiError error={error} /> : null}
          </Box>
        </Form>
      )}
    </Formik>
  );
};
