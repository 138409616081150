import { api } from '../api';
import { ListResponse, QueryParams } from '../types';
import { DNS, Domain, Status as DomainStatus } from '../../types/domain';

export interface CreateDomainRequest {
  params: {
    organizationId: string;
  };
  body: {
    domain: string;
  };
}

export const domainApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createDomain: builder.mutation<Domain, CreateDomainRequest>({
      query: ({ params, body }) => ({
        url: `api/organizations/${params.organizationId}/domains`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'Domains', id: 'LIST' }],
    }),
    getDomains: builder.query<ListResponse<Domain>, QueryParams>({
      query: ({ pageSize, pageNum, organizationId } = {}) => {
        const params = new URLSearchParams();
        if (pageSize) params.append('pageSize', pageSize.toString());
        if (pageNum) params.append('pageNum', pageNum.toString());

        return {
          url: `api/organizations/${organizationId}/domains?${params.toString()}`,
          method: 'GET',
        };
      },
      transformResponse(
        baseQueryReturnValue: ListResponse<Domain>,
        meta = undefined,
        arg: QueryParams,
      ): ListResponse<Domain> | Promise<ListResponse<Domain>> {
        if (arg.verified) {
          baseQueryReturnValue.data = baseQueryReturnValue.data.filter(
            (domain) => domain.status === DomainStatus.Ready,
          );
        }
        return baseQueryReturnValue;
      },
      providesTags: (result) =>
        result
          ? [...result.data.map(({ id }) => ({ type: 'Domains' as const, id })), { type: 'Domains', id: 'LIST' }]
          : [{ type: 'Domains', id: 'LIST' }],
    }),
    deleteDomain: builder.mutation<void, { domainId: string; organizationId?: string }>({
      query: ({ domainId, organizationId }) => ({
        url: `api/organizations/${organizationId}/domains/${domainId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error) => [{ type: 'Domains', id: 'LIST' }],
    }),
    verifySsl: builder.mutation({
      query: ({ domainId, organizationId }) => ({
        url: `api/organizations/${organizationId}/domains/${domainId}/verify`,
        method: 'POST',
        body: { organizationId },
      }),
      invalidatesTags: () => [{ type: 'Domains', id: 'LIST' }],
    }),
    getDnsConfig: builder.query<DNS, string | undefined>({
      query: (organizationId) => ({
        url: `api/organizations/${organizationId}/domains/dns-config`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useCreateDomainMutation,
  useGetDomainsQuery,
  useDeleteDomainMutation,
  useVerifySslMutation,
  useGetDnsConfigQuery,
} = domainApi;
