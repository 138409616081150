import { useCallback, useEffect, useState } from 'react';
import { Box, Button, FormikTextInput, Icon, toast } from '@hyphen/hyphen-components';
import { Helmet } from 'react-helmet';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { InferType } from 'yup';
import { useLocation } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import { DomainVerification } from '../../components/domains/DomainVerification';
import { useCreateDomainMutation } from '../../services/link/domains';
import { CreateDomainRequest } from '../../services/link/domains';
import { domainRegex } from '../../utils/validations';
import { useOrganization } from '../../providers/OrganizationProvider';
import { ApiError } from '../../components/ApiError';

interface DomainsProps {
  isBeingVerified?: boolean;
}

const domainSchema = yup.object().shape({
  domain: yup
    .string()
    .max(32, 'Domain should not exceed 32 characters')
    .matches(domainRegex, 'Please, provide a valid domain')
    .required('Domain is required'),
});

type DomainFormSchema = InferType<typeof domainSchema>;

const customMessages = {
  409: 'Unable to add domain. This domain already exists for another organization.',
  default: 'Failed to save domain. Please try again later.',
};

export const Domains = ({ isBeingVerified }: DomainsProps) => {
  const [createDomain, { error }] = useCreateDomainMutation();
  const { organization } = useOrganization();

  const location = useLocation();
  const { domain } = location?.state || {};

  const [step, setStep] = useState(isBeingVerified ? '2' : '1');
  const [currentDomain, setCurrentDomain] = useState(domain || undefined);

  useEffect(() => {
    if (domain) {
      setCurrentDomain(domain);
    }
  }, [domain]);

  const handleSetDomain = useCallback(
    async (values: DomainFormSchema) => {
      if (!organization) return;
      const body: CreateDomainRequest = {
        body: {
          domain: values.domain,
        },
        params: {
          organizationId: organization.id,
        },
      };
      const { error, data } = await createDomain(body);

      if (!error) {
        toast.success('Domain successfully saved');
        setCurrentDomain(data);
        setStep('2');
      }
    },
    [createDomain, organization],
  );

  return (
    <Box width="100" maxWidth="10xl" gap="4xl" alignItems="flex-start">
      <Helmet>
        <title>Add a Domain</title>
      </Helmet>

      {step !== '3' && (
        <AddDomainSteps currentStep={step as '1' | '2' | undefined} domainToVerify={currentDomain?.domain} />
      )}

      {step === '1' && (
        <>
          <PageHeader title="Add a Domain" description="Add your domain to create short links" />
          <Formik initialValues={{ domain: '' }} validationSchema={domainSchema} onSubmit={handleSetDomain}>
            {({ isSubmitting, values, errors }) => (
              <Box width="100">
                <Form noValidate>
                  <Box gap="xl" width="100">
                    <Box gap="sm">
                      <Field
                        type="text"
                        label="Enter your custom domain"
                        name="domain"
                        id="domain"
                        component={FormikTextInput}
                        isDisabled={isSubmitting}
                        maxLength={32}
                        placeholder="e.g. myurl.com"
                        error={errors.domain}
                      />
                      <Box fontSize="xs" color="secondary">
                        {`${values.domain.length}/32 characters`}
                      </Box>
                    </Box>
                    <Button
                      variant="primary"
                      type="submit"
                      isLoading={isSubmitting}
                      style={{ width: 'fit-content' }}
                    >
                      Next
                    </Button>
                  </Box>
                </Form>
              </Box>
            )}
          </Formik>
          {error ? <ApiError error={error} customMessages={customMessages} /> : null}
        </>
      )}

      {step === '2' && (
        <DomainVerification setStep={setStep} domain={currentDomain} isBeingVerified={isBeingVerified} />
      )}
    </Box>
  );
};

const AddDomainSteps = ({
  currentStep = '1',
  domainToVerify,
}: {
  currentStep?: '1' | '2';
  domainToVerify?: string;
}) => {
  return (
    <Box direction="row" gap="lg" background="secondary" padding="lg 2xl" radius="2xl" alignItems="center">
      <Box direction="row" gap="xs" alignItems="center">
        <Icon name={currentStep === '1' ? 'circle-filled' : 'check'} size="2xs" />
        <Box fontSize="sm">{currentStep === '1' ? 'Enter Your Domain' : domainToVerify}</Box>
      </Box>
      <Icon name="caret-sm-right" color="secondary" />
      <Box direction="row" gap="xs" alignItems="center">
        <Icon name={currentStep === '2' ? 'circle-filled' : 'circle'} size="2xs" color="secondary" />
        <Box fontSize="sm">Configure DNS</Box>
      </Box>
    </Box>
  );
};

export default Domains;
