import { api } from './api';

export interface BillingAccountResponse {
  id: string;
  name: string;
  email: string;
  phone?: string;
  address: {
    streetAddress: string;
    locality: string;
    region: string;
    postalCode: string;
    country: string;
  };
  provider: string;
  providerId: string;
  providerData: any;
  paymentMethods: {
    providerId: string;
    type: 'Card' | 'ACH';
    last4?: string;
  }[];
  subscription: {
    status: string;
    providerId: string;
    startDate: string;
  };
  owners: {
    email: string;
    name: string;
    userId: string;
  }[];
}

export interface OrganizationBillingAccount {
  id: string;
  name: string;
  env: {
    pull: number;
    push: number;
  };
  link: {
    created: number;
  };
  netInfo: {
    requests: number;
  };
}

export interface BillingAccountUsageResponse {
  billingCycle: number;
  days: number;
  startDate: string;
  endDate: string;
  organizations: OrganizationBillingAccount[];
  total: {
    env: {
      pull: number;
      push: number;
    };
    link: {
      created: number;
    };
    netInfo: {
      requests: number;
    };
  };
}

export const billingAccountApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBillingAccountById: builder.query<BillingAccountResponse, string>({
      query: (id) => `api/billing-accounts/${id}`,
      providesTags: (result) => (result ? [{ type: 'BillingAccounts', id: result.id }] : []),
    }),
    getBillingAccountUsage: builder.query<BillingAccountUsageResponse, { id: string; billingCycle?: number }>({
      query: ({ id, billingCycle }) => {
        const params = new URLSearchParams();
        if (billingCycle) params.append('billingCycle', billingCycle.toString());
        return {
          url: `api/billing-accounts/${id}/usage?${params.toString()}`,
          method: 'GET',
        };
      },
      providesTags: (result) => (result ? [{ type: 'BillingAccounts' }] : []),
    }),
  }),
});

export const { useGetBillingAccountByIdQuery, useLazyGetBillingAccountUsageQuery } = billingAccountApi;
