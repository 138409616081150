import { Box, Button, Card, Icon } from '@hyphen/hyphen-components';

const EnvSetupGuideBanner = () => (
  <Card background="brand-gradient-purple-reverse" color="white" margin="0 0 xl 0">
    <Card.Section>
      <Box
        gap="lg"
        direction={{ base: 'column', tablet: 'row' }}
        alignItems={{ base: 'flex-start', tablet: 'center' }}
      >
        <Box>
          <Icon name="c-info" size="lg" />
        </Box>
        <Box flex="auto" fontSize="sm" gap="xs">
          <Box has="h2" fontWeight="bold">
            Initialize App for Local Development
          </Box>
          <Box>Establish an encryption key and keep app secrets in-sync with your team</Box>
        </Box>
        <Button variant="primary" iconSuffix="arrow-right" style={{ whiteSpace: 'nowrap' }} asChild>
          <a target="_blank" href="https://docs.hyphen.ai/docs/env-secrets-management" rel="noreferrer">
            ENV Setup Guide
          </a>
        </Button>
      </Box>
    </Card.Section>
  </Card>
);

export default EnvSetupGuideBanner;
