import { Card, Box, Heading, Icon } from '@hyphen/hyphen-components';
import { IconName } from '@hyphen/hyphen-components/dist/types';
import { ReactNode } from 'react';

type EmptyListProps = {
  title: string;
  description?: ReactNode;
  iconName?: IconName;
  img?: string;
  children?: ReactNode;
};

const EmptyList = ({ title, description, iconName, img, children }: EmptyListProps) => {
  return (
    <Card className="fade-in">
      <Box
        minHeight="7xl"
        width="100"
        height="100"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        gap="2xl"
      >
        {iconName && <Icon name={iconName} color="disabled" size="3xl" />}
        {img && (
          <Box width="4xl">
            <img src={img} alt="" />
          </Box>
        )}
        <Box gap="xs" alignItems="center">
          <Heading size={{ base: 'sm', desktop: 'md' }} as="h2">
            {title}
          </Heading>
          {description && (
            <Box display="block" fontSize={{ base: 'xs', tablet: 'sm' }} color="secondary" padding="0 4xl">
              {description}
            </Box>
          )}
        </Box>
        {children}
      </Box>
    </Card>
  );
};

export default EmptyList;
