import { Alert, Box, Button, FormikTextInput, FormikToggle, Modal, toast } from '@hyphen/hyphen-components';
import { Formik, Field, Form } from 'formik';
import { ApiError } from '../ApiError';
import { useCallback, useRef, useMemo } from 'react';
import * as yup from 'yup';
import { InferType } from 'yup';
import { useCreateMemberMutation } from '../../services/member';
import { useNavigate } from 'react-router-dom';
import { Organization } from '../../services/organization';
import { useGiveAccessMutation } from '../../services/access';
import { OrganizationRoles } from '../../types/roles';
import { AssignmentTypes } from '../access/GiveAccess';
import { useOrganizationAbilityContext } from '../auth/OrganizationAbilityProvider';
import { useAuth } from '../auth/authProvider';

const inviteMemberSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required'),
  orgAdmin: yup.boolean(),
});

type InviteMemberSchema = InferType<typeof inviteMemberSchema>;

export const InviteMemberModal = ({ organization }: { organization: Organization }) => {
  const [createMember, { error: createError }] = useCreateMemberMutation();
  const [giveAccess, { error: assignOrgAdminError }] = useGiveAccessMutation();
  const containerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const ability = useOrganizationAbilityContext();

  const { user } = useAuth();

  const isOrgAdmin = useMemo(() => {
    return user?.memberships
      .find((m) => m.organization.id === organization.id)
      ?.organizationRoles.includes(OrganizationRoles.OrganizationAdmin);
  }, [user, organization.id]);

  const canGiveOrgAdminAccess = useMemo(() => {
    return isOrgAdmin && ability.can('manage', organization);
  }, [isOrgAdmin, ability, organization]);

  const handleDismiss = useCallback(() => {
    navigate(`/${organization.id}/settings/members`);
  }, [navigate, organization.id]);

  const handleOnSubmit = useCallback(
    async (values: InviteMemberSchema) => {
      const { email, orgAdmin } = values;

      try {
        const { error: createError, data: member } = await createMember({
          email,
          organizationId: organization.id,
        });

        if (createError) {
          throw new Error('Invite failed');
        }

        if (canGiveOrgAdminAccess && orgAdmin && member?.id) {
          const { error: assignOrgAdminError } = await giveAccess({
            entity: { type: 'Organization', id: organization.id },
            assignment: { type: AssignmentTypes.Member, id: member.id },
            organizationId: organization.id,
            roles: [OrganizationRoles.OrganizationAdmin],
          });

          if (assignOrgAdminError) {
            toast.error('Org admin assignment failed');
            throw new Error('Org admin assignment failed');
          }
        }

        toast.success('Member invited', { duration: 5000 });
        handleDismiss();
      } catch (error) {
        toast.error('Invite failed');
      }
    },
    [canGiveOrgAdminAccess, organization.id, handleDismiss, createMember, giveAccess],
  );

  const initialValues = useMemo(() => ({ email: '', orgAdmin: false }), []);

  return (
    <Modal
      ariaLabelledBy="inviteMemberModal"
      isOpen
      onDismiss={handleDismiss}
      maxWidth="9xl"
      containerRef={containerRef}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={inviteMemberSchema}
        validateOnBlur={false}
        onSubmit={handleOnSubmit}
      >
        {({ isSubmitting, errors, values }) => (
          <Form noValidate>
            <Box gap={{ base: '2xl', tablet: '4xl' }}>
              <Modal.Header id="inviteMemberModal" title="Invite Member" onDismiss={handleDismiss} />
              <Modal.Body gap="xl">
                <Field
                  id="email"
                  name="email"
                  label="Invite by email"
                  component={FormikTextInput}
                  error={errors.email}
                />
                {canGiveOrgAdminAccess && (
                  <Field
                    id="orgAdmin"
                    name="orgAdmin"
                    label="Invite as organization administrator"
                    component={FormikToggle}
                    error={errors.orgAdmin}
                  />
                )}
                {values.orgAdmin && (
                  <Alert
                    variant="warning"
                    hasIcon
                    title="Org administrators have full access to all resources and services in this organization"
                  />
                )}
                {(createError || assignOrgAdminError) && <ApiError error={createError || assignOrgAdminError} />}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" type="button" onClick={handleDismiss} isDisabled={isSubmitting}>
                  Cancel
                </Button>
                <Button variant="primary" type="submit" isLoading={isSubmitting}>
                  Invite
                </Button>
              </Modal.Footer>
            </Box>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
