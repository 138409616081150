import React, { useMemo, useCallback } from 'react';
import { Box, Card, DrawerProvider, Icon } from '@hyphen/hyphen-components';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import TargetDrawer from './TargetDrawer';
import { ToggleReturnType } from '../../types/toggle';
import { ParsedCondition } from '../../utils/parseJsonLogic';
import { useToggle } from '../../providers/ToggleProvider';

interface TargetProps {
  targetContext: TargetContextType;
  returnType: ToggleReturnType;
  isDraggable: boolean;
  handleUpdateItems?: (updatedItems: any) => void;
  isHighlighted: boolean;
}

interface TargetContextType {
  id: string;
  parsedLogic: ParsedCondition[];
  value: boolean | string | number;
}

export const Target = React.memo(
  ({ targetContext, returnType, isDraggable, handleUpdateItems = () => {}, isHighlighted }: TargetProps) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging, isSorting, newIndex } =
      useSortable({
        id: targetContext.id,
      });

    const { toggle } = useToggle();

    const style = useMemo(
      () =>
        isDraggable
          ? {
              transform: CSS.Translate.toString(transform),
              transition,
              cursor: 'move',
              touchAction: 'none',
            }
          : {},
      [transform, transition, isDraggable],
    );

    const DisplayIndex = useMemo(
      () => (isDragging || isSorting ? newIndex + 1 : <Icon name="grab" size="2xl" />),
      [isDragging, isSorting, newIndex],
    );

    const renderUpdateMenu = useCallback(() => {
      if (!toggle) return null;

      if (!isDraggable) return null;
      if (isDragging || isSorting) {
        return <Box width="44px" height="32px" />;
      }
      return (
        <Box width="44px" alignItems="center">
          <DrawerProvider>
            <TargetDrawer targetContext={targetContext} onUpdateItems={handleUpdateItems} />
          </DrawerProvider>
        </Box>
      );
    }, [isDraggable, isDragging, isSorting, toggle, targetContext, handleUpdateItems]);

    return (
      <Box ref={setNodeRef} style={style} as="li" direction="row" alignItems="center" gap="lg">
        <Box
          flex="auto"
          {...listeners}
          {...attributes}
          id={targetContext.id}
          direction="row"
          alignItems="center"
          gap="lg"
        >
          {isDraggable && <Box width="4xl">{DisplayIndex}</Box>}
          <TargetCard
            targetContext={targetContext}
            returnType={returnType}
            isDraggable={isDraggable}
            isHighlighted={isHighlighted}
          />
        </Box>
        {renderUpdateMenu()}
      </Box>
    );
  },
);

interface TargetCardProps {
  targetContext: TargetContextType;
  returnType: ToggleReturnType;
  isDraggable: boolean;
  isHighlighted: boolean;
}

const TargetCard = React.memo(
  ({ targetContext: { parsedLogic, value }, returnType, isDraggable, isHighlighted }: TargetCardProps) => (
    <Card
      className={isHighlighted ? 'update-card-highlight' : ''}
      hover={isDraggable ? { borderColor: 'info', shadow: 'sm' } : undefined}
    >
      <Box
        direction={{ base: 'column', tablet: 'row' }}
        gap={{ base: 'sm', tablet: 'xl' }}
        alignItems={{ base: 'stretch', tablet: 'center' }}
        padding={{ base: 'lg', tablet: 'xl' }}
        fontFamily="monospace"
        fontSize={{ base: 'xs', tablet: 'sm' }}
        overflow="auto"
      >
        <ContextList contexts={parsedLogic} />

        <Box
          display="inline-block"
          radius="sm"
          background="tertiary"
          padding="sm"
          alignSelf={{ base: 'flex-start', tablet: 'center' }}
        >
          RETURNS
        </Box>

        <Box
          width="100"
          radius="sm"
          maxWidth={{ base: '8xl', hd: '9xl' }}
          minWidth="6xl"
          borderColor="subtle"
          borderWidth="sm"
          padding="sm lg"
          justifyContent="center"
          color={returnType === 'boolean' ? (value ? 'success' : 'danger') : 'base'}
          as="pre"
        >
          {String(value)}
        </Box>
      </Box>
    </Card>
  ),
);

const ContextList: React.FC<{ contexts: ParsedCondition[] }> = React.memo(({ contexts }) => (
  <Box as="ol" flex="auto" width="100" padding="0" borderColor="subtle" borderWidth="sm" radius="sm">
    {contexts.map(({ key, operator, value }, index) => (
      <ContextListItem key={index} contextKey={key} operator={operator} value={value} />
    ))}
  </Box>
));

const ContextListItem: React.FC<any> = React.memo(({ contextKey, operator, value }) => (
  <Box
    as="li"
    direction="row"
    gap="sm"
    alignItems="center"
    padding="sm md"
    className="row-item"
    borderColor="subtle"
    borderWidth="sm 0 0 0"
  >
    <Box style={{ flex: '1 0' }}>{contextKey}</Box>
    <Box width="5xl" textAlign="center">
      {operator}
    </Box>
    <Box style={{ flex: '1 0' }}>{value}</Box>
  </Box>
));
