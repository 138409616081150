import { Box, Button } from '@hyphen/hyphen-components';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import { Organization } from '../../services/organization';
import PageHeader from '../../components/PageHeader';
import { OrganizationPicker } from '../../components/organization/OrganizationPicker';

//https://hyphen.ai/integrations/setup/github?installation_id=51565605&setup_action=install

export const IntegrationSetup = () => {
  const [searchParams] = useSearchParams();
  const { integrationType } = useParams<{ integrationType: string }>();
  const [integrationName, setIntegrationName] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const installationId = searchParams.get('installation_id');

  useEffect(() => {
    // TODO: If this validation fails where do we want to send them?
    if (!searchParams.has('installation_id') || !searchParams.has('setup_action')) {
      alert('Invalid URL parameters');
    }
    if (integrationType !== 'github') {
      alert(`Invalid integration type: ${integrationType}`);
    }
    if (searchParams.get('setup_action') !== 'install') {
      alert('Invalid setup action');
    }

    setIntegrationName('GitHub');
  }, [integrationType, searchParams]);

  const [selectedOrg, setSelectedOrg] = useState<Organization | undefined>();

  const redirectToSetup = useCallback(async () => {
    setIsSubmitting(true);
    if (!selectedOrg) {
      alert('Please select an organization');
      return;
    }
    if (!installationId) {
      alert('Invalid installation id');
      return;
    }

    navigate(`/${selectedOrg.id}/integrations/setup/github?installationId=${installationId}`);
    setIsSubmitting(false);
  }, [navigate, selectedOrg, installationId]);

  return (
    <>
      <Helmet>
        <title>Setup Integration</title>
      </Helmet>
      <Box width="100" height="100" gap="4xl" alignItems="center" justifyContent="center">
        <Box textAlign="center" width="100" maxWidth="9xl" padding={{ base: '4xl', desktop: '5xl' }} gap="3xl">
          <PageHeader title={`Connect ${integrationName} to`} alignItems="center" />
          <OrganizationPicker handleSelection={setSelectedOrg} />
          <Box gap="3xl" margin="xl 0 0 0" width="100%">
            Your Installation ID is: {installationId}
          </Box>
          <Button
            variant="primary"
            fullWidth
            isDisabled={!selectedOrg && isSubmitting}
            isLoading={isSubmitting}
            onClick={redirectToSetup}
          >
            Complete Setup
          </Button>
        </Box>
      </Box>
    </>
  );
};
