import { Box, Button, FormikSelectInputNative, FormikTextInput, Heading, toast } from '@hyphen/hyphen-components';
import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { InferType } from 'yup';
import { useOrganization } from '../../providers/OrganizationProvider';
import { useGetDomainsQuery } from '../../services/link/domains';
import { useCreateCodeMutation } from '../../services/link/codes';
import { CreateCodeRequestBody } from '../../types/domain';
import { ApiError } from '../ApiError';

const createLinkSchema = yup.object().shape({
  long_url: yup
    .string()
    .url('Invalid URL, must be a valid URL including http:// or https://')
    .required('Required')
    .test('no-short-domain', 'Destination cannot be the same as the domain', function (value) {
      const { domain } = this.parent;
      return domain ? !value.includes(domain) : true;
    }),
  title: yup.string(),
  domain: yup.string().required('Required'),
  code: yup.string().matches(/^[a-zA-Z0-9-_]+$/, 'Invalid code'),
});

type CreateLinkSchema = InferType<typeof createLinkSchema>;

export const CreateLinkForm = () => {
  const navigate = useNavigate();

  const { organization } = useOrganization();
  const organizationId = organization?.id;
  const {
    data: domains,
    error: domainsError,
    isLoading: isDomainsListLoading,
  } = useGetDomainsQuery({ verified: true, organizationId });
  const [createShortLink, { isLoading, error }] = useCreateCodeMutation();

  const [domainsList, setDomainsListData] = useState<{ value: string; label: string }[]>([]);

  const initialValues = {
    long_url: '',
    title: '',
    domain: domainsList.length ? domainsList[0].value : '',
    code: '',
  };

  useEffect(() => {
    if (!isDomainsListLoading && !domainsError && domains) {
      const domainsList = domains.data.map((domain) => ({ value: domain.domain, label: domain.domain }));

      setDomainsListData(domainsList);
    }
  }, [domains, domainsError, isDomainsListLoading]);

  const handleCreateShortLink = useCallback(
    async (values: CreateLinkSchema) => {
      if (!organizationId) return;
      const body: CreateCodeRequestBody = {
        ...values,
      };

      const { error, data } = await createShortLink({ codeBody: body, organizationId });
      if (!error && data) {
        toast.success('Link copied to clipboard', { duration: 5000 });
        navigator.clipboard.writeText(`${data.domain}/${data.code}`);
        navigate(`/${organizationId}/link/${data.id}`);
      }
    },
    [createShortLink, navigate, organizationId],
  );

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={createLinkSchema}
        onSubmit={handleCreateShortLink}
        enableReinitialize
      >
        {({ errors, isSubmitting }) => (
          <Form noValidate>
            <Box gap="4xl">
              <Box gap="2xl">
                <Field
                  id="long_url"
                  name="long_url"
                  label="Destination"
                  placeholder="https://my-long-url-example.com"
                  component={FormikTextInput}
                  error={errors.long_url}
                  isRequired
                />
                <Field id="title" name="title" label="Label (optional)" component={FormikTextInput} />
              </Box>
              <Box gap="lg" width="100" alignItems="flex-start">
                <Heading as="h2" size="sm">
                  Short Link
                </Heading>
                <Box width="100" direction="row" gap="lg" alignItems="flex-end">
                  <Box flex="auto" alignItems="flex-start">
                    <Field
                      id="domain"
                      name="domain"
                      label="Domain"
                      component={FormikSelectInputNative}
                      options={domainsList}
                      error={errors.domain}
                      isRequired
                    />
                  </Box>
                  <Box padding="0 0 md 0" fontWeight="bold" color="secondary">
                    /
                  </Box>
                  <Box flex="auto">
                    <Field
                      id="code"
                      name="code"
                      label="Custom back-half (optional)"
                      component={FormikTextInput}
                      error={errors.code}
                    />
                  </Box>
                </Box>
                <Link
                  to={`/${organizationId}/settings/domains/add`}
                  className="hyphen-components__variables__button hyphen-components__variables__form-control Button-module_button__18Bed Button-module_size-sm__6Xrjw Button-module_secondary__j-3rj"
                >
                  Add domain
                </Link>
              </Box>
              <Box direction="row" gap="sm">
                <Button variant="primary" isLoading={isSubmitting} type="submit">
                  Create Short Link
                </Button>
                <Button
                  variant="tertiary"
                  isDisabled={isLoading}
                  onClick={() => navigate(`/${organizationId}/link/`)}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>

      {error ? <ApiError error={error} /> : null}
    </>
  );
};

export default CreateLinkForm;
