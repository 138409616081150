import { Box, Heading } from '@hyphen/hyphen-components';
import { App } from '../../services/apps';
import { useGetEnvsQuery } from '../../services/env';
import { useGetProjectEnvironmentsQuery } from '../../services/environments';
import EnvironmentItem from '../common/EnvironmentItem';
import { Skeleton } from '../common/Skeleton';
import EnvSetupGuideBanner from './EnvSetupGuideBanner';
import { useMemo } from 'react';

const gridStyle = {
  gridTemplateColumns: 'repeat(auto-fill, minmax(290px, 1fr))',
};

const EnvironmentsWithSecrets = ({
  app,
  organizationId,
  isLoading,
}: {
  app?: App;
  organizationId: string;
  isLoading: boolean;
}) => {
  const { data: envs, isLoading: isEnvsLoading } = useGetEnvsQuery({ organizationId, appIds: app ? [app.id] : [] });
  const { data: projectEnvironments, isLoading: isProjectEnvironmentsLoading } = useGetProjectEnvironmentsQuery({
    organizationId,
    projectId: app?.project.id,
  });

  const secretsData = useMemo(() => envs?.data ?? [], [envs]);
  const loading = useMemo(
    () => isLoading || isEnvsLoading || isProjectEnvironmentsLoading,
    [isEnvsLoading, isLoading, isProjectEnvironmentsLoading],
  );

  const projectEnvironmentsWithSecrets = useMemo(
    () =>
      (projectEnvironments?.data ?? []).map((env) => ({
        ...env,
        secrets: secretsData.find((secret) => secret.projectEnvironment?.id === env.id),
      })),
    [projectEnvironments, secretsData],
  );

  const defaultSecrets = useMemo(
    () => ({
      name: 'Default (all)',
      secrets: secretsData.find((secret) => !secret.projectEnvironment) || 0,
    }),
    [secretsData],
  );

  const renderSkeletons = () => (
    <>
      <Skeleton width="100" height="68px" />
      <Skeleton width="100" height="68px" />
      <Skeleton width="100" height="68px" />
    </>
  );

  return (
    <Box alignItems="flex-start" width="100" gap="xl">
      {loading ? (
        <Skeleton width="106px" height="20px" />
      ) : (
        <Heading as="h2" size="sm">
          Environments
        </Heading>
      )}
      {!loading && secretsData.length === 0 && <EnvSetupGuideBanner />}
      <Box
        width="100"
        direction="row"
        gap="lg"
        overflow="auto"
        display="grid"
        fontSize="xs"
        style={{
          ...(loading || projectEnvironmentsWithSecrets.length > 0 ? gridStyle : {}),
        }}
      >
        {loading ? (
          renderSkeletons()
        ) : projectEnvironmentsWithSecrets.length > 0 ? (
          <>
            <EnvironmentItem {...defaultSecrets} app={app} />
            {projectEnvironmentsWithSecrets.map((env) => (
              <EnvironmentItem key={env.id} {...env} app={app} />
            ))}
          </>
        ) : !loading ? (
          <Box>No environments found</Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default EnvironmentsWithSecrets;
