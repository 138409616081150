import { useOrganization } from '../../providers/OrganizationProvider';
import { Helmet } from 'react-helmet';
import { Box, useIsMobile } from '@hyphen/hyphen-components';
import { ProjectsList } from '../../components/projects/ProjectsList';
import GetStartedDashboard from '../../components/dashboard/GetStartedDashboard';
import { useOrganizationAbilityContext } from '../../components/auth/OrganizationAbilityProvider';
import { ScrollableMainLayout } from '../../components/ScrollableMainLayout';
import { EntityNames } from '../../types/executionContext';
import { BreadCrumbPortal } from '../../components/common/Breadcrumb';
import PageHeader from '../../components/PageHeader';
import { useMemo } from 'react';
import { Skeleton } from '../../components/common/Skeleton';

export const Dashboard = () => {
  const { id: orgId, isLoading } = useOrganization();
  const isMobile = useIsMobile();
  const ability = useOrganizationAbilityContext();
  const canManageOrganizationIntegrations = ability.can('manage', EntityNames.OrganizationIntegration);

  const pageHeaderProps = useMemo(() => {
    if (isLoading) {
      return { title: <Skeleton width={isMobile ? '105px' : '168px'} height="32px" /> };
    }

    return { title: 'Dashboard' };
  }, [isLoading, isMobile]);

  return (
    <Box width="100" height="100" direction={{ base: 'column', tablet: 'row' }}>
      <Box flex="auto" overflow="auto" className="scroll-bar-thin">
        <Box width="100" margin="0">
          <Helmet>
            <title>Dashboard</title>
          </Helmet>
          <BreadCrumbPortal />
          {canManageOrganizationIntegrations ? (
            <Box width="100" height="100" overflow="auto" className="scroll-bar-thin">
              <GetStartedDashboard organizationId={orgId} />
              <Box gap={{ base: '2xl', tablet: '4xl' }} width="100" padding={{ base: '3xl', tablet: '5xl' }}>
                <PageHeader {...pageHeaderProps} />
                <ProjectsList />
              </Box>
            </Box>
          ) : (
            <ScrollableMainLayout fullWidth>
              <PageHeader {...pageHeaderProps} />
              <ProjectsList />
            </ScrollableMainLayout>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
