import { Helmet } from 'react-helmet';
import { useOrganizationAbilityContext } from '../../../components/auth/OrganizationAbilityProvider';
import { useToggle } from '../../../providers/ToggleProvider';
import { EditToggleForm } from '../../../components/toggles/EditToggleForm';
import DeleteToggle from '../../../components/toggles/DeleteToggle';

export const ToggleSettings = () => {
  const { toggle } = useToggle();
  const ability = useOrganizationAbilityContext();

  if (!toggle) return null;

  const canUpdateToggle = ability.can('update', toggle);
  if (canUpdateToggle)
    return (
      <>
        <Helmet title={`${toggle?.key} Settings`} />
        <EditToggleForm />
        <DeleteToggle />
      </>
    );

  return (
    <>
      <Helmet title={`${toggle?.key} Settings`} />
      <p>You do not have permission</p>
    </>
  );
};

export default ToggleSettings;
