import { Box, Button } from '@hyphen/hyphen-components';
import { Link } from 'react-router-dom';
import { Guide } from '../../../guide/Guide';
import { GuideStep } from '../../../guide/GuideStep';
import { JiraSetupForm } from './JiraSetupForm';

interface JiraSetupGuideProps {
  organizationId: string;
}

export const JiraSetupGuide = ({ organizationId }: JiraSetupGuideProps) => {
  return (
    <Guide title="Setup Guide">
      <GuideStep title="Create or sign into your Jira account">
        <p>
          <Button asChild iconSuffix="launch-app">
            <Link to="https://www.atlassian.com/software/jira" target="_blank">
              Sign in to Jira
            </Link>
          </Button>
        </p>
        <p>or</p>
        <p>
          <Button asChild iconSuffix="launch-app">
            <Link to="https://www.atlassian.com/try/cloud/signup" target="_blank">
              Create a new Jira Cloud account
            </Link>
          </Button>
        </p>
      </GuideStep>
      <GuideStep title="Generate an API token">
        <Box as="ol" childGap="2xl" padding="0 0 0 2xl">
          <li>
            Go to{' '}
            <Link to="https://id.atlassian.com/manage/api-tokens" target="_blank">
              Atlassian API tokens
            </Link>
          </li>
          <li>Click "Create API token"</li>
          <li>Give your token a label (e.g., "Hyphen Integration")</li>
          <li>Copy the generated token - you'll need it in the next step</li>
        </Box>
      </GuideStep>
      <GuideStep title="Get your Cloud ID and Organization ID">
        <Box as="ol" childGap="2xl" padding="0 0 0 2xl">
          <li>
            Go to{' '}
            <Link to="https://home.atlassian.com" target="_blank">
              Atlassian Home
            </Link>
          </li>
          <li>After logging in, check your browser's URL bar</li>
          <li>
            Your Organization ID can be found in the URL:{' '}
            <code>
              https://home.atlassian.com/o/<strong>[your-org-id]</strong>
            </code>
          </li>
          <li>
            Your Cloud ID can be found in your Jira instance URL:{' '}
            <code>
              https://<strong>[your-cloud-id]</strong>.atlassian.net
            </code>
          </li>
        </Box>
      </GuideStep>
      <GuideStep title="Connect Jira">
        <JiraSetupForm organizationId={organizationId} />
      </GuideStep>
    </Guide>
  );
};
