import { Box, Button, Heading, Spinner, toast } from '@hyphen/hyphen-components';
import { useEffect, useState } from 'react';
import { useGetEventsQuery } from '../../services/events';
import { Organization } from '../../services/organization';
import { useOrganization } from '../../providers/OrganizationProvider';
import { EventReferenceType, EventType, ReferenceOperation } from '../../types/events';
import { EventsTable } from '../events/EventsTable';
import { EnvEventMap } from './EnvEventMap';

const PAGE_SIZE = 50;

interface EventListProps {
  appId: string;
  title: string;
  pevrId?: string;
  types?: EventType[];
  showMap?: boolean;
}

export const EventList = ({ appId, pevrId, types, title, showMap = false }: EventListProps) => {
  const [nextPageCursor, setNextPageCursor] = useState<string | undefined>(undefined);
  const filters = {
    pageCursor: nextPageCursor,
    pageSize: PAGE_SIZE,
    types: types,
    references: [
      {
        id: appId,
        type: EventReferenceType.App,
      },
      ...(pevrId
        ? [
            {
              id: pevrId,
              type: EventReferenceType.ProjectEnvironment,
            },
          ]
        : []),
    ],
    referenceOperation: ReferenceOperation.And,
  };

  const { organization = {} as Organization } = useOrganization();
  const {
    data: events,
    isLoading,
    isFetching,
    refetch,
  } = useGetEventsQuery({ orgId: organization.id, body: filters });
  const eventsData = events?.data ?? [];

  const [eventsContent, setEventsContent] = useState(eventsData);

  useEffect(() => {
    setEventsContent((prevEvents) => {
      if (events?.data) {
        if (prevEvents !== events.data) {
          return [...prevEvents, ...events.data];
        }
      }
      return prevEvents;
    });
  }, [events?.data]);

  const handleRefreshAccessLog = async () => {
    try {
      const { error } = await refetch();
      if (!error) {
        toast.success('Access log refreshed', { duration: 2000 });
      } else {
        toast.error('Failed to refresh access log');
      }
    } catch (e) {
      toast.error('Failed to refresh access log');
    }
  };

  return (
    <Box gap="sm" fontSize="sm">
      <Box direction="row" gap="2xl" alignItems="center" justifyContent="space-between">
        <Heading as="h2" size="sm">
          {title}
        </Heading>
        <Button
          size="sm"
          variant="secondary"
          iconPrefix="refresh"
          aria-label="Refresh access log"
          onClick={handleRefreshAccessLog}
          isDisabled={isLoading || isFetching}
        />
      </Box>
      {/*<Box direction="row" gap="2xl" alignItems="center" justifyContent="space-between">
      <Box minWidth="7xl" maxWidth="50%">
          <TextInput
            id="search"
            name="search"
            label="Filter access log"
            hideLabel
            value=""
            placeholder="Filter access log by user, IP address, or environment"
            onChange={() => {}}
          />
        </Box>
      </Box>*/}
      {isLoading ? (
        <Box gap="md" direction="row" alignItems="center">
          <Spinner />
          Loading...
        </Box>
      ) : (
        <>
          {showMap && eventsContent.length > 0 && <EnvEventMap events={eventsContent} />}
          <EventsTable
            events={events}
            eventsContent={eventsContent}
            setNextPageCursor={setNextPageCursor}
            isLoading={isLoading || isFetching}
          />
        </>
      )}
    </Box>
  );
};
