import { Box, Button, Card, FormikTextInput, toast } from '@hyphen/hyphen-components';
import { Field, Form, Formik } from 'formik';
import { InferType } from 'yup';
import { ApiError } from '../ApiError';
import { useUpdateToggleMutation } from '../../services/toggle';
import { useOrganization } from '../../providers/OrganizationProvider';
import { useToggle } from '../../providers/ToggleProvider';
import ReturnValueField from './ReturnValueField';
import * as yup from 'yup';
import { validateDefaultValue } from '../../utils/validations';
import { ToggleType } from '../../types/toggle';

const editToggleFormSchema = yup.object().shape({
  description: yup.string(),
  defaultValue: yup
    .mixed()
    .required('Default return value is required')
    .test('validate-default-value', 'Invalid default return value', validateDefaultValue),
});

type EditToggleSchema = InferType<typeof editToggleFormSchema>;

export const EditToggleForm = () => {
  const { id: orgId } = useOrganization();
  const { toggle, refetch } = useToggle();

  const [updateToggle, { error }] = useUpdateToggleMutation();

  const handleSubmitEditToggle = async (values: EditToggleSchema) => {
    if (!toggle) return;

    if (toggle.type === ToggleType.Boolean) {
      values.defaultValue = values.defaultValue === 'true';
    }

    const { error, data } = await updateToggle({
      organizationId: orgId,
      projectId: toggle.project.alternateId,
      toggleKey: toggle.key,
      body: { description: values.description || '', defaultValue: values.defaultValue },
    });

    if (!error && data) {
      toast.success('Toggle updated');
      refetch();
    }
  };

  if (!toggle) return null;

  const initialValues = {
    description: toggle.description,
    defaultValue: toggle.type === ToggleType.Boolean ? String(toggle.defaultValue) : toggle.defaultValue,
    toggleType: toggle.type,
  };

  return (
    <Card>
      <Card.Section gap="xl">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={editToggleFormSchema}
          onSubmit={handleSubmitEditToggle}
        >
          {({ values, setFieldValue, errors, isSubmitting }) => (
            <Form noValidate>
              <Box gap="2xl">
                <Field
                  type="text"
                  label="Description (optional)"
                  name="description"
                  id="description"
                  autoComplete="off"
                  component={FormikTextInput}
                  error={errors.description}
                  isDisabled={isSubmitting}
                />
                <ReturnValueField
                  label="Default Return Value"
                  toggleType={toggle.type}
                  defaultValue={values.defaultValue}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  isSubmitting={isSubmitting}
                />
                {error && <ApiError error={error} title="Error updating toggle" />}
                <Box display="block">
                  <Button variant="primary" type="submit" isLoading={isSubmitting}>
                    Save
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Card.Section>
    </Card>
  );
};
