import { useCallback, useEffect, useState } from 'react';
import { Box, Button, Card, Popover, Spinner, toast, useBreakpoint, useOpenClose } from '@hyphen/hyphen-components';
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useOrganization } from '../../providers/OrganizationProvider';
import {
  useDeleteCodeMutation,
  useGetCodeQuery,
  useGetTagsQuery,
  useGetQrsCodeQuery,
  useDeleteQrCodeMutation,
} from '../../services/link/codes';
import PageHeader from '../../components/PageHeader';
import { TagList } from '../../components/link/TagList';
import { DateCreated } from '../../components/DateCreated';
import { ScrollableMainLayout } from '../../components/ScrollableMainLayout';
import { ConfirmModal } from '../../components/common/ConfirmModal';
import { CreateQRForm } from '../../components/link/CreateQRForm';
import { RedirectLinkModal } from '../../components/link/RedirectLinkModal';
import { LinkDetailStats } from '../../components/link/LinkDetailStats';
import { Code } from '../../types/domain';
import QrCodeItem from '../../components/link/QrCodeItem';
import { useOrganizationAbilityContext } from '../../components/auth/OrganizationAbilityProvider';
import { subject } from '@casl/ability';
import { EntityNames } from '@hyphen/nucleus/dist/types';
import { EditTitleModal } from '../../components/link/EditTitleModal';
import Error from '../../Error';
import { BreadCrumbPortal } from '../../components/common/Breadcrumb';

const deleteCodeMessage = {
  default: 'Failed to remove short link',
};
const deleteQrCodeMessage = {
  default: 'Failed to remove QR code',
};

export const ShortLinkDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: any }>();

  const { organization } = useOrganization();
  const organizationId = organization?.id;
  const { data: code, error, isLoading: isCodeLoading } = useGetCodeQuery({ id, organizationId });
  const { data: orgTags } = useGetTagsQuery(organizationId);
  const {
    data: qrCodes,
    error: qrCodesError,
    isLoading: isQrCodesLoading,
  } = useGetQrsCodeQuery({ codeId: id, organizationId });
  const [deleteShortLink, { isLoading: isDeleting, error: deleteCodeError }] = useDeleteCodeMutation();
  const [deleteQrCode, { isLoading: isDeletingQrCode, error: deleteQrCodeError }] = useDeleteQrCodeMutation();
  const [codeData, setCodeData] = useState<Code | undefined>(undefined);
  const [shortLink, setShortLink] = useState<string>('');
  const [showQrCodeForm, setShowQrCodeForm] = useState(false);

  const { isOpen: isPopoverOpen, handleToggle: togglePopover, handleClose: closePopover } = useOpenClose();
  const { isOpen: isRedirectOpen, handleToggle: toggleRedirect, handleClose: closeRedirect } = useOpenClose();
  const { isOpen: isTitleOpen, handleToggle: toggleTitle, handleClose: closeTitle } = useOpenClose();
  const { isOpen: isDeleteOpen, handleOpen: openDelete, handleClose: closeDelete } = useOpenClose();

  const { isPhone } = useBreakpoint();

  const abilityFilter = { id: codeData?.id, organization: { id: organizationId } };
  const ability = useOrganizationAbilityContext();
  const canEditLink = ability.can('update', subject(EntityNames.LinkCode, abilityFilter));
  const canDeleteLinkCode = ability.can('delete', subject(EntityNames.LinkCode, abilityFilter));

  useEffect(() => {
    if (!isCodeLoading && !error && code) {
      setCodeData(code);
      setShortLink(`${code?.domain}/${code?.code}`);
    }
  }, [code, error, isCodeLoading]);

  const handleRedirectBtn = () => {
    closePopover();
    toggleRedirect();
  };

  const handleEditLabelBtn = () => {
    closePopover();
    toggleTitle();
  };

  const handleCopyUrl = () => {
    toast.success('Copied to clipboard');
    navigator.clipboard.writeText(`https://${shortLink}`);
  };

  const handleDelete = useCallback(async () => {
    const result = await deleteShortLink({ codeId: codeData?.id ?? '', organizationId: organization?.id });
    if (!result.error) {
      toast.success('Short link deleted');
      navigate(`/${organization?.id}/link`);
      closeDelete();
    }
  }, [closeDelete, codeData?.id, deleteShortLink, navigate, organization?.id]);

  const handleQrCodeDelete = useCallback(
    async (qrId: string) => {
      const result = await deleteQrCode({ codeId: codeData?.id ?? '', qrId, organizationId: organization?.id });
      if (!result.error) {
        toast.success('QR code deleted');
      }
    },
    [codeData?.id, deleteQrCode, organization?.id],
  );

  const popoverContent = (
    <>
      {canEditLink && (
        <>
          <Button variant="secondary" size="sm" onClick={handleRedirectBtn}>
            Redirect
          </Button>
          <Button variant="secondary" size="sm" onClick={handleEditLabelBtn}>
            Edit Label
          </Button>
        </>
      )}
      {canDeleteLinkCode && (
        <Button
          variant="danger"
          size="sm"
          onClick={() => {
            closePopover();
            closeRedirect();
            openDelete();
          }}
        >
          Delete
        </Button>
      )}
    </>
  );

  if (isCodeLoading || isQrCodesLoading) return <Spinner />;

  if ((!isCodeLoading && error) || (!isQrCodesLoading && qrCodesError)) return <Error />;

  if (!codeData) return null;

  return (
    <>
      <RedirectLinkModal
        codeData={codeData}
        closeRedirect={closeRedirect}
        isRedirectOpen={isRedirectOpen}
        toggleRedirect={toggleRedirect}
        shortLink={shortLink}
      />
      <EditTitleModal codeData={codeData} closeTitle={closeTitle} isOpen={isTitleOpen} toggleTitle={toggleTitle} />
      <ConfirmModal
        message={`Are you sure you want to delete ${codeData?.title}?`}
        confirmButtonLabel="Delete"
        isOpen={isDeleteOpen}
        onClose={closeDelete}
        onConfirm={handleDelete}
        isLoading={isDeleting}
        title={`Delete ${codeData?.title}`}
        description="Once deleted, it will be gone forever. Please be certain."
        error={deleteCodeError}
        customErrorMessages={deleteCodeMessage}
      />
      <ScrollableMainLayout>
        <Helmet title={`${codeData?.title} Short URL`} />
        <BreadCrumbPortal entity={code} />
        <Box gap="lg" alignItems="flex-start">
          <Box
            width="100"
            direction={{ base: 'column', tablet: 'row' }}
            gap={{ base: '2xl', tablet: '5xl' }}
            alignItems={{ tablet: 'center' }}
          >
            <PageHeader
              flex="auto"
              title={codeData?.title}
              description={
                <Box gap="sm">
                  <Box fontWeight="semibold">
                    <Link to={`https://${shortLink}`} target="_blank" rel="noopener noreferrer">
                      {shortLink}
                    </Link>
                  </Box>
                  <Box>
                    {codeData?.long_url && (
                      <Link
                        className="font-color-secondary"
                        to={codeData?.long_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {codeData?.long_url}
                      </Link>
                    )}
                  </Box>
                </Box>
              }
              favoriteId={codeData?.id}
            />
            <Box gap="md" direction="row" alignItems="center">
              <Button variant="primary" iconPrefix="copy-document" onClick={handleCopyUrl} fullWidth={isPhone}>
                Copy short link
              </Button>
              {(canEditLink || canDeleteLinkCode) && (
                <Popover
                  content={popoverContent}
                  isOpen={isPopoverOpen}
                  hasArrow={false}
                  offsetFromTarget={3}
                  placement="bottom-end"
                  contentContainerProps={{
                    padding: 'sm',
                    gap: 'xs',
                    borderWidth: 'sm',
                    borderColor: 'subtle',
                    background: 'inverse',
                  }}
                  onClickOutside={closePopover}
                >
                  <Button variant="secondary" iconPrefix="dots" aria-label="more actions" onClick={togglePopover} />
                </Popover>
              )}
            </Box>
          </Box>

          {codeData?.id && (
            <TagList codeId={codeData.id} tags={codeData.tags ?? []} orgTags={orgTags ?? []} badgeSize="md" />
          )}
          {codeData?.createdAt && <DateCreated dateCreated={codeData.createdAt} />}
        </Box>

        <Card>
          {canEditLink && (
            <Card.Section>
              <div>
                {!showQrCodeForm && (
                  <Button variant="secondary" onClick={() => setShowQrCodeForm(true)}>
                    Create QR code
                  </Button>
                )}
              </div>
              {showQrCodeForm && codeData?.id && (
                <CreateQRForm showQr={setShowQrCodeForm} codeId={codeData.id} codeData={codeData} />
              )}
            </Card.Section>
          )}
          {qrCodes && qrCodes.data.length > 0 && (
            <Card.Section title="QR Code">
              <Box direction="row" wrap gap="xl">
                {qrCodes.data.map((qrCode) => (
                  <QrCodeItem
                    key={qrCode.id}
                    qrCode={qrCode}
                    isDeleting={isDeletingQrCode}
                    handleQrCodeDelete={handleQrCodeDelete}
                    error={deleteQrCodeError}
                    deleteQrCodeMessage={deleteQrCodeMessage}
                    canDeleteQrCode={canEditLink}
                  />
                ))}
              </Box>
            </Card.Section>
          )}
        </Card>

        <LinkDetailStats />
      </ScrollableMainLayout>
    </>
  );
};

export default ShortLinkDetail;
