import { Card, Box, Heading } from '@hyphen/hyphen-components';
import { ParentSize } from '@visx/responsive';
import { useMemo, useState, useEffect, useCallback } from 'react';
import { timeParse, timeFormat } from '@visx/vendor/d3-time-format';
import { Skeleton } from '../common/Skeleton';
import DailyUsageBarChart, { BarData } from './DailyUsageBarChart';
import EnvironmentData from './EnvironmentData';
import { useToggle } from '../../providers/ToggleProvider';

const parseDate = timeParse('%Y-%m-%d');
const format = timeFormat('%b %d');
const formatDate = (date: string) => format(parseDate(date) as Date);

const DailyUsageCard = () => {
  const { usage, isLoading } = useToggle();

  const data = useMemo(() => usage?.dailyUsage || [], [usage]);
  const total30days = useMemo(() => usage?.counts.total || 0, [usage]);

  // the usage total for each environment
  const totalSum = useMemo(
    () =>
      data.reduce((acc, entry) => {
        Object.entries(entry.counts.environments).forEach(([key, value]) => {
          acc[key] = (acc[key] || 0) + value;
        });
        return acc;
      }, {} as Record<string, number>),
    [data],
  );

  const initialBigDisplayValue = useMemo(
    () => ({
      total: total30days,
      dateRange: data[0] ? `${formatDate(data[0].date)} - ${formatDate(data[data.length - 1].date)}` : '',
      barData: undefined as BarData | undefined,
    }),
    [data, total30days],
  );

  const [bigDisplayValue, setBigDisplayValue] = useState(initialBigDisplayValue);

  useEffect(() => {
    setBigDisplayValue(initialBigDisplayValue);
  }, [initialBigDisplayValue]);

  const resetBigDisplayValue = useCallback(
    () => setBigDisplayValue(initialBigDisplayValue),
    [initialBigDisplayValue],
  );

  if (isLoading) {
    return (
      <>
        <Card.Section gap="sm">
          <Skeleton width="80px" height="20px" />
          <Skeleton width="120px" height="16px" margin="lg 0 0 0" />
          <Skeleton width="360px" height="36px" />
        </Card.Section>
        <Box padding="0 2xl 2xl 2xl" width="100" style={{ minHeight: '300px', height: '300px' }}>
          <Skeleton width="100%" height="100%" />
        </Box>
      </>
    );
  }

  if (!isLoading && !data) return null;

  return (
    <>
      <Card.Section gap="sm" className="fade-in">
        <Heading as="h4" size="sm">
          Usage
        </Heading>
        <Box margin="lg 0 0 0" color="secondary" fontSize="sm">
          {bigDisplayValue.dateRange}
        </Box>
        <Box
          direction="row"
          gap="xl"
          alignItems="baseline"
          fontSize={{ base: 'lg', tablet: 'xl', desktop: '3xl' }}
          fontWeight="bold"
        >
          {bigDisplayValue.barData ? (
            <>
              {bigDisplayValue.total.toLocaleString('en-US')}
              <Box as="span" display="inline" fontSize="sm" fontWeight="normal">
                <EnvironmentData data={bigDisplayValue.barData.data} />
              </Box>
            </>
          ) : (
            <>
              {bigDisplayValue.total.toLocaleString('en-US')}
              <Box as="span" display="inline" fontSize="sm" fontWeight="normal">
                <EnvironmentData data={totalSum} />
              </Box>
            </>
          )}
        </Box>
      </Card.Section>
      <Box className="fade-in" padding="0 2xl" width="100" style={{ minHeight: '300px', height: '300px' }}>
        <ParentSize>
          {({ width, height }) => (
            <DailyUsageBarChart
              width={width}
              height={height}
              data={data}
              setBigDisplayValue={setBigDisplayValue}
              resetBigDisplayValue={resetBigDisplayValue}
            />
          )}
        </ParentSize>
      </Box>
    </>
  );
};

export default DailyUsageCard;
