import { toast, useOpenClose } from '@hyphen/hyphen-components';
import { ConfirmModal } from '../common/ConfirmModal';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDeleteProjectMutation } from '../../services/projects';
import { DeleteItemCard } from '../common/DeleteItemCard';
import { useProject } from '../../providers/ProjectProvider';

export default function DeleteProject() {
  const { project } = useProject();
  const [deleteProject, { isLoading: isDeleting, error }] = useDeleteProjectMutation();

  const navigate = useNavigate();

  const { isOpen: isDeleteModalOpen, handleOpen: openDeleteModal, handleClose: closeDeleteModal } = useOpenClose();

  const handleDelete = useCallback(async () => {
    if (project) {
      const { error } = await deleteProject({
        projectId: project.alternateId,
        organizationId: project.organization.id,
      });
      if (!error) {
        toast.success('Project deleted');
        closeDeleteModal();
        navigate(`/${project.organization.id}`, { replace: true });
      }
    }
  }, [project, deleteProject, closeDeleteModal, navigate]);

  return (
    <>
      <DeleteItemCard itemLabel="Project" onDelete={openDeleteModal} />
      <ConfirmModal
        message={
          <>
            {(project?.apps?.length ?? 0) > 0 && (
              <p>
                <strong>{project?.apps?.length} apps within this project will be deleted.</strong>
              </p>
            )}
            <p>Are you sure you want to delete {project?.name}?</p>
          </>
        }
        confirmButtonLabel="Delete"
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onConfirm={handleDelete}
        isLoading={isDeleting}
        error={error}
        customErrorMessages={{ default: 'Delete failed' }}
      />
    </>
  );
}
