import Toggles from '../../components/toggles/Toggles';
import { ProjectEnvironmentsProvider } from '../../providers/ProjectEnvironmentsProvider';

export const ToggleDashboard = () => {
  return (
    <ProjectEnvironmentsProvider>
      <Toggles />
    </ProjectEnvironmentsProvider>
  );
};

export default ToggleDashboard;
