import { Box, Button, Heading, Spinner, useBreakpoint } from '@hyphen/hyphen-components';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useOrganizationsList } from '../providers/OrganizationsListProvider';
import { Helmet } from 'react-helmet';
import { OrganizationAvatar } from '../components/organization/OrganizationAvatar';
import { routes } from '..';
import { Organization } from '../services/organization';

export const OrganizationList = () => {
  const navigate = useNavigate();
  const { organizations, isLoading: areOrganizationsLoading } = useOrganizationsList();
  const location = useLocation();

  const { isPhone } = useBreakpoint();

  useEffect(() => {
    if (!areOrganizationsLoading) {
      if (organizations?.length === 0) {
        // get search query from URL and add it to the get started page
        navigate(`/${routes.getStarted}${location.search}`);
      } else if (organizations?.length === 1) {
        navigate(`/${organizations[0].id}`);
      }
    }
  }, [areOrganizationsLoading, location.search, navigate, organizations]);

  if (areOrganizationsLoading) {
    return (
      <Box
        alignItems="center"
        background="primary"
        color="base"
        fontSize="sm"
        height="100vh"
        justifyContent="center"
        padding="lg"
      >
        <Spinner />
      </Box>
    );
  }

  return (
    <>
      <Helmet>
        <title>Organizations</title>
      </Helmet>
      <Box
        width={{ base: '100', desktop: '9xl' }}
        gap="xl"
        padding={{ base: '3xl', tablet: '4xl', desktop: '5xl' }}
        fontSize="xs"
        height="100"
        alignItems="center"
        justifyContent="center"
        margin="0 auto"
      >
        <Box width="100" childGap="sm" direction="row" justifyContent="space-between" alignItems="center">
          <Heading as="h1" size="sm">
            Choose an Organization
          </Heading>
          {!isPhone && (
            <Button
              asChild
              variant="primary"
              size="sm"
              iconPrefix="add"
              aria-label="Add Organization"
              style={{ textDecoration: 'none' }}
            >
              <Link to={`/${routes.getStarted}`}>Add</Link>
            </Button>
          )}
        </Box>
        <Box
          flex="auto"
          width="100"
          borderWidth="sm"
          radius="md"
          maxHeight={{ tablet: '9xl' }}
          overflow="auto"
          className="scroll-bar-thin"
        >
          {organizations?.map((org) => (
            <OrganizationItem key={org.id} org={org} />
          ))}
        </Box>
        {isPhone && (
          <Button
            asChild
            fullWidth
            variant="primary"
            size="md"
            iconPrefix="add"
            aria-label="Add Organization"
            style={{ textDecoration: 'none' }}
          >
            <Link to={`/${routes.getStarted}`}>Add Organization</Link>
          </Button>
        )}
      </Box>
    </>
  );
};

const OrganizationItem = ({ org }: { org: Organization }) => (
  <Box
    childGap="sm"
    padding="md"
    direction="row"
    alignItems="center"
    borderColor="subtle"
    borderWidth="sm 0 0 0"
    className="row-item"
  >
    <Link to={`/${org.id}`}>
      <Box flex="auto" alignItems="center" direction="row" gap="lg">
        <OrganizationAvatar name={org?.name || 'Unknown Organization'} logoUrl={org.logoUrl} />
        {org.name}
      </Box>
    </Link>
  </Box>
);
