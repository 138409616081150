import { Alert } from '@hyphen/hyphen-components';

export type CustomMessages = { [status: number]: string; default: string };

interface ApiErrorProps {
  error: any;
  customMessages?: CustomMessages;
  title?: string;
}

export const ApiError = ({ error, customMessages, title }: ApiErrorProps) => {
  const status = error?.status;
  let message = customMessages?.default ?? 'Unknown error';

  if (customMessages?.[status]) {
    message = customMessages?.[status];
  }

  const errorMessage = error?.data?.error || error?.data?.message;
  if (errorMessage) {
    message = errorMessage;
  }

  return (
    status && (
      <Alert
        variant="danger"
        message={`${message || 'Unknown error'}`}
        hasIcon
        title={title}
        style={{ wordBreak: 'break-word' }}
      />
    )
  );
};
