import { Box, Button, Popover, useOpenClose } from '@hyphen/hyphen-components';
import { Link } from 'react-router-dom';
import { App } from '../../services/apps';
import { ColorDot } from './ColorDot';

interface EnvironmentItemProps {
  id?: string;
  app?: App;
  color?: string;
  name: string;
  secrets?: any;
  hideSecrets?: boolean;
}

function EnvironmentItem(props: EnvironmentItemProps) {
  const { color, name, secrets, hideSecrets, app, id = '*' } = props;

  const { isOpen: isPopoverOpen, handleOpen: openPopover, handleClose: closePopover } = useOpenClose();

  return (
    <Link
      to={`/${app?.organization?.id}/${app?.project?.alternateId}/app/${app?.alternateId}/${id}`}
      style={{ textDecoration: 'none' }}
    >
      <Box
        borderWidth="0"
        width="100"
        height="100"
        padding="md md md xl"
        background="secondary"
        direction="row"
        gap="xs"
        alignItems="center"
        radius="md"
        cursor="pointer"
        hover={{ background: 'tertiary' }}
      >
        {color && <ColorDot color={color} />}
        <Box fontFamily="monospace" flex="auto">
          {name}
        </Box>
        {hideSecrets ? (
          <Popover
            content={<>You do not have permission to access ENV secrets</>}
            isOpen={isPopoverOpen}
            onClickOutside={closePopover}
            placement="top"
            contentContainerProps={{
              padding: 'md',
              background: 'inverse',
              color: 'inverse',
              gap: 'xs',
              fontSize: 'xs',
            }}
          >
            <Button
              onMouseEnter={openPopover}
              onMouseLeave={closePopover}
              onFocus={openPopover}
              onBlur={closePopover}
              variant="tertiary"
              size="sm"
              iconPrefix="ban"
              aria-label="no access to ENV secrets"
            />
          </Popover>
        ) : secrets ? (
          <Box
            borderWidth="0"
            onMouseEnter={openPopover}
            onMouseLeave={closePopover}
            onFocus={openPopover}
            onBlur={closePopover}
            fontSize="xs"
            background="primary"
            radius="sm"
            padding="sm"
            gap="xs"
            textAlign="right"
            color={secrets.countVariables < 1 ? 'secondary' : 'base'}
          >
            <Box>{secrets.countVariables} secrets</Box>
            {secrets.countVariables > 0 && <Box color="secondary">{`v${secrets.version} - ${secrets.size}`}</Box>}
          </Box>
        ) : (
          <Box color="tertiary">no secrets</Box>
        )}
      </Box>
    </Link>
  );
}

export default EnvironmentItem;
