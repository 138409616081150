import { EntityType } from '../types/executionContext';

export const getEntityUrl = (
  organizationId: string,
  entityType: EntityType,
  entityId: string,
  projectId?: string,
): string => {
  console.log(projectId);
  if (
    (entityType === 'Toggle' ||
      entityType === 'App' ||
      entityType === 'Project' ||
      entityType === 'ProjectEnvironment') &&
    !projectId
  ) {
    throw new Error(`projectId is required when entityType is ${entityType}`);
  }

  switch (entityType) {
    case 'Project':
      return `/${organizationId}/${projectId}`;
    case 'App':
      return `/${organizationId}/${projectId}/app/${entityId}`;
    case 'Team':
      return `/${organizationId}/teams/${entityId}`;
    case 'LinkCode':
      return `/${organizationId}/link/${entityId}`;
    case 'Toggle':
      return `/${organizationId}/${projectId}/toggles/${entityId}`;
    case 'Member':
      return `/${organizationId}/settings/members/${entityId}`;
    case 'ProjectEnvironment':
      //TODO: fix with project environment page
      return `/${organizationId}/${projectId}`;
    default:
      throw new Error(`Unrecognized entityType: ${entityType}`);
  }
};
