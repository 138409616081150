import { ConnectionsList } from '../integrations/connections/ConnectionsList';
import { Helmet } from 'react-helmet';
import { useProject } from '../../providers/ProjectProvider';
import { useOrganization } from '../../providers/OrganizationProvider';

export default function ProjectConnections() {
  const { project } = useProject();
  const { id: orgId } = useOrganization();

  return (
    <>
      <Helmet title={`${project?.name} Integration Connections`} />
      <ConnectionsList
        organizationId={orgId}
        filter={{
          entity: {
            type: 'Project',
            id: project?.id ?? '',
            name: project?.name ?? '',
          },
          entityType: 'Project',
        }}
      />
    </>
  );
}
