import DeleteProject from '../../../components/projects/DeleteProject';
import { useOrganizationAbilityContext } from '../../../components/auth/OrganizationAbilityProvider';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { Box, Button, Card, Heading, toast, useBreakpoint, useOpenClose } from '@hyphen/hyphen-components';
import { Organization } from '../../../services/organization';
import { useOrganization } from '../../../providers/OrganizationProvider';
import { EnvironmentActionItem, EnvironmentDataType } from '../../../environments/EnvironmentActionItem';
import {
  useCreateProjectEnvironmentMutation,
  useDeleteProjectEnvironmentMutation,
  useGetProjectEnvironmentsQuery,
  useUpdateProjectEnvironmentMutation,
} from '../../../services/environments';
import { EnvironmentModal, EnvironmentSchema } from '../../../environments/EnvironmentModal';
import { EntityNames } from '../../../types/executionContext';
import { useProject } from '../../../providers/ProjectProvider';
import { Skeleton } from '../../../components/common/Skeleton';

export default function ProjectSettings() {
  const { isOpen: isCreateModalOpen, handleOpen: openCreateModal, handleClose: closeCreateModal } = useOpenClose();
  const { projectId } = useParams<{ projectId: string }>();
  const { project } = useProject();
  const { organization = {} as Organization } = useOrganization();
  const { isPhone } = useBreakpoint();
  const ability = useOrganizationAbilityContext();

  const [createProjectEnvironment, { error: createError }] = useCreateProjectEnvironmentMutation();
  const [updateProjectEnvironment, { error: updateError, isLoading: isUpdating }] =
    useUpdateProjectEnvironmentMutation();
  const [deleteProjectEnvironment, { error: deleteError, isLoading: isDeleting }] =
    useDeleteProjectEnvironmentMutation();
  const { data, isLoading } = useGetProjectEnvironmentsQuery({ organizationId: organization.id, projectId });
  const projectEnvironments = data?.data ?? [];

  const canDeleteProject = ability.can('delete', EntityNames.Project);
  const canUpdateProjectEnv = ability.can('update', EntityNames.ProjectEnvironment);
  const canCreateProjectEnv = ability.can('create', EntityNames.ProjectEnvironment);

  const handleCreate = async (values: EnvironmentSchema) => {
    if (projectId) {
      const { data, error } = await createProjectEnvironment({
        projectId,
        organizationId: organization.id,
        data: values,
      });
      if (!error && data) {
        closeCreateModal();
        toast.success('Environment created successfully');
      }
    }
  };

  const handleEdit = async (
    values: EnvironmentSchema,
    defaultEnvironment: EnvironmentDataType,
    onClose: () => void,
  ) => {
    if (projectId) {
      const { data, error } = await updateProjectEnvironment({
        projectId,
        organizationId: organization.id,
        environmentId: defaultEnvironment.id,
        data: values,
      });
      if (!error && data) {
        toast.success('Environment updated successfully');
        onClose();
      }
    }
  };

  const handleDelete = async (defaultEnvironment: EnvironmentDataType) => {
    if (projectId) {
      const { error } = await deleteProjectEnvironment({
        projectId,
        organizationId: organization.id,
        environmentId: defaultEnvironment.id,
      });
      if (!error) {
        toast.success('Environment deleted successfully');
      }
    }
  };

  const renderProjectEnvLoading = () => (
    <Box className="row-item" borderWidth="sm 0 0 0" borderColor="subtle">
      <Box
        direction="row"
        alignItems="center"
        padding={{ base: '2xl', desktop: '3xl' }}
        gap="2xl"
        justifyContent="space-between"
      >
        <Box gap="xs" flex="auto">
          <Skeleton width="100px" height="18px" />
          <Skeleton width="80px" height="12px" />
        </Box>
        <Box width="120px" direction="row" gap="sm" alignItems="center" color="secondary">
          <Skeleton width="32px" height="32px" />
          <Skeleton width="50px" height="14px" />
        </Box>
        <Skeleton width="36px" height="32px" />
      </Box>
    </Box>
  );

  return (
    <>
      <Helmet title={`${project?.name || 'Loading'} Settings`} />
      <Card>
        <Card.Section>
          <Box direction="row" gap="2xl" justifyContent="space-between" alignItems="center">
            <Box gap="2xs">
              <Heading as="h4" size="sm">
                Project Environments
              </Heading>
              <Box color="secondary" fontSize="sm">
                Apps in this project will have these environments
              </Box>
            </Box>
            {canCreateProjectEnv && (
              <Button
                aria-label="Add environment"
                size="sm"
                variant="primary"
                onClick={openCreateModal}
                style={{ width: 'fit-content' }}
                iconPrefix={isPhone ? 'add' : undefined}
              >
                {isPhone ? '' : 'Add Environment'}
              </Button>
            )}
          </Box>
        </Card.Section>
        <Card.Section padding="0">
          {isLoading ? (
            <>
              {renderProjectEnvLoading()}
              {renderProjectEnvLoading()}
            </>
          ) : projectEnvironments.length > 0 ? (
            projectEnvironments.map((env) => (
              <EnvironmentActionItem
                key={env.id}
                defaultEnvironment={env}
                canManage={canUpdateProjectEnv}
                onEdit={handleEdit}
                onDelete={handleDelete}
                deleteMessage="The environment will be removed from all apps within this project. Are you sure you want to delete this environment?"
                error={updateError || deleteError}
                isLoading={isUpdating || isDeleting}
                showAlternateId={false}
              />
            ))
          ) : (
            <Box
              padding="2xl"
              fontSize="sm"
              color="secondary"
              alignItems="center"
              justifyContent="center"
              minHeight="5xl"
            >
              No project environments exist
            </Box>
          )}
        </Card.Section>
      </Card>
      {canDeleteProject && <DeleteProject />}
      <EnvironmentModal
        title="Add Project Environment"
        message="Create an environment for every app in this project."
        isOpen={isCreateModalOpen}
        onClose={closeCreateModal}
        onSubmit={handleCreate}
        error={createError}
      />
    </>
  );
}
