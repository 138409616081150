import { IntegrationSetupForm } from '../common/IntegrationSetupForm';
import { JiraSetupFormConfig, IntegrationType } from '../../../../types/integrations';
import * as yup from 'yup';

interface JiraSetupFormProps {
  organizationId: string;
}

const jiraSetupFormSchema = yup.object().shape({
  cloudId: yup.string().required('Required'),
  email: yup.string().email('Invalid email').required('Required'),
  apiToken: yup.string().required('Required'),
  organizationId: yup.string().required('Required'),
});

const jiraFormConfig = [
  { id: 'cloudId', type: 'text', label: 'Cloud ID', initialValue: '' },
  { id: 'email', type: 'email', label: 'Email Address', initialValue: '' },
  { id: 'apiToken', type: 'password', label: 'API Token', initialValue: '' },
  { id: 'organizationId', type: 'text', label: 'Organization ID', initialValue: '' }, // Add this field
];

export const JiraSetupForm = ({ organizationId }: JiraSetupFormProps) => (
  <IntegrationSetupForm
    organizationId={organizationId}
    validationSchema={jiraSetupFormSchema}
    formConfig={jiraFormConfig}
    configMapper={(values: JiraSetupFormConfig) => ({
      cloudId: values.cloudId,
      email: values.email,
      apiToken: values.apiToken,
      organizationId: values.organizationId,
    })}
    integrationType={IntegrationType.jira}
  />
);
