import { Box, Button, Heading } from '@hyphen/hyphen-components';
import { Link } from 'react-router-dom';
import { Guide } from '../../../guide/Guide';
import { GuideStep } from '../../../guide/GuideStep';
import { NewRelicSetupForm } from './NewRelicSetupForm';

const NEW_RELIC_URLS = {
  LOGIN: 'https://login.newrelic.com/login',
  SIGNUP: 'https://newrelic.com/signup',
  DOCS: 'https://docs.newrelic.com/docs/apis/intro-apis/new-relic-api-keys/',
  API_KEYS_UI: 'https://one.newrelic.com/launcher/api-keys-ui.api-keys-launcher',
} as const;

const REQUIRED_PERMISSIONS = ['User management', 'Authentication domain management', 'Group management'] as const;

const SECURITY_RECOMMENDATIONS = [
  'Keep your API keys secure and treat them like passwords',
  'Implement key rotation strategy for license and browser keys',
  'Remove user IDs when team members leave the organization',
  'Create additional ingest keys instead of using original ones for better security',
] as const;

interface NewRelicSetupGuideProps {
  organizationId: string;
}

export const NewRelicSetupGuide: React.FC<NewRelicSetupGuideProps> = ({ organizationId }) => {
  const externalLinkProps = {
    target: '_blank',
    rel: 'noopener noreferrer',
    'aria-label': 'Opens in new tab',
  };

  return (
    <Guide title="New Relic Setup Guide">
      <GuideStep title="Create or sign into your New Relic account">
        <Box as="p" marginBottom="md">
          New Relic uses API keys to authenticate and verify your identity. These keys allow approved people to:
        </Box>
        <Box as="ul" childGap="sm" marginBottom="lg" paddingLeft="xl">
          <li>Report data to New Relic</li>
          <li>Access and query your data</li>
          <li>Configure features and settings</li>
        </Box>

        <Button asChild iconSuffix="launch-app" variant="primary">
          <Link to={NEW_RELIC_URLS.LOGIN} {...externalLinkProps}>
            Sign in to New Relic
          </Link>
        </Button>

        <p>or</p>

        <Button asChild iconSuffix="launch-app" variant="secondary">
          <Link to={NEW_RELIC_URLS.SIGNUP} {...externalLinkProps}>
            Create a new New Relic account
          </Link>
        </Button>
      </GuideStep>

      <GuideStep title="Generate User API Key">
        <Box as="p" marginBottom="md">
          You'll need a User API key to access NerdGraph, New Relic's GraphQL API. This key is essential for:
        </Box>
        <Box as="ul" childGap="sm" marginBottom="lg" paddingLeft="xl">
          <li>Querying data from your New Relic account</li>
          <li>Configuring features and settings</li>
          <li>Managing user access and permissions</li>
        </Box>

        <Button asChild iconSuffix="launch-app" variant="primary">
          <Link to={NEW_RELIC_URLS.API_KEYS_UI} {...externalLinkProps}>
            Go to API Keys UI to generate your key
          </Link>
        </Button>

        <Box marginBottom="lg">
          <Heading as="h4" size="sm" marginBottom="sm">
            Required API Key Permissions:
          </Heading>
          <Box as="ul" childGap="xs" paddingLeft="xl">
            {REQUIRED_PERMISSIONS.map((permission) => (
              <li key={permission}>{permission}</li>
            ))}
          </Box>
        </Box>

        <Box backgroundColor="surface-warning-subtle" padding="lg" borderRadius="sm">
          <Heading as="h4" size="sm" marginBottom="sm">
            Security Recommendations:
          </Heading>
          <Box as="ul" childGap="xs" paddingLeft="xl">
            {SECURITY_RECOMMENDATIONS.map((recommendation) => (
              <li key={recommendation}>{recommendation}</li>
            ))}
          </Box>
        </Box>
      </GuideStep>

      <GuideStep title="Connect New Relic Account">
        <Box as="p" marginBottom="md">
          Enter your User API key below to complete the integration. This key will be used to:
        </Box>
        <Box as="ul" childGap="sm" marginBottom="lg" paddingLeft="xl">
          <li>Authenticate your account</li>
          <li>Enable data access and management</li>
          <li>Configure integration settings</li>
        </Box>
        <NewRelicSetupForm organizationId={organizationId} />
      </GuideStep>
    </Guide>
  );
};
