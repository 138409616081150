import { Badge, Box, Spinner } from '@hyphen/hyphen-components';
import PageHeader from '../../components/PageHeader';
import { ScrollableMainLayout } from '../../components/ScrollableMainLayout';
import { useOrganization } from '../../providers/OrganizationProvider';
import { Organization } from '../../services/organization';
import { Route, Routes, useParams } from 'react-router-dom';
import ClickToCopy from '../../components/common/ClickToCopy';
import Error from '../../Error';
import { TabNavLink, TabNav } from '../../components/TabNav';
import { useGetTeamQuery } from '../../services/teams';
import TeamDetail from './TeamDetail';
import TeamSettings from './TeamSettings';
import { TeamDetails } from '../../types/teams';
import { AddMemberModal } from '../../components/members/AddMemberModal';
import TeamConnections from '../../components/teams/TeamConnections';
import ConnectionVerificationModal from '../../components/integrations/connections/ConnectionVerificationModal';
import { BreadCrumbPortal } from '../../components/common/Breadcrumb';

export default function TeamContainer() {
  const { organization = {} as Organization } = useOrganization();
  const { id } = useParams<{ id: any }>();

  const { isLoading, error, data: team = {} as TeamDetails } = useGetTeamQuery({ orgId: organization.id, id });

  if (!isLoading && error) return <Error />;

  return (
    <ScrollableMainLayout>
      {isLoading ? (
        <Box gap="md" direction="row" alignItems="center">
          <Spinner size="md" />
        </Box>
      ) : (
        <>
          <PageHeader
            flex="auto"
            title={
              <Box direction="row" gap="md" alignItems="center">
                {team.name} {team.type === 'dynamic' && <Badge message="dynamic" variant="purple" size="sm" />}
              </Box>
            }
            description={
              team.distributionList && <ClickToCopy text={team.distributionList.email} hideIcon fontSize="xs" />
            }
            favoriteId={team.id}
          />
          <BreadCrumbPortal entity={team} />
          <TabNav>
            <TabNavLink to="" countLabel={`${team.memberCount}`}>
              Members
            </TabNavLink>
            <TabNavLink to="connections">Connections</TabNavLink>
            <TabNavLink to="settings">Settings</TabNavLink>
          </TabNav>

          <Routes>
            <Route path="/*" element={<TeamDetail team={team} />}>
              <Route path="add" element={<AddMemberModal isOpen onClose={() => {}} data={{ organization }} />} />
            </Route>
            <Route path="settings/*" element={<TeamSettings team={team} />} />
            <Route path="connections/*" element={<TeamConnections team={team} organization={organization} />}>
              <Route
                path=":connectionId/verify"
                element={<ConnectionVerificationModal organization={organization} closePath={'..'} />}
              />
            </Route>
          </Routes>
        </>
      )}
    </ScrollableMainLayout>
  );
}
