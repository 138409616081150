import { Box, Heading, RadioGroup, useTheme, Theme } from '@hyphen/hyphen-components';
import { Helmet } from 'react-helmet';
import { useAuth } from '../auth/authProvider';
import PageHeader from '../PageHeader';

export function MyProfile() {
  const { user } = useAuth();

  const { theme, setTheme } = useTheme();

  const options = [
    {
      id: 'light',
      value: 'light',
      label: 'Light',
    },
    {
      id: 'dark',
      value: 'dark',
      label: 'Dark',
    },
    {
      id: 'system',
      value: 'system',
      label: 'System',
    },
  ];

  return (
    <>
      <Helmet>
        <title>My Profile</title>
      </Helmet>
      <PageHeader title="My Profile" description="Manage your Hyphen profile" />
      <Box gap="lg">
        <Box
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          borderWidth="0 0 xs 0"
          borderColor="default"
          padding="0 0 xs 0"
        >
          <Box fontWeight="medium">Name</Box>
          <Box>{user?.name !== 'undefined undefined' ? user?.name : <>&mdash;</>}</Box>
        </Box>
        <Box
          direction="row"
          alignItems="flex-start"
          justifyContent="space-between"
          borderWidth="0 0 xs 0"
          borderColor="default"
          padding="0 0 xs 0"
        >
          <Box fontWeight="medium">Email</Box>
          <Box direction="column" gap="xs" alignItems="flex-end">
            {user?.email}
          </Box>
        </Box>
      </Box>
      <Box gap="xl" borderColor="default" borderWidth="sm 0 0 0" padding="4xl 0 0 0">
        <Box gap="xs">
          <Heading as="h4" size="sm">
            Theme
          </Heading>
          <Box as="p" color="secondary" fontSize="sm">
            Choose how Hyphen looks to you, or sync with your system and automatically switch between light and dark
            themes
          </Box>
        </Box>
        <RadioGroup
          name="Theme"
          value={theme}
          onChange={(event) => setTheme(event.target.value as Theme)}
          options={options}
        />
      </Box>
    </>
  );
}
