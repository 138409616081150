import { Box, BoxProps, Button, Heading } from '@hyphen/hyphen-components';
import { ReactNode } from 'react';
import { ReactSVG } from 'react-svg';
import { useOrganizationMember } from '../providers/OrganizationMemberProvider';

import { useOrganization } from '../providers/OrganizationProvider';
import { useFavoriteHandler } from '../hooks/useFavoriteHandler';

interface PageHeaderProps extends BoxProps {
  title: ReactNode;
  description?: ReactNode;
  children?: ReactNode;
  className?: string;
  iconUrl?: string;
  favoriteId?: string;
}

const PageHeader = ({
  title,
  description,
  children,
  className,
  iconUrl,
  favoriteId,
  ...boxProps
}: PageHeaderProps) => {
  const { member } = useOrganizationMember();
  const { id: orgId } = useOrganization();

  const { isMemberFavorite, isFavoriteLoading, handleFavorite } = useFavoriteHandler({
    favoriteId: favoriteId,
    member: member,
    orgId,
  });

  return (
    <Box className={className} color="base" gap="xl" {...boxProps}>
      <Box direction="row" gap={{ base: 'lg', tablet: 'xl' }}>
        {iconUrl && (
          <Box
            background="primary"
            width={{ base: '4xl', tablet: '5xl' }}
            minWidth={{ base: '4xl', tablet: '5xl' }}
            height={{ base: '4xl', tablet: '5xl' }}
            borderWidth="sm"
            borderColor="subtle"
            alignItems="center"
            justifyContent="center"
            shadow="xs"
            radius="md"
            padding={{ base: 'xs', tablet: 'sm' }}
          >
            <ReactSVG
              src={iconUrl}
              beforeInjection={(svg) => svg.setAttribute('style', 'width: 100%; height: 100%; display: block;')}
            />
          </Box>
        )}
        <Box alignItems="flex-start" gap="xs">
          <Box direction="row" gap="sm" alignItems="center">
            <Heading as="h1" size={{ base: 'md', tablet: 'lg', desktop: 'xl' }}>
              {title}
            </Heading>
            {favoriteId && (
              <Button
                onClick={handleFavorite}
                iconPrefix={isFavoriteLoading ? 'dots' : isMemberFavorite ? 'star' : 'star-o'}
                variant="tertiary"
                size="md"
              />
            )}
          </Box>
          {description && (
            <Box
              fontSize={{
                base: 'sm',
              }}
              color="secondary"
            >
              {description}
            </Box>
          )}
        </Box>
      </Box>

      {children && <Box display="block">{children}</Box>}
    </Box>
  );
};

export default PageHeader;
