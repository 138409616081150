import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useState, useCallback, useEffect } from 'react';
import {
  Box,
  Heading,
  Button,
  toast,
  useOpenClose,
  Modal,
  Icon,
  DrawerProvider,
  DrawerTrigger,
} from '@hyphen/hyphen-components';
import { Fragment } from 'react/jsx-runtime';
import { Target } from './Target';
import LogicalOperator from './LogicalOperator';
import { useUpdateToggleTargetsMutation } from '../../services/toggle';
import { useOrganization } from '../../providers/OrganizationProvider';
import { Organization } from '../../services/organization';
import { useOrganizationAbilityContext } from '../auth/OrganizationAbilityProvider';
import { useToggle } from '../../providers/ToggleProvider';
import TargetDrawer from './TargetDrawer';
import DefaultReturnValueCard from './DefaultReturnValueCard';
import { Skeleton } from '../common/Skeleton';

const Targets = () => {
  const { toggle, isLoading } = useToggle();

  const { organization = {} as Organization } = useOrganization();
  const { isOpen, handleOpen: showErrorModal, handleClose } = useOpenClose();
  const ability = useOrganizationAbilityContext();
  const canUpdateToggle = toggle ? ability.can('update', toggle) : false;

  const [updateToggleTargets] = useUpdateToggleTargetsMutation();

  const [items, setItems] = useState(toggle?.targets || []);
  const [previousItems, setPreviousItems] = useState(toggle?.targets || []);
  const [isDragging, setIsDragging] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null);
  const [highlightTimeout, setHighlightTimeout] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    setItems(toggle?.targets || []);
  }, [toggle?.targets]);

  useEffect(() => {
    if (highlightedIndex !== null) {
      if (highlightTimeout) clearTimeout(highlightTimeout);
      const timer = setTimeout(() => {
        setHighlightedIndex(null);
      }, 2100);
      setHighlightTimeout(timer);
      return () => clearTimeout(timer);
    }
    // do not need to include highlightTimeout in the dependency array since it is only used for cleanup and does not change the effect behavior.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightedIndex]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates }),
  );

  const handleDragStart = () => {
    if (!canUpdateToggle) return;
    setIsDragging(true);
    setPreviousItems(items);
  };

  const handleDragEnd = useCallback(
    async (event: DragEndEvent) => {
      if (!toggle || !canUpdateToggle) return;

      const { active, over } = event;

      if (!over || active.id === over.id) return;

      const newTargetOrder = arrayMove(
        items,
        items.findIndex((item) => item.id === active.id),
        items.findIndex((item) => item.id === over.id),
      );

      if (JSON.stringify(newTargetOrder) === JSON.stringify(items)) return;

      setItems(newTargetOrder);

      try {
        const { error, data } = await updateToggleTargets({
          organizationId: organization.id,
          projectId: toggle.project.alternateId,
          toggleKey: toggle.key,
          body: { targets: newTargetOrder },
        });

        if (!error && data) {
          setIsDragging(false);
          toast.success('Order updated');
        } else {
          throw new Error('Failed to update targets');
        }
      } catch (err) {
        setItems(previousItems);
        showErrorModal();
      }
    },
    [toggle, canUpdateToggle, items, updateToggleTargets, organization.id, previousItems, showErrorModal],
  );

  const handleUpdateItems = (highlightedIndex: number | null = null) => {
    setHighlightedIndex(highlightedIndex);
  };

  if (isLoading || !toggle || !Array.isArray(toggle.targets))
    return <Skeleton radius="md" background="secondary" width="100" minHeight="210px" />;

  const { type: returnType, defaultValue } = toggle;

  return (
    <Box background="secondary" padding="0" radius="md">
      <Box
        direction="row"
        alignItems="center"
        gap="lg"
        borderWidth="0 0 sm 0"
        borderColor="default"
        padding={{ base: '2xl', tablet: '3xl' }}
      >
        <Box flex="auto">
          <Heading as="h2" size="sm">
            Targets ({items.length})
          </Heading>
        </Box>
        {canUpdateToggle && (
          <DrawerProvider>
            <DrawerTrigger asChild>
              <Button size="sm" variant="primary">
                Add Target
              </Button>
            </DrawerTrigger>
            <TargetDrawer onUpdateItems={handleUpdateItems} />
          </DrawerProvider>
        )}
      </Box>
      <Box as="ol" gap={{ base: 'lg', desktop: '2xl' }} padding={{ base: '2xl', tablet: '3xl' }}>
        {canUpdateToggle ? (
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
          >
            <SortableContext items={items} strategy={verticalListSortingStrategy}>
              {items.map((targetContext: any, index: number) => (
                <Fragment key={index}>
                  <Target
                    isDraggable
                    key={targetContext.id}
                    returnType={returnType}
                    targetContext={targetContext}
                    handleUpdateItems={handleUpdateItems}
                    isHighlighted={index === highlightedIndex}
                  />
                  {index < items.length - 1 && <LogicalOperator isDragging={isDragging} operator="OR" />}
                </Fragment>
              ))}
            </SortableContext>
            {items.length > 0 && <LogicalOperator isDragging={false} operator="ELSE" />}
            <DefaultReturnValueCard defaultReturnValue={defaultValue} canUpdateToggle={canUpdateToggle} />
          </DndContext>
        ) : (
          <>
            {items.map((targetContext: any, index: number) => (
              <Fragment key={index}>
                <Target
                  isHighlighted={false}
                  isDraggable={false}
                  returnType={returnType}
                  targetContext={targetContext}
                />
                {index < items.length - 1 && <LogicalOperator isDragging={isDragging} operator="OR" />}
              </Fragment>
            ))}
            {items.length > 0 && <LogicalOperator isDragging={false} operator="ELSE" />}
            <DefaultReturnValueCard defaultReturnValue={defaultValue} />
          </>
        )}
      </Box>
      <Modal isOpen={isOpen} onDismiss={handleClose} background="danger" maxWidth="9xl" ariaLabelledBy="orderError">
        <Modal.Body gap="lg" textAlign="center" alignItems="center">
          <Icon name="t-warning" size="4xl" color="danger" />
          <Box as="p" id="orderError" margin="0 0 lg 0">
            Failed to update targets. Reverting to previous order.
          </Box>
          <Button onClick={handleClose}>Acknowledge</Button>
        </Modal.Body>
      </Modal>
    </Box>
  );
};

export default Targets;
