import { Box, Button, TextInput } from '@hyphen/hyphen-components';
import { Link } from 'react-router-dom';
import { CodeBlock } from '../../../Codeblock';
import { Guide } from '../../../guide/Guide';
import { GuideStep } from '../../../guide/GuideStep';
import { AzureSetupForm } from './AzureSetupForm';
import { useState } from 'react';

interface AzureSetupGuideProps {
  organizationId: string;
}

export const AzureSetupGuide = ({ organizationId }: AzureSetupGuideProps) => {
  const [subscriptionId, setSubscriptionId] = useState('###SUBSCRIPTION_ID###');
  const [objectId, setObjectId] = useState('###OBJECT_ID###');

  const AddServicePrincipal = `az ad sp create --id ${process.env.REACT_APP_AZ_CLIENT_ID || ''}`;

  const AddRoleDefinition = `az role definition create --role-definition '{
        "Name": "Hyphen",
        "Description": "Create and manage resources via Hyphen",
        "AssignableScopes": [
            "subscriptions/${subscriptionId}"
        ],
        "Actions": [
                    "Microsoft.Authorization/roleAssignments/write",
                    "Microsoft.Authorization/roleAssignments/delete",
                    "Microsoft.Authorization/roleAssignments/read",
                    "Microsoft.Resources/subscriptions/read",
                    "Microsoft.Resources/subscriptions/resourceGroups/read",
                    "Microsoft.Resources/subscriptions/resourceGroups/write",
                    "Microsoft.Resources/subscriptions/resourceGroups/delete"
                ],
        "NotActions": [],
        "DataActions": [],
        "NotDataActions": []
    }'`;

  const AssignRole = `az role assignment create --assignee ${process.env.REACT_APP_AZ_CLIENT_ID || ''
    } --role "Hyphen" --scope subscriptions/${subscriptionId}`;

  const UpdateServicePrincipal = `az ad sp update --id ${objectId} --set 'tags=["WindowsAzureActiveDirectoryIntegratedApp"]'`;

  return (
    <Guide title="Setup Guide">
      <GuideStep title="Create or sign into your Azure account">
        <p>
          <Button asChild iconSuffix="launch-app">
            <Link to="https://portal.azure.com/" target="_blank" rel="noreferrer">
              Sign in to Azure Portal
            </Link>
          </Button>
        </p>
        <p>or</p>
        <p>
          <Button asChild iconSuffix="launch-app">
            <Link to="https://azure.microsoft.com/en-us/free/" target="_blank" rel="noreferrer">
              Create a new Azure Cloud account
            </Link>
          </Button>
        </p>
      </GuideStep>
      <GuideStep
        title={
          <>
            <p>Add Hyphen application</p>
            <p>
              <Link to="https://azure.microsoft.com/en-us/get-started/azure-portal/cloud-shell/" target="_blank">
                Azure Cloud Shell Documentation
              </Link>
            </p>
          </>
        }
      >
        <Button asChild iconSuffix="launch-app">
          <Link to="https://portal.azure.com/#cloudshell/" target="_blank" rel="noreferrer">
            Open Azure Cloud Shell
          </Link>
        </Button>

        <p>Execute the following command:</p>
        <p>
          <CodeBlock>{AddServicePrincipal}</CodeBlock>
        </p>
        <p>
          You should see in the response that the <code>verifiedPublisher</code> contains <code>Hyphen AI Inc</code>{' '}
          with id <code>6875541</code>
        </p>
      </GuideStep>
      <GuideStep title="Copy and paste your subscription ID">
        <p>Copy and paste the subscription ID for the subscription you would like to use.</p>
        <p>
          <TextInput
            id="subscriptionId"
            name="subscriptionId"
            label="Enter your Azure subscription ID"
            helpText="We'll update the commands with your subscription ID"
            value={subscriptionId}
            onChange={(e) => {
              setSubscriptionId(e.target.value);
            }}
            placeholder="Enter Azure Subscription ID"
          />
        </p>
      </GuideStep>
      <GuideStep title="Create Hyphen role">
        <p>In the Azure Cloud Shell, enter the following command.</p>
        <Box>
          <CodeBlock>{AddRoleDefinition}</CodeBlock>
        </Box>
      </GuideStep>
      <GuideStep title="Assign Hyphen role">
        <p>In the Azure Cloud Shell, enter the following command.</p>
        <p>
          <strong>
            <em>
              Note: Replace the `##SubscriptionId##` with the subscription ID where you will have Hyphen deploy
              resources.
            </em>
          </strong>
        </p>
        <Box>
          <CodeBlock>{AssignRole}</CodeBlock>
        </Box>
      </GuideStep>
      <GuideStep
        title={
          <>
            <p>Grant Hyphen application admin consent</p>
            <p>
              <Link
                to="https://learn.microsoft.com/en-us/entra/identity/enterprise-apps/manage-consent-requests"
                target="_blank"
              >
                Grant Application Consent Documentation
              </Link>
            </p>
          </>
        }
      >
        <Box as="ol" childGap="2xl" padding="0 0 0 2xl">
          <li>
            In the Azure Portal, search for <code>Hyphen Dev</code> in the search box, and select the Hyphen
            Enterprise Application.
          </li>
          <li>
            Go to <strong>Security</strong> &gt; <strong>Permissions</strong>
          </li>
          <li>
            <p>
              On the permissions page, click the <strong>Grant admin consent for Default Directory</strong> button
            </p>
            <p>Doing so will grant these permissions:</p>
            <ul>
              <li>Directory.Read.All</li>
              <li>Group.ReadWrite.All</li>
              <li>User.Read.All</li>
              <li>Channel.Create</li>
              <li>ChannelMember.ReadWrite.All</li>
              <li>ChannelSettings.Read.All</li>
            </ul>
          </li>
        </Box>
      </GuideStep>
      <GuideStep title="Copy and paste your object ID">
        <p>
          Go to <strong>Overview</strong> and copy the <strong>Object ID</strong> of the Hyphen application
        </p>
        <p>
          <TextInput
            id="objectId"
            name="objectId"
            label="Enter your Azure object ID"
            helpText="We'll update the commands with your object ID"
            value={objectId}
            onChange={(e) => {
              setObjectId(e.target.value);
            }}
            placeholder="Enter Azure object ID"
          />
        </p>
      </GuideStep>
      <GuideStep title="Add Enterprise Application tag">
        <p>
          In the Azure Cloud Shell, enter the following command to add the Enterprise Application tag to the Hyphen
          application.
        </p>
        <Box>
          <CodeBlock>{UpdateServicePrincipal}</CodeBlock>
        </Box>

        <p>
          <em>
            Note: When browsing the Enterprise Applications Blade, you may need to remove the "Enterprise
            Applications" filter to see the Hyphen application.
          </em>
        </p>
      </GuideStep>
      <GuideStep title="Connect Tenant">
        <AzureSetupForm organizationId={organizationId} />
      </GuideStep>
    </Guide>
  );
};
