import { Helmet } from 'react-helmet';
import { EditAppForm } from '../../../components/app/EditApp';
import DeleteApp from '../../../components/app/DeleteApp';
import { App } from '../../../services/apps';
import { useOrganizationAbilityContext } from '../../../components/auth/OrganizationAbilityProvider';
import { Skeleton } from '../../../components/common/Skeleton';
import { useMemo } from 'react';

export const AppSettings = ({ app, isLoading }: { app?: App; isLoading: boolean }) => {
  const ability = useOrganizationAbilityContext();

  const canUpdateApp = useMemo(() => !isLoading && app && ability.can('update', app), [isLoading, app, ability]);

  if (isLoading) {
    return (
      <>
        <Helmet title="Loading Settings" />
        <Skeleton height="162px" width="100" />
        <Skeleton height="80px" width="100" />
      </>
    );
  }

  return (
    <>
      <Helmet title={`${app?.name || 'App'} Settings`} />
      {canUpdateApp ? (
        <>
          {app && <EditAppForm app={app} />}
          <DeleteApp app={app} />
        </>
      ) : (
        <p>You do not have permission</p>
      )}
    </>
  );
};

export default AppSettings;
