import { IntegrationSetupForm } from '../common/IntegrationSetupForm';
import { MicrosoftTeamsIntegrationConfig, IntegrationType } from '../../../../types/integrations';
import * as yup from 'yup';

interface MicrosoftTeamsSetupFormProps {
  organizationId: string;
}

const microsoftTeamsSetupFormSchema = yup.object().shape({
  azureTenantId: yup.string().required('Required'),
});

const microsoftTeamsFormConfig = [{ id: 'azureTenantId', type: 'text', label: 'Azure Tenant ID', initialValue: '' }];

export const MicrosoftTeamsSetupForm = ({ organizationId }: MicrosoftTeamsSetupFormProps) => (
  <IntegrationSetupForm
    organizationId={organizationId}
    validationSchema={microsoftTeamsSetupFormSchema}
    formConfig={microsoftTeamsFormConfig}
    configMapper={(values: MicrosoftTeamsIntegrationConfig) => ({
      azureTenantId: values.azureTenantId,
    })}
    integrationType={IntegrationType.microsoftTeams}
  />
);
