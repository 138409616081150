import { Outlet } from 'react-router-dom';
import { EnvsList } from '../../../components/env/EnvList';
import { EventList } from '../../../components/env/EventList';
import { App } from '../../../services/apps';

export default function AppOverview({ app, isLoading }: { app?: App; isLoading: boolean }) {
  return (
    <>
      <EnvsList isLoading={isLoading} app={app} />
      {app && <EventList appId={app.id} title="Event Log" />}
      <Outlet />
    </>
  );
}
