import { DropdownMenuItem, Icon, toast } from '@hyphen/hyphen-components';
import { Project } from '../../services/projects';
import { useLazyGetPublicKeysQuery } from '../../services/publicKeys';

// Assumes there is one public key per project since only one can be created in the UI
// However, the API allows for multiple public keys to be created
// If we want to support multiple public keys, we'll need to open a modal to list the keys to copy
const CopyProjectPublicKey = ({ project }: { project: Project }) => {
  const [triggerGetPublicKeys, { isLoading, error }] = useLazyGetPublicKeysQuery();

  const handleCopyPublicKey = async () => {
    try {
      const result = await triggerGetPublicKeys({
        organizationId: project.organization.id,
        projectId: project.id,
      }).unwrap();

      if (error) {
        toast.error('Error fetching public key');
      }

      if (result && result.length > 0 && result[0]?.key) {
        await navigator.clipboard.writeText(result[0].key);
        toast.success('Public key copied to clipboard');
      } else {
        toast.error('Public key not does not exist');
      }
    } catch (e) {
      toast.error('Error fetching public key: ');
    }
  };

  return (
    <DropdownMenuItem onSelect={handleCopyPublicKey} disabled={isLoading}>
      <Icon name="copy-document" /> {isLoading ? 'Loading...' : 'Copy Public Key'}
    </DropdownMenuItem>
  );
};

export default CopyProjectPublicKey;
