import {
  Toggle,
  ToggleReturnType,
  ToggleTarget,
  ToggleUsage,
  ToggleSummary,
  ToggleUsageSummary,
  ToggleDailyUsage,
} from '../types/toggle';
import { cleanRequest } from '../utils/requestUtils';
import { api } from './api';
import { ListResponse } from './types';

export interface GetProjectTogglesBody {
  organizationId?: string;
  projectId?: string;
}

export interface CreateToggleBody {
  organizationId: string;
  projectId: string;
  key: string;
  description?: string;
  tags?: string[];
  type: ToggleReturnType;
  targets?: TargetResponse[];
  defaultValue: string | number | boolean | object;
}

export type UpdateToggleBody = Omit<CreateToggleBody, 'organizationId' | 'projectId' | 'key' | 'type'>;

export type TargetsUpdate = UpdateToggleBody['targets'] extends (infer U)[] | undefined ? U : never;

type TargetResponse = Omit<ToggleTarget, 'id' | 'parsedLogic'>;

type ToggleBaseResponse = Omit<Toggle, 'targets'> & { targets: TargetResponse[] };
export type ToggleResponseWithoutReferences = Omit<ToggleBaseResponse, 'id' | 'project' | 'organization'>;
type ToggleResponseWithOnlyId = Omit<ToggleBaseResponse, 'project' | 'organization'>;

export const toggleApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createToggle: builder.mutation<ToggleResponseWithoutReferences, CreateToggleBody>({
      query: ({ key, description, tags, type, defaultValue, organizationId, projectId }) => ({
        url: `api/organizations/${organizationId}/projects/${projectId}/toggles/`,
        method: 'POST',
        body: {
          key,
          description,
          tags: [],
          type,
          targets: [],
          defaultValue,
        },
      }),
      invalidatesTags: [{ type: 'Toggles', id: 'TOGGLE-LIST' }],
    }),
    getToggle: builder.query<
      ToggleResponseWithOnlyId,
      { organizationId: string; projectId: string; toggleKey: string }
    >({
      query: ({ organizationId, projectId, toggleKey }) => ({
        url: `api/organizations/${organizationId}/projects/${projectId}/toggles/${toggleKey}`,
        method: 'GET',
      }),
      providesTags: (result, _error, { projectId }) =>
        result ? [{ type: 'Toggles', id: `${projectId}-${result.key}` }] : [],
    }),
    getToggles: builder.query<ListResponse<ToggleResponseWithoutReferences>, GetProjectTogglesBody>({
      query: ({ organizationId, projectId }) => ({
        url: `api/organizations/${organizationId}/projects/${projectId}/toggles/`,
        method: 'GET',
      }),
      providesTags: (result = { data: [], pageNum: 1, pageSize: 200, total: 200 }, _error, { projectId }) => {
        return [
          ...result.data.map(({ key }) => ({ type: 'Toggles' as const, id: `${projectId}-${key}` })),
          { type: 'Toggles', id: 'TOGGLE-LIST' },
        ];
      },
    }),
    getOrganizationToggles: builder.query<ListResponse<ToggleResponseWithoutReferences>, { organizationId: string }>(
      {
        query: ({ organizationId }) => ({
          url: `api/organizations/${organizationId}/toggles/`,
          method: 'GET',
        }),
        providesTags: (result = { data: [], pageNum: 1, pageSize: 200, total: 200 }, _error, { organizationId }) => {
          return [
            ...result.data.map(({ key }) => ({ type: 'Toggles' as const, id: `${organizationId}-${key}` })),
            { type: 'Toggles', id: 'TOGGLE-LIST' },
          ];
        },
      },
    ),
    updateToggleTargets: builder.mutation<
      ToggleResponseWithoutReferences,
      { organizationId: string; projectId: string; toggleKey: string; body: Pick<CreateToggleBody, 'targets'> }
    >({
      query: ({ organizationId, projectId, toggleKey, body }) => ({
        url: `api/organizations/${organizationId}/projects/${projectId}/toggles/${toggleKey}`,
        method: 'PATCH',
        body: cleanRequest(body),
      }),
      invalidatesTags: (_result, _error, { toggleKey, projectId }) => [
        { type: 'Toggles', id: `${projectId}-${toggleKey}` },
        { type: 'Toggles', id: 'TOGGLE-LIST' },
      ],
    }),
    updateToggle: builder.mutation<
      ToggleResponseWithoutReferences,
      { organizationId: string; projectId: string; toggleKey: string; body: UpdateToggleBody }
    >({
      query: ({ organizationId, projectId, toggleKey, body }) => ({
        url: `api/organizations/${organizationId}/projects/${projectId}/toggles/${toggleKey}`,
        method: 'PATCH',
        body: {
          description: body.description,
          defaultValue: body.defaultValue,
        },
      }),
      invalidatesTags: (_result, _error, { toggleKey, projectId }) => [
        { type: 'Toggles', id: `${projectId}-${toggleKey}` },
        { type: 'Toggles', id: 'TOGGLE-LIST' },
      ],
    }),
    getToggleUsage: builder.query<
      ListResponse<ToggleUsage>,
      { organizationId: string; projectId: string; toggleKey: string; pageSize?: number; pageNum?: number }
    >({
      query: ({ organizationId, projectId, toggleKey, pageSize = 50, pageNum = 1 }) => ({
        url: `/api/organizations/${organizationId}/projects/${projectId}/toggles/${toggleKey}/Telemetry/Usage?pageNum=${pageNum}&pageSize=${pageSize}`,
        method: 'GET',
      }),
      providesTags: (result, _error, { projectId, toggleKey }) =>
        result ? [{ type: 'Toggles', id: `${projectId}-${toggleKey}-USAGE` }] : [],
    }),
    getToggleDailyUsage: builder.query<
      ToggleDailyUsage,
      { organizationId: string; projectId: string; toggleKey: string }
    >({
      query: ({ organizationId, projectId, toggleKey }) => ({
        url: `/api/organizations/${organizationId}/projects/${projectId}/toggles/${toggleKey}/Telemetry/DailyUsage`,
        method: 'GET',
      }),
      providesTags: (result, _error, { projectId, toggleKey }) =>
        result ? [{ type: 'Toggles', id: `${projectId}-${toggleKey}-DAILY-USAGE` }] : [],
    }),
    deleteToggle: builder.mutation<void, { organizationId: string; projectId: string; toggleKey: string }>({
      query: ({ organizationId, projectId, toggleKey }) => ({
        url: `api/organizations/${organizationId}/projects/${projectId}/toggles/${toggleKey}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, { toggleKey, projectId }) => [
        { type: 'Toggles', id: `${projectId}-${toggleKey}` },
        { type: 'Toggles', id: 'TOGGLE-LIST' },
      ],
    }),
    getOrganizationalToggleSummary: builder.query<ToggleSummary[], { organizationId: string; projects?: string[] }>({
      query: ({ organizationId, projects }) => {
        const params = new URLSearchParams();
        if (projects) params.append('projects', projects.join(','));

        return {
          url: `api/organizations/${organizationId}/toggles/telemetry/summary?${params.toString()}`,
          method: 'GET',
        };
      },
      transformResponse(baseQueryReturnValue: { data: any[] }, _meta, _arg) {
        const { data } = baseQueryReturnValue;
        const result: ToggleSummary[] = [];
        data.forEach((summary) => {
          result.push({
            project: summary.project,
            toggles: summary.toggles.reduce(
              (
                acc: Record<string, ToggleUsageSummary>,
                entry: { toggle: string; count: number; environment: string },
              ) => {
                if (!acc[entry.toggle]) acc[entry.toggle] = { count: 0, environments: [] };

                acc[entry.toggle].count += entry.count;
                acc[entry.toggle].environments.push({
                  name: entry.environment,
                  count: entry.count,
                });

                return acc;
              },
              {} as Record<string, ToggleUsageSummary>,
            ),
          });
        });
        return result;
      },
      providesTags: (result, _error, { organizationId }) => [
        { type: 'Toggles', id: `TOGGLE-${organizationId}-SUMMARY-LIST` },
      ],
    }),
  }),
});

export const {
  useCreateToggleMutation,
  useGetToggleQuery,
  useGetTogglesQuery,
  useGetOrganizationTogglesQuery,
  useUpdateToggleTargetsMutation,
  useUpdateToggleMutation,
  useGetToggleUsageQuery,
  useDeleteToggleMutation,
  useGetOrganizationalToggleSummaryQuery,
  useGetToggleDailyUsageQuery,
} = toggleApi;
