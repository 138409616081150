import { Box } from '@hyphen/hyphen-components';
import { useParams } from 'react-router-dom';

import { Helmet } from 'react-helmet';

import { GoogleCloudSetupGuide } from '../../../components/integrations/setup/googleCloud/GoogleCloudSetupGuide';
import { useOrganization } from '../../../providers/OrganizationProvider';
import { Organization } from '../../../services/organization';
import {
  INTEGRATION_DESCRIPTION_MAP,
  INTEGRATION_NAME_MAP,
  INTEGRATION_SLUG_MAP,
  INTEGRATION_ICON_MAP,
} from '../../../constants/integrations';
import { IntegrationType } from '../../../types/integrations';
import PageHeader from '../../../components/PageHeader';
import { ScrollableMainLayout } from '../../../components/ScrollableMainLayout';
import { GoogleWorkspaceSetupGuide } from '../../../components/integrations/setup/googleWorkspace/GoogleWorkspaceSetupGuide';
import { AzureSetupGuide } from '../../../components/integrations/setup/azure/AzureSetupGuide';
import { AwsSetupGuide } from '../../../components/integrations/setup/aws/AwsSetupGuide';
import { SlackSetupGuide } from '../../../components/integrations/setup/slack/SlackSetupGuide';
import { GithubSetupGuide } from '../../../components/integrations/setup/github/GithubSetupGuide';
import { Office365SetupGuide } from '../../../components/integrations/setup/office365/Office365SetupGuide';
import { IncidentIoSetupGuide } from '../../../components/integrations/setup/incidentIo/IncidentIoSetupGuide';
import { NpmSetupGuide } from '../../../components/integrations/setup/npm/NpmSetupGuide';
import { NewRelicSetupGuide } from '../../../components/integrations/setup/newRelic/NewRelicSetupGuide';
import { JiraSetupGuide } from '../../../components/integrations/setup/jira/JiraSetupGuide';
import { BreadCrumbPortal } from '../../../components/common/Breadcrumb';
import { MicrosoftTeamsSetupGuide } from '../../../components/integrations/setup/microsoftTeams/MicrosoftTeamsSetupGuide';

export const IntegrationSetup = () => {
  const { organization = {} as Organization } = useOrganization();
  const { integrationSlug } = useParams();

  const integrationType = Object.entries(INTEGRATION_SLUG_MAP).find(
    ([, slug]) => slug === integrationSlug,
  )?.[0] as IntegrationType;

  return (
    <ScrollableMainLayout>
      <Helmet>
        <title>{`${INTEGRATION_NAME_MAP[integrationType]} Setup`}</title>
      </Helmet>
      <PageHeader
        title={`${INTEGRATION_NAME_MAP[integrationType]} Setup`}
        description={INTEGRATION_DESCRIPTION_MAP[integrationType]}
        iconUrl={INTEGRATION_ICON_MAP[integrationType]}
      />
      <BreadCrumbPortal entity={{ type: integrationType }} />

      <Box>
        {integrationType === IntegrationType.googleCloud && (
          <GoogleCloudSetupGuide organizationId={organization.id} />
        )}
        {integrationType === IntegrationType.googleWorkspace && (
          <GoogleWorkspaceSetupGuide organizationId={organization.id} />
        )}
        {integrationType === IntegrationType.azure && <AzureSetupGuide organizationId={organization.id} />}
        {integrationType === IntegrationType.aws && <AwsSetupGuide organizationId={organization.id} />}
        {integrationType === IntegrationType.slack && <SlackSetupGuide organizationId={organization.id} />}
        {integrationType === IntegrationType.github && <GithubSetupGuide organizationId={organization.id} />}
        {integrationType === IntegrationType.office365 && <Office365SetupGuide organizationId={organization.id} />}
        {integrationType === IntegrationType.incidentIo && <IncidentIoSetupGuide organizationId={organization.id} />}
        {integrationType === IntegrationType.npm && <NpmSetupGuide organizationId={organization.id} />}
        {integrationType === IntegrationType.newRelic && <NewRelicSetupGuide organizationId={organization.id} />}
        {integrationType === IntegrationType.microsoftTeams && <MicrosoftTeamsSetupGuide organizationId={organization.id} />}
        {integrationType === IntegrationType.jira && <JiraSetupGuide organizationId={organization.id} />}
      </Box>
    </ScrollableMainLayout>
  );
};
