import { FormikRadioGroup, FormikTextareaInput, FormikTextInput } from '@hyphen/hyphen-components';
import { Field } from 'formik';
import { ToggleType } from '../../types/toggle';
import { useCallback, useMemo } from 'react';

interface ReturnValueFieldProps {
  toggleType: ToggleType;
  defaultValue: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  errors: any;
  isSubmitting: boolean;
  label?: string;
}

const ReturnValueField = ({
  toggleType,
  defaultValue = '',
  setFieldValue,
  errors,
  isSubmitting,
  label = 'Return Value',
}: ReturnValueFieldProps) => {
  const commonProps = useMemo(
    () => ({
      id: 'defaultValue',
      name: 'defaultValue',
      value: toggleType === ToggleType.Boolean ? String(defaultValue) : defaultValue,
      disabled: isSubmitting,
      error: errors.defaultValue,
      isDisabled: isSubmitting,
    }),
    [toggleType, defaultValue, isSubmitting, errors.defaultValue],
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      switch (toggleType) {
        case ToggleType.Boolean:
          setFieldValue('defaultValue', value === 'true' ? 'true' : 'false');
          break;
        case ToggleType.Number:
          const numberValue = Number(value);
          setFieldValue('defaultValue', !isNaN(numberValue) ? numberValue : '');
          break;
        default:
          setFieldValue('defaultValue', value);
      }
    },
    [toggleType, setFieldValue],
  );

  const fieldProps = useMemo(
    () => ({
      ...commonProps,
      onChange: handleChange,
    }),
    [commonProps, handleChange],
  );

  switch (toggleType) {
    case ToggleType.Boolean:
      return (
        <Field
          {...fieldProps}
          component={FormikRadioGroup}
          title={label}
          description="Boolean value returned when no matching target context(s) are found"
          options={[
            { id: 'true', label: 'True', value: 'true' },
            { id: 'false', label: 'False', value: 'false' },
          ]}
        />
      );
    case ToggleType.Object:
      return (
        <Field
          {...fieldProps}
          component={FormikTextareaInput}
          label={label}
          helpText="Object returned when no matching target context(s) are found"
          placeholder="Enter a JSON object"
          rows={20}
        />
      );
    case ToggleType.Number:
      return (
        <Field
          {...fieldProps}
          component={FormikTextInput}
          label={label}
          helpText="Number returned when no matching target context(s) are found"
          placeholder="Enter number"
        />
      );
    case ToggleType.String:
    default:
      return (
        <Field
          {...fieldProps}
          component={FormikTextInput}
          label={label}
          helpText="String returned when no matching target context(s) are found"
        />
      );
  }
};

export default ReturnValueField;
