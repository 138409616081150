import { Box, Button, FormikSelectInputNative, FormikTextInput, useBreakpoint } from '@hyphen/hyphen-components';
import { Field, useFormikContext } from 'formik';
import React, { useCallback } from 'react';

interface ParsedLogicItem {
  key: string;
  operator: string;
  value: string;
  contextFieldType: '' | 'custom' | 'userCustom';
}

function RuleFields({ index, onRemove }: { index: number; onRemove: () => void }) {
  const { isPhone } = useBreakpoint();

  const { values, errors, setFieldValue } = useFormikContext<{
    parsedLogic: ParsedLogicItem[];
  }>();

  const firstRowMargin = index === 0 ? '2xl 0 0 0' : '0';
  const contextFieldType = values.parsedLogic[index].contextFieldType;
  const prefix = contextFieldType === 'userCustom' ? 'user.customAttribute.' : 'customAttribute.';

  const handleCustomKeyChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;
      const updatedValue = inputValue.startsWith(prefix) ? inputValue : `${prefix}${inputValue}`;
      setFieldValue(`parsedLogic.${index}.key`, updatedValue);
    },
    [index, prefix, setFieldValue],
  );

  const renderKeyField = useCallback(() => {
    return contextFieldType === 'custom' || contextFieldType === 'userCustom' ? (
      <Field
        name={`parsedLogic.${index}.key`}
        label="Custom Attribute"
        hideLabel
        placeholder={`${prefix}myKey`}
        component={FormikTextInput}
        required
        error={(errors.parsedLogic?.[index] as { key?: string })?.key || ''}
        onChange={handleCustomKeyChange}
      />
    ) : (
      <Box
        borderWidth="sm"
        radius="md"
        fontFamily="monospace"
        fontSize="sm"
        justifyContent="center"
        padding="sm"
        background="primary"
        flex="auto"
        maxHeight="42px"
      >
        {values.parsedLogic[index].key}
      </Box>
    );
  }, [contextFieldType, errors.parsedLogic, handleCustomKeyChange, index, prefix, values.parsedLogic]);

  return (
    <Box width="100" flex="auto" as="li" direction={{ base: 'column', tablet: 'row' }} alignItems="stretch" gap="lg">
      <Box gap="xs" justifyContent="stretch" width="100" maxWidth="8xl">
        {index === 0 && <Box fontSize="sm">Context Field</Box>}
        {renderKeyField()}
      </Box>
      <Box width="6xl">
        <Field
          name={`parsedLogic.${index}.operator`}
          label="Operator"
          hideLabel={index > 0}
          component={FormikSelectInputNative}
          options={[
            { value: '==', label: '==' },
            { value: '!=', label: '!=' },
            { value: 'in', label: 'in' },
            { value: 'contains', label: 'contains' },
            { value: '>=', label: '>=' },
            { value: '<=', label: '<=' },
          ]}
          error={(errors.parsedLogic?.[index] as { operator?: string })?.operator || ''}
        />
      </Box>
      <Box flex="auto">
        <Field
          name={`parsedLogic.${index}.value`}
          label="Value"
          hideLabel={index > 0}
          placeholder={values.parsedLogic[index].operator === 'in' ? 'value1, value2' : ''}
          component={FormikTextInput}
          required
          error={(errors.parsedLogic?.[index] as { value?: string })?.value || ''}
        />
      </Box>
      <Box margin={firstRowMargin}>
        <Button
          type="button"
          variant="tertiary"
          onClick={onRemove}
          aria-label="remove"
          size="md"
          iconPrefix="c-remove"
        >
          {isPhone && 'Remove'}
        </Button>
      </Box>
    </Box>
  );
}

export default React.memo(RuleFields);
