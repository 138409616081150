import { useCallback, useMemo } from 'react';
import { BarStack } from '@visx/shape';
import { Group } from '@visx/group';
import { Grid } from '@visx/grid';
import { AxisBottom } from '@visx/axis';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { timeParse, timeFormat } from '@visx/vendor/d3-time-format';
import { Toggle, ToggleDailyUsage } from '../../types/toggle';
import { useTheme } from '@hyphen/hyphen-components';
import tokens from '@hyphen/hyphen-design-tokens/build/json/variables.json';
import { ProjectEnvironments, useProjectEnvironments } from '../../providers/ProjectEnvironmentsProvider';
import { useToggle } from '../../providers/ToggleProvider';

export type BarData = {
  data: Record<string, number>;
};

export type DailyUsageBarChartProps = {
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  events?: boolean;
  data: ToggleDailyUsage['dailyUsage'];
};

const defaultMargin = { top: 0, right: 50, bottom: 0, left: 12 };

const parseDate = timeParse('%Y-%m-%d');
const format = timeFormat('%b %d');
const formatDate = (date: string) => format(parseDate(date) as Date);

// accessors
const getDate = (d: any) => d.date;

const getEnvironmentColors = (
  environments: string[],
  projectEnvironments: ProjectEnvironments,
  toggle: Toggle,
): string[] => {
  const projectId = toggle?.project.id;
  return environments.reduce<string[]>((colors, key) => {
    const color = projectId && projectEnvironments?.[projectId]?.[key]?.color;
    if (color) {
      colors.push(color);
    }
    return colors;
  }, []);
};

const DailyUsageBarChart = ({
  width,
  height,
  events = false,
  margin = defaultMargin,
  data,
  setBigDisplayValue,
  resetBigDisplayValue,
}: DailyUsageBarChartProps & {
  setBigDisplayValue: (value: { total: number; dateRange: string; barData: BarData }) => void;
  resetBigDisplayValue: () => void;
}) => {
  const { projectEnvironments } = useProjectEnvironments();

  const { toggle } = useToggle();
  const environments = useMemo(() => {
    // no environments exist in project
    if (!data || !Array.isArray(data) || data.length === 0 || !data[0].counts) {
      return undefined;
    }
    return Object.keys(data[0].counts.environments);
  }, [data]);

  const environmentColors = useMemo(
    () => toggle && projectEnvironments && getEnvironmentColors(environments || [], projectEnvironments, toggle),
    [environments, projectEnvironments, toggle],
  );

  const chartData = useMemo(
    () =>
      data.map((entry) => {
        const transformedEntry: { date: string; [key: string]: any } = { date: entry.date };
        Object.entries(entry.counts.environments).forEach(([environment, value]) => {
          transformedEntry[environment] = value;
        });
        return transformedEntry;
      }),
    [data],
  );

  const { isDarkMode } = useTheme();
  const bgColor = isDarkMode ? tokens.color.background.primary.darkValue : tokens.color.background.primary.value;

  const dailyUsageTotal = useMemo(() => data.map((entry) => entry.counts.total), [data]);

  // bounds
  const xMax = width - margin.right;
  const yMax = height - margin.top - 60;

  // scales
  const valueScale = useMemo(
    () =>
      scaleLinear<number>({
        domain: [0, Math.max(...dailyUsageTotal)],
        nice: true,
      }),
    [dailyUsageTotal],
  );

  const dateScale = useMemo(
    () =>
      scaleBand<string>({
        domain: chartData.map(getDate),
        padding: 0.2,
      }),
    [chartData],
  );

  const colorScale = useMemo(
    () =>
      scaleOrdinal<string, string>({
        domain: environments,
        range: environmentColors,
      }),
    [environments, environmentColors],
  );

  dateScale.rangeRound([0, xMax]);
  valueScale.range([yMax, 0]);

  const handleMouseEnter = useCallback(
    (bar: any) => {
      const { index, bar: barData } = bar;
      const dateRange = formatDate(getDate(chartData[index]));
      const total = dailyUsageTotal[index];

      setBigDisplayValue({
        total,
        dateRange,
        barData,
      });
    },
    [chartData, dailyUsageTotal, setBigDisplayValue],
  );

  const handleMouseLeave = useCallback(() => resetBigDisplayValue(), [resetBigDisplayValue]);

  if (width < 10) return null;

  return (
    <div style={{ position: 'relative' }}>
      <svg width={width} height={height}>
        <rect x={0} y={0} width={width} height={height} fill={bgColor} />
        <Grid
          top={margin.top}
          left={margin.left}
          xScale={dateScale}
          yScale={valueScale}
          xOffset={dateScale.bandwidth() / 2}
          width={xMax}
          height={yMax}
          stroke="var(--color-border-default)"
          strokeOpacity={0.8}
          strokeDasharray="2 2"
          numTicksRows={5}
          numTicksColumns={0}
        />
        <Group top={margin.top}>
          <BarStack
            data={chartData}
            keys={environments}
            x={getDate}
            xScale={dateScale}
            yScale={valueScale}
            color={colorScale}
          >
            {(barStacks) =>
              barStacks.map((barStack) =>
                barStack.bars.map((bar) => (
                  <rect
                    key={`bar-stack-${barStack.index}-${bar.index}`}
                    x={bar.x}
                    y={bar.y}
                    height={bar.height}
                    width={bar.width}
                    fill={bar.color}
                    onClick={() => events && alert(`clicked: ${JSON.stringify(bar)}`)}
                    onMouseLeave={handleMouseLeave}
                    onMouseEnter={() => handleMouseEnter(bar)}
                  />
                )),
              )
            }
          </BarStack>
        </Group>
        <AxisBottom
          top={yMax + margin.top}
          left={margin.left}
          scale={dateScale}
          tickFormat={formatDate}
          stroke="var(--color-border-default)"
          tickStroke="var(--color-border-default)"
          tickLabelProps={{
            fill: 'var(--color-font-base)',
            fontSize: 'var(--size-font-size-xs)',
            textAnchor: 'middle',
          }}
        />
      </svg>
    </div>
  );
};

export default DailyUsageBarChart;
