import { Outlet } from 'react-router-dom';
import { Box, Button } from '@hyphen/hyphen-components';
import { useAuth } from '../components/auth/authProvider';
import './RegistrationLayout.css';

export default function RegistrationLayout() {
  const { user, logout } = useAuth();

  return (
    <Box width="100" height="100" position="relative" className="container" color="base">
      <div className="linearGlow" />
      <div className="centerGlow" />
      <div className="upperRightGlow" />
      <Box direction="row" justifyContent="space-between" padding="2xl" style={{ zIndex: 1 }}>
        <Box gap="xs">
          <Box fontSize="xs" color="tertiary">
            Signed in as
          </Box>
          <Box fontSize="sm" color="secondary">
            {user?.email}
          </Box>
        </Box>
        <Button variant="primary" size="sm" onClick={logout} className="registration-button">
          Sign out
        </Button>
      </Box>
      <Box flex="auto" style={{ zIndex: 1 }}>
        <Outlet />
      </Box>
    </Box>
  );
}
