import { Box } from '@hyphen/hyphen-components';
import { useProjectEnvironments } from '../../providers/ProjectEnvironmentsProvider';
import { ColorDot } from '../common/ColorDot';
import { useToggle } from '../../providers/ToggleProvider';

const EnvironmentData = ({ data }: { data: Record<string, number> }) => {
  const { projectEnvironments: orgProjectEnvironments } = useProjectEnvironments();
  const { toggle } = useToggle();

  const projectEnvironments = toggle?.project.id ? orgProjectEnvironments?.[toggle.project.id] : undefined;
  const { date, ...environments } = data;

  return (
    <Box direction="row" gap="xl" wrap>
      {Object.entries(environments).map(([key, value]) => (
        <Box as="span" key={key} display="inline-flex" alignItems="center" direction="row" gap="xs">
          {projectEnvironments?.[key]?.color && <ColorDot color={projectEnvironments?.[key]?.color} />}
          {key} {value.toLocaleString('en-US')}
        </Box>
      ))}
    </Box>
  );
};

export default EnvironmentData;
