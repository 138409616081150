import { Box, Card } from '@hyphen/hyphen-components';
import ClickToCopy from '../common/ClickToCopy';
import Targets from './Targets';
import LastEvent from './LastEvent';
import { useToggle } from '../../providers/ToggleProvider';
import { Skeleton } from '../common/Skeleton';
import { ReactNode } from 'react';
import DailyUsageCard from './DailyUsageCard';
import ToggleUsageList from './ToggleUsageList';

export const ToggleStatus = () => {
  const { toggle, isLoading } = useToggle();

  return (
    <Box gap="4xl">
      <Card className="fade-in">
        <Box direction={{ base: 'column', tablet: 'row' }} fontSize="sm">
          <InfoBox title="Key ID" isLoading={isLoading || !toggle} skeletonWidth="120px" skeletonHeight="30px">
            {toggle && <ClickToCopy fontSize="xs" text={toggle?.key} />}
          </InfoBox>
          <Box
            flex="auto"
            padding={{ base: 'lg', tablet: 'xl', desktop: '2xl' }}
            alignItems={{ base: 'center', desktop: 'flex-start' }}
            direction={{ base: 'row', desktop: 'column' }}
            gap="lg"
          >
            {toggle && <LastEvent toggleKey={toggle.key} projectId={toggle.project.id} />}
          </Box>
        </Box>
      </Card>
      <Card>
        <DailyUsageCard />
        <Card.Header>Recent Usage</Card.Header>
        <ToggleUsageList showPagination={false} pageSize={5} />
      </Card>
      <Targets />
    </Box>
  );
};

const InfoBox = ({
  title,
  children,
  isLoading,
  skeletonWidth,
  skeletonHeight,
}: {
  title: string;
  children: ReactNode;
  isLoading: boolean;
  skeletonWidth: string;
  skeletonHeight: string;
}) => (
  <Box
    padding={{ base: 'lg', tablet: 'xl', desktop: '2xl' }}
    borderColor="subtle"
    borderWidth={{ base: '0 0 sm 0', tablet: '0 sm 0 0' }}
    alignItems={{ base: 'center', tablet: 'flex-start' }}
    justifyContent={{ base: 'space-between', tablet: 'flex-start' }}
    direction={{ base: 'row', tablet: 'column' }}
    gap="lg"
    minWidth="7xl"
  >
    <Box fontWeight="semibold" color="secondary" fontSize="xs">
      {title}
    </Box>
    {isLoading ? <Skeleton width={skeletonWidth} height={skeletonHeight} /> : children}
  </Box>
);
