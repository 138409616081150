import {
  Box,
  Button,
  Drawer,
  useBreakpoint,
  DrawerTrigger,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  useDrawer,
  Card,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
  toast,
  DropdownMenuSeparator,
  Icon,
  useOpenClose,
} from '@hyphen/hyphen-components';
import TargetForm, { TargetFormSchema } from './TargetForm';
import { TargetsUpdate, useUpdateToggleTargetsMutation } from '../../services/toggle';
import { useOrganization } from '../../providers/OrganizationProvider';
import { useCallback, useMemo } from 'react';
import { toJsonLogic } from '../../utils/parseJsonLogic';
import { ConfirmModal } from '../common/ConfirmModal';
import { useToggle } from '../../providers/ToggleProvider';

interface ToggleContextDrawerProps {
  onUpdateItems: (updatedIndex: number) => void;
  targetContext?: any;
}

export default function TargetDrawer({ targetContext, onUpdateItems }: ToggleContextDrawerProps) {
  const { toggle, refetch } = useToggle();
  const { id: orgId } = useOrganization();
  const [updateToggleTargets, { isLoading }] = useUpdateToggleTargetsMutation();

  const { isOpen: isDeleteModalOpen, handleOpen: openDeleteModal, handleClose: closeDeleteModal } = useOpenClose();

  const { isPhone, isTablet } = useBreakpoint();

  const { setOpen } = useDrawer();

  const initialValues = useMemo(
    () => ({
      defaultValue: targetContext?.value || toggle?.defaultValue,
      id: targetContext?.id || '',
      parsedLogic: targetContext?.parsedLogic || [],
    }),
    [targetContext, toggle?.defaultValue],
  );

  const isNewTargetContext = useMemo(() => targetContext === undefined, [targetContext]);

  const handleDelete = useCallback(async () => {
    if (!toggle) return;
    const updatedTargets = toggle.targets.filter((target) => target.id !== targetContext?.id);

    try {
      const { error, data } = await updateToggleTargets({
        organizationId: orgId,
        projectId: toggle.project.alternateId,
        toggleKey: toggle.key,
        body: {
          targets: updatedTargets,
        },
      });

      if (!error && data) {
        toast.success('Target deleted');
        closeDeleteModal();
      } else {
        toast.error('Failed to delete target');
      }
    } catch (e) {
      toast.error('Failed to delete target');
    }
  }, [closeDeleteModal, orgId, targetContext?.id, toggle, updateToggleTargets]);

  const handleSubmit = useCallback(
    async (values: TargetFormSchema) => {
      if (!toggle) return;
      const targetToUpdateIndex = toggle.targets.findIndex((target) => target.id === values.id);

      const targetUpdate: TargetsUpdate = {
        logic: toJsonLogic(values.parsedLogic),
        value: values.defaultValue as string | number | boolean,
      };

      let targetUpdates: TargetsUpdate[] = toggle.targets.map((target) =>
        target.id === values.id ? targetUpdate : { logic: target.logic, value: target.value },
      );

      if (isNewTargetContext) {
        targetUpdates.push(targetUpdate);
      }

      const { error, data } = await updateToggleTargets({
        organizationId: orgId,
        projectId: toggle.project.alternateId,
        toggleKey: toggle.key,
        body: {
          targets: targetUpdates,
        },
      });

      if (!error && data) {
        toast.success('Target context updated');
        onUpdateItems(isNewTargetContext ? data.targets.length - 1 : targetToUpdateIndex);
        setOpen(false);
        refetch();
      }
    },
    [toggle, isNewTargetContext, updateToggleTargets, orgId, onUpdateItems, setOpen, refetch],
  );

  if (!toggle) return null;

  return (
    <>
      <Drawer ariaLabelledBy="target evaluation context" width={isPhone ? '90%' : isTablet ? '70%' : '60%'}>
        <DrawerHeader>
          <DrawerTitle gap="sm">
            <Box gap="sm" display="inline-flex" direction="row" alignItems="baseline">
              Beta Dashboard{' '}
              <Box
                margin="0 0 0 sm"
                as="span"
                fontFamily="monospace"
                fontSize="sm"
                display="inline-block"
                padding="xs"
                color="secondary"
                background="secondary"
              >
                {toggle.type}
              </Box>
            </Box>
            <Box display="inline" fontSize="sm" color="secondary" fontWeight="normal">
              Target Evaluation Context
            </Box>
          </DrawerTitle>
          <DrawerCloseButton />
        </DrawerHeader>
        <DrawerContent gap={{ base: '2xl', tablet: '3xl' }}>
          <Card style={{ flexShrink: '0' }}>
            <Card.Section background="secondary">
              <TargetForm
                initialValues={initialValues}
                returnType={toggle.type}
                isLoading={isLoading}
                onSubmit={handleSubmit}
              />
            </Card.Section>
          </Card>
        </DrawerContent>
      </Drawer>
      {!isNewTargetContext && (
        <>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="tertiary" size="sm" iconPrefix="dots" aria-label="Open menu" />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" side="bottom">
              <DrawerTrigger asChild>
                <DropdownMenuItem>Edit</DropdownMenuItem>
              </DrawerTrigger>
              <DropdownMenuSeparator />
              <DropdownMenuItem onSelect={openDeleteModal} className="font-color-danger">
                <Icon name="c-remove" />
                <span>Delete</span>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <ConfirmModal
            isOpen={isDeleteModalOpen}
            onConfirm={handleDelete}
            onClose={closeDeleteModal}
            title="Delete Target"
            message="Are you sure you want to delete this target?"
            confirmButtonLabel="Delete"
            isLoading={isLoading}
          />
        </>
      )}
    </>
  );
}
