import { ConnectionType } from '../types/connections';
import { IntegrationType } from '../types/integrations';

//TODO: use i18n for these strings
export const INTEGRATION_NAME_MAP: Record<IntegrationType, string> = {
  googleWorkspace: 'Google Workspace',
  github: 'GitHub',
  googleCloud: 'Google Cloud',
  azure: 'Microsoft Azure',
  aws: 'Amazon Web Services',
  slack: 'Slack',
  office365: 'Microsoft Office 365',
  incidentIo: 'incident.io',
  npm: 'NPM',
  newRelic: 'New Relic',
  microsoftTeams: 'Microsoft Teams',
  jira: 'Jira',
};

export const CONNECTION_NAME_MAP: Record<ConnectionType, string> = {
  [ConnectionType.DistributionList]: 'Distribution List',
  [ConnectionType.Channel]: 'Channel',
  [ConnectionType.PermissionGroup]: 'Permission Group',
  [ConnectionType.Folder]: 'Folder',
  [ConnectionType.Tag]: 'Tag',
  [ConnectionType.CloudWorkspace]: 'Cloud Workspace',
  [ConnectionType.CodeRepository]: 'Code Repository',
  [ConnectionType.ProjectBoard]: 'Project Board',
  [ConnectionType.CodeRegistry]: 'Package Registry',
  [ConnectionType.User]: 'User Account',
  [ConnectionType.IncidentManagement]: 'Incident Management',
};

export const INTEGRATION_SLUG_MAP: Record<IntegrationType, string> = {
  googleWorkspace: 'google-workspace',
  github: 'github',
  googleCloud: 'google-cloud',
  azure: 'azure',
  aws: 'aws',
  slack: 'slack',
  office365: 'office365',
  incidentIo: 'incident-io',
  npm: 'npm',
  newRelic: 'new-relic',
  microsoftTeams: 'microsoft-teams',
  jira: 'jira',
};

export const INTEGRATION_DESCRIPTION_MAP: Record<IntegrationType, string> = {
  googleWorkspace: 'Keep team membership synced for up-to-date document access, calendaring and distribution lists',
  github: 'Automated access management to github users, code repos, permission groups, and projects',
  googleCloud: `Automate your team's infrastructure and ensure resource access is automatically kept in sync with team membership`,
  azure: `Automate your team's infrastructure and ensure resource access is automatically kept in sync with team membership`,
  aws: `Automate your team's infrastructure and ensure resource access is automatically kept in sync with team membership`,
  slack:
    'Provide your employees Hyphen services to organize their teams and manage access to company resources - all without leaving Slack.',
  office365:
    'Connect your Office 365 account to keep team membership synced for up-to-date document access, calendaring and distribution lists.',
  incidentIo: 'Connect your incident.io account to keep your incident data in sync with Hyphen.',
  npm: 'Manage NPM packages and team access permissions automatically as your team changes',
  newRelic: 'Automatically manage New Relic user access and permission groups as your team evolves',
  microsoftTeams: 'Connect to Microsoft Teams to automatically manage Teams and channels',
  jira: 'Manage Jira projects, boards, and user permissions automatically as your team changes',
};

export const INTEGRATION_ICON_MAP: Record<IntegrationType, string> = {
  googleWorkspace: '/images/google.svg',
  github: '/images/github.svg',
  googleCloud: '/images/google-cloud.svg',
  azure: '/images/azure.svg',
  aws: '/images/aws.svg',
  slack: '/images/slack.svg',
  office365: '/images/office365.svg',
  incidentIo: '/images/incidentIo.svg',
  npm: '/images/npm.svg',
  newRelic: '/images/newrelic.svg',
  microsoftTeams: '/images/microsoftTeams.svg',
  jira: '/images/jira.svg',
};

export const INTEGRATION_FEATURED_BACKGROUND_MAP: Record<IntegrationType, string> = {
  googleWorkspace: '/images/google-workspace-background.png',
  googleCloud: '/images/google-cloud-background.png',
  azure: '/images/azure-background.png',
  slack: '/images/default-background.png',
  github: '/images/default-background.png',
  aws: '/images/default-background.png',
  office365: '/images/default-background.png',
  incidentIo: '/images/default-background.png',
  npm: '/images/default-background.png',
  newRelic: '/images/default-background.png',
  microsoftTeams: '/images/default-background.png',
  jira: '/images/default-background.png',
};

export const INTEGRATION_REMOVAL_DESCRIPTION_MAP: Record<IntegrationType, string> = {
  googleWorkspace: 'Once removed, it will be safe to remove app wide delegation from your workspace instance.',
  github: 'Once removed, please uninstall the Hyphen application from your GitHub organization.',
  googleCloud: 'Once removed, it will be safe to remove the Hyphen service account from your organization.',
  azure: 'Once removed, it will be safe to remove the Hyphen service principal from your tenant.',
  aws: 'Once removed, it will be safe to remove the Hyphen IAM role from your account.',
  office365: 'Once removed, it will be safe to remove app wide delegation from your workspace instance.',
  slack: 'Once removed, it will be safe to remove the Hyphen app from your workspace.',
  incidentIo: 'Once removed, it will be safe to remove the Hyphen app from your workspace.',
  npm: 'Once removed, it will be safe to revoke the NPM access token from your organization settings.',
  newRelic: 'Once removed, it will be safe to revoke the New Relic API key from your account settings.',
  microsoftTeams: 'Once removed, it will be safe to revoke Hyphen access to Teams scopes.',
  jira: 'Once removed, it will be safe to revoke the API token from your Jira account settings.',
};
