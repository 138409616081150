import { Box, Button } from '@hyphen/hyphen-components';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripePaymentElementOptions } from '@stripe/stripe-js/dist/stripe-js/elements/payment';
import { FormEvent, useCallback, useState } from 'react';

interface CreatePaymentMethodFormProps {
  returnUrl: string;
}

export const CreatePaymentMethodForm = ({ returnUrl }: CreatePaymentMethodFormProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      if (!stripe || !elements) {
        return;
      }

      setIsSubmitting(true);

      await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: returnUrl,
        },
      });

      setIsSubmitting(false);
    },
    [stripe, elements, returnUrl],
  );

  const elementsOptions: StripePaymentElementOptions = {
    fields: {
      billingDetails: {
        address: 'if_required',
      },
    },
  };
  return (
    <Box width="100" height="100" gap="4xl" alignItems="center" justifyContent="center">
      <Box width="100" maxWidth="9xl" padding={{ base: '2xl', desktop: '5xl' }} gap="4xl" className="fade-in">
        <Box as="h1" fontSize={{ base: 'lg', tablet: 'xl' }} textAlign="center" fontWeight="bold">
          Add a payment method
        </Box>
        <form onSubmit={handleSubmit}>
          <PaymentElement options={elementsOptions} />
          <Box margin="lg 0">
            <Button
              fullWidth
              size="md"
              variant="primary"
              type="submit"
              isLoading={isSubmitting}
              className="registration-button"
            >
              Complete Setup
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};
