import {
  Box,
  Button,
  Card,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Icon,
} from '@hyphen/hyphen-components';
import { Link } from 'react-router-dom';
import { Project } from '../../services/projects';
import ClickToCopy from '../common/ClickToCopy';
import CopyProjectPublicKey from './CopyProjectPublicKey';

export type ProjectListItemProps = {
  project: Project;
};

const ProjectListItem = ({ project }: ProjectListItemProps) => {
  const {
    name,
    alternateId,
    organization: { id: orgId },
  } = project;

  return (
    <Card className="fade-in">
      <Box
        direction="row"
        gap="lg"
        padding={{ base: 'xl', desktop: '3xl' }}
        alignItems="center"
        justifyContent="flex-start"
      >
        <Box gap="sm" flex="auto" alignItems="flex-start">
          <Box fontSize="md" fontWeight="bold" display="block">
            <Link
              to={`/${orgId}/${alternateId}`}
              className="display-flex flex-direction-row g-sm align-items-center"
            >
              <Box width="2xl" height="2xl" alignSelf="flex-start">
                <Icon name="blocks" size="xl" color="tertiary" />
              </Box>
              {name}
            </Link>
          </Box>
          <ClickToCopy text={alternateId} fontSize="xs" />
        </Box>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="tertiary" size="sm" iconPrefix="dots" aria-label="Open menu" />
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" side="bottom">
            <DropdownMenuGroup>
              <CopyProjectPublicKey project={project} />
            </DropdownMenuGroup>
            <DropdownMenuSeparator />
            <DropdownMenuGroup>
              <DropdownMenuLabel>Project</DropdownMenuLabel>
              <DropdownMenuItem asChild>
                <Link to={`/${orgId}/${alternateId}/toggles`}>Toggles</Link>
              </DropdownMenuItem>
              <DropdownMenuItem asChild>
                <Link to={`/${orgId}/${alternateId}/access`}>Access</Link>
              </DropdownMenuItem>
              <DropdownMenuItem asChild>
                <Link to={`/${orgId}/${alternateId}/connections`}>Connections</Link>
              </DropdownMenuItem>
              <DropdownMenuItem asChild>
                <Link to={`/${orgId}/${alternateId}/settings`}>Settings</Link>
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      </Box>
    </Card>
  );
};

export default ProjectListItem;
