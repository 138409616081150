import { Helmet } from 'react-helmet';
import { Organization } from '../../services/organization';
import PageHeader from '../../components/PageHeader';
import { ScrollableMainLayout } from '../../components/ScrollableMainLayout';
import { useOrganization } from '../../providers/OrganizationProvider';
import { TeamsList } from '../../components/teams/TeamsList';
import { Spinner } from '@hyphen/hyphen-components';
import { BreadCrumbPortal } from '../../components/common/Breadcrumb';

export const Teams = () => {
  const { organization = {} as Organization, isLoading } = useOrganization();

  return (
    <ScrollableMainLayout fullWidth>
      <Helmet>
        <title>Teams</title>
      </Helmet>
      <BreadCrumbPortal />
      <PageHeader title="Teams" description="Intelligently provide members access to your resources" />
      {isLoading ? <Spinner /> : <TeamsList organizationId={organization.id} />}
    </ScrollableMainLayout>
  );
};

export default Teams;
