import { useCallback } from 'react';
import { toast } from '@hyphen/hyphen-components';
import { useAddFavoriteMutation, useDeleteFavoriteMutation } from '../services/member';
import { Favorite, Member } from '../types/members';

interface FavoriteHandlerProps {
  favoriteId?: string;
  member?: Member;
  orgId: string;
}

export const useFavoriteHandler = ({ favoriteId, member, orgId }: FavoriteHandlerProps) => {
  const isMemberFavorite: Favorite | undefined = favoriteId
    ? member?.favorites?.find((fav) => fav.id === favoriteId)
    : undefined;

  const [addFavorite, { isLoading: isAddingFavorite }] = useAddFavoriteMutation();
  const [deleteFavorite, { isLoading: isDeletingFavorite }] = useDeleteFavoriteMutation();

  const isFavoriteLoading = isAddingFavorite || isDeletingFavorite;

  const handleFavorite = useCallback(async () => {
    if (!favoriteId || !member) return;

    if (isMemberFavorite) {
      try {
        const { error } = await deleteFavorite({
          memberId: member.id,
          organizationId: orgId,
          favoriteId: isMemberFavorite?.id,
        });

        if (!error) {
          toast.success('Favorite removed');
        } else {
          toast.error('Remove favorite failed');
        }
      } catch (e) {
        toast.error('Remove favorite failed');
      }
    } else {
      try {
        const { error } = await addFavorite({
          memberId: member?.id,
          organizationId: orgId,
          data: {
            id: favoriteId,
          },
        });

        if (!error) {
          toast.success('Favorite added');
        } else {
          toast.error('Add favorite failed');
        }
      } catch (e) {
        toast.error('Add favorite failed');
      }
    }
  }, [addFavorite, deleteFavorite, favoriteId, isMemberFavorite, member, orgId]);

  return { isMemberFavorite, isFavoriteLoading, handleFavorite };
};
