import { Box, Heading } from '@hyphen/hyphen-components';
import { App } from '../../services/apps';
import { useGetProjectEnvironmentsQuery } from '../../services/environments';
import EnvironmentItem from '../common/EnvironmentItem';
import { Skeleton } from '../common/Skeleton';
import { useMemo } from 'react';

const gridStyle = {
  gridTemplateColumns: 'repeat(auto-fill, minmax(290px, 1fr))',
};

const Environments = ({
  app,
  organizationId,
  isLoading,
}: {
  app?: App;
  organizationId: string;
  isLoading: boolean;
}) => {
  const { data, isLoading: isProjectEnvironmentsLoading } = useGetProjectEnvironmentsQuery({
    organizationId,
    projectId: app?.project.id,
  });

  const projectEnvironments = useMemo(() => data?.data ?? [], [data]);
  const loading = useMemo(
    () => isLoading || isProjectEnvironmentsLoading,
    [isLoading, isProjectEnvironmentsLoading],
  );

  const renderSkeletons = () => (
    <>
      <Skeleton width="100" height="68px" />
      <Skeleton width="100" height="68px" />
      <Skeleton width="100" height="68px" />
    </>
  );

  return (
    <Box alignItems="flex-start" width="100" gap="xl">
      {loading ? (
        <Skeleton width="106px" height="20px" />
      ) : (
        <Heading as="h2" size="sm">
          Environments
        </Heading>
      )}
      <Box width="100" direction="row" gap="lg" overflow="auto" display="grid" fontSize="xs" style={gridStyle}>
        {loading ? (
          renderSkeletons()
        ) : projectEnvironments.length > 0 ? (
          <>
            <EnvironmentItem name="Default (all)" hideSecrets app={app} />
            {projectEnvironments.map((env) => (
              <EnvironmentItem key={env.id} {...env} app={app} hideSecrets />
            ))}
          </>
        ) : (
          <Box padding="3xl" textAlign="center">
            No environments found
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Environments;
