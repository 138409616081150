import { ConnectionType } from './connections';
import { EntityType } from './executionContext';

export enum IntegrationType {
  googleWorkspace = 'googleWorkspace',
  github = 'github',
  googleCloud = 'googleCloud',
  azure = 'azure',
  aws = 'aws',
  slack = 'slack',
  office365 = 'office365',
  incidentIo = 'incidentIo',
  npm = 'npm',
  newRelic = 'newRelic',
  microsoftTeams = 'microsoftTeams',
  jira = 'jira',
}

// AWS Integration Types
export interface AwsIntegrationConfig {
  region: string;
  managementAccountId: string;
  name: string;
}

export interface AwsIntegrationOptions extends Record<string, any> { }

// Azure Integration Types
export interface AzureIntegrationConfig {
  azureTenantId: string;
  name: string;
}

export interface AzureIntegrationOptions extends Record<string, any> { }

// Google Workspace Integration Types
export interface GoogleWorkspaceIntegrationConfig {
  domain: string;
  adminUserEmail: string;
}

export interface GoogleWorkspaceIntegrationOptions extends Record<string, any> { }

// GitHub Integration Types
export interface githubIntegrationConfig {
  installationId: string;
  githubOrgId: string;
  githubOrgName: string;
  verificationEmail: string;
}

export interface GithubIntegrationOptions extends Record<string, any> { }

// Google Cloud Integration Types
export interface GoogleCloudIntegrationConfig {
  googleCloudOrganizationId: string;
  googleCloudOrganizationName: string;
}

export interface GoogleCloudIntegrationOptions extends Record<string, any> { }

// Slack Integration Types
export interface SlackIntegrationConfig {
  workspaceId: string;
  workspaceName: string;
}

export interface SlackIntegrationOptions extends Record<string, any> { }

// Office 365 Integration Types
export interface Office365IntegrationConfig {
  azureTenantId: string;
  name: string;
}

export interface Office365IntegrationOptions extends Record<string, any> { }

// NPM Integration Types
export interface NpmIntegrationConfig {
  organizationName: string;
  organizationId: string;
}

export interface NpmSetupFormConfig {
  organizationName: string;
  token: string;
}

export interface NpmIntegrationOptions extends Record<string, any> { }

// New Relic Integration Types
export interface NewRelicIntegrationConfig {
  accountId: number;
  accountName: string;
}

export interface NewRelicSetupFormConfig {
  token: string;
  accountId?: number;
  accountName?: string;
}

// Microsoft Teams Integration Types
export interface MicrosoftTeamsIntegrationConfig {
  azureTenantId: string;
  name: string;
  defaultTeamId: string;
}

export interface MicrosoftTeamsIntegrationOptions extends Record<string, any> { }

export interface NewRelicIntegrationOptions extends Record<string, any> { }

// Base Integration Interface
export interface IncidentIoIntegrationConfig {
  organizationName: string;
  dashboardUrl: string;
  token: string;
  catalogServiceTypeId: string;
  catalogTeamType: {
    id: string;
    memberAttributeId: string;
  };
  catalogUserTypeId: string;
}

export interface IncidentIoIntegrationOptions extends Record<string, any> { }

export interface BaseIntegration {
  id: string;
  type: IntegrationType;
  config: unknown;
  options: unknown;
  provides: [
    {
      connectionType: ConnectionType;
      for: EntityType[];
    },
  ];
}

// Integration Interfaces
export interface AzureIntegration extends BaseIntegration {
  type: IntegrationType.azure;
  config: AzureIntegrationConfig;
  options: AzureIntegrationOptions;
}

export interface AwsIntegration extends BaseIntegration {
  type: IntegrationType.aws;
  config: AwsIntegrationConfig;
  options: AwsIntegrationOptions;
}

export interface GoogleWorkspaceIntegration extends BaseIntegration {
  type: IntegrationType.googleWorkspace;
  config: GoogleWorkspaceIntegrationConfig;
  options: GoogleWorkspaceIntegrationOptions;
}

export interface GithubIntegration extends BaseIntegration {
  type: IntegrationType.github;
  config: githubIntegrationConfig;
  options: GithubIntegrationOptions;
}

export interface GoogleCloudIntegration extends BaseIntegration {
  type: IntegrationType.googleCloud;
  config: GoogleCloudIntegrationConfig;
  options: GoogleCloudIntegrationOptions;
}

export interface SlackIntegrationInvite {
  type: IntegrationType.slack;
  url: string;
}

export interface SlackIntegration extends BaseIntegration {
  type: IntegrationType.slack;
  config: SlackIntegrationConfig;
  options: SlackIntegrationOptions;
}

export interface Office365Integration extends BaseIntegration {
  type: IntegrationType.office365;
  config: Office365IntegrationConfig;
  options: Office365IntegrationOptions;
}

export interface NpmIntegration extends BaseIntegration {
  type: IntegrationType.npm;
  config: NpmIntegrationConfig;
  options: NpmIntegrationOptions;
}

export interface NewRelicIntegration extends BaseIntegration {
  type: IntegrationType.newRelic;
  config: NewRelicIntegrationConfig;
  options: NewRelicIntegrationOptions;
}

export interface MicrosoftTeamsIntegration extends BaseIntegration {
  type: IntegrationType.microsoftTeams;
  config: MicrosoftTeamsIntegrationConfig;
  options: MicrosoftTeamsIntegrationOptions;
}

// Integration Type Union
export interface IncidentIoIntegration extends BaseIntegration {
  type: IntegrationType.incidentIo;
  config: IncidentIoIntegrationConfig;
  options: IncidentIoIntegrationOptions;
}

export interface JiraIntegrationConfig {
  cloudId: string;
  email: string;
  organizationId: string;
}

export interface JiraSetupFormConfig {
  cloudId: string;
  email: string;
  apiToken: string;
  organizationId: string;
}

export interface JiraIntegrationOptions extends Record<string, any> { }

export interface JiraIntegration extends BaseIntegration {
  type: IntegrationType.jira;
  config: JiraIntegrationConfig;
  options: JiraIntegrationOptions;
}

export type Integration =
  | GoogleWorkspaceIntegration
  | GithubIntegration
  | GoogleCloudIntegration
  | AzureIntegration
  | AwsIntegration
  | SlackIntegration
  | Office365Integration
  | NpmIntegration
  | NewRelicIntegration
  | IncidentIoIntegration
  | NpmIntegration
  | MicrosoftTeamsIntegration
  | JiraIntegration;

// Type Guards
export function isGoogleWorkspaceIntegration(integration: Integration): integration is GoogleWorkspaceIntegration {
  return integration.type === IntegrationType.googleWorkspace;
}

export function isGithubIntegration(integration: Integration): integration is GithubIntegration {
  return integration.type === IntegrationType.github;
}

export function isGoogleCloudIntegration(integration: Integration): integration is GoogleCloudIntegration {
  return integration.type === IntegrationType.googleCloud;
}

export function isNpmIntegration(integration: Integration): integration is NpmIntegration {
  return integration.type === IntegrationType.npm;
}

export function isNewRelicIntegration(integration: Integration): integration is NewRelicIntegration {
  return integration.type === IntegrationType.newRelic;
}

export function isJiraIntegration(integration: Integration): integration is JiraIntegration {
  return integration.type === IntegrationType.jira;
}

// Enums
export enum ProductivitySuite {
  googleWorkspace = IntegrationType.googleWorkspace,
  office365 = IntegrationType.office365,
}

export enum CloudProvider {
  aws = IntegrationType.aws,
  azure = IntegrationType.azure,
  googleCloud = IntegrationType.googleCloud,
}

// Setup Types
export type IntegrationSetupKeys =
  | 'productivitySuite'
  | 'cloudProvider'
  | 'github'
  | 'slack'
  | 'npm'
  | 'newRelic'
  | 'jira';
export type IntegrationSetup = Record<IntegrationSetupKeys, boolean>;
