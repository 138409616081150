import { Box } from '@hyphen/hyphen-components';
import { Link } from 'react-router-dom';
import { ProjectEnvironmentUsage } from './ProjectEnvironmentUsage';
import { ToggleSummary } from '../../types/toggle';
import { useProjectEnvironments } from '../../providers/ProjectEnvironmentsProvider';

interface ToggleUsageItemProps {
  orgId: string;
  project: ToggleSummary['project'];
  toggleKey: string;
  toggleData: ToggleSummary['toggles'][string];
}

const ToggleUsageItem = ({ orgId, project, toggleKey, toggleData }: ToggleUsageItemProps) => {
  const { projectEnvironments } = useProjectEnvironments();

  const renderTotalUsage =
    toggleData.count > 0 ? (
      <Box fontSize="lg" fontWeight="bold" textAlign="right" color="base">
        {toggleData.count.toLocaleString('en-US', {
          notation: 'compact',
          compactDisplay: 'short',
        })}
      </Box>
    ) : (
      <Box textAlign="right" color="tertiary">
        no usage
      </Box>
    );

  return (
    <Box
      flex="auto"
      className="row-item"
      direction={{ base: 'column', tablet: 'row' }}
      borderWidth="sm 0 0 0"
      padding="lg"
      gap="2xl"
      alignItems={{ base: 'flex-start', tablet: 'center' }}
    >
      <Box
        flex="auto"
        width={{ base: '100', desktop: '40' }}
        justifyContent="space-between"
        gap="lg"
        direction="row"
      >
        <Box direction="row" gap="xs" wrap fontWeight="medium" alignItems="center">
          <Link to={`/${orgId}/${project.alternateId}/toggles/${toggleKey}`}>{toggleKey}</Link>
          <Box
            margin="0 0 0 sm"
            as="span"
            fontFamily="monospace"
            fontSize="xs"
            fontWeight="normal"
            display="inline-block"
            padding="xs"
            color="secondary"
            background="secondary"
            radius="xs"
          >
            {toggleData.type}
          </Box>
        </Box>
        {renderTotalUsage}
      </Box>
      {toggleData?.environments.length > 0 && (
        <Box
          display="grid"
          flex="auto"
          gap="md"
          fontSize="xs"
          width={{ base: '100', desktop: '70' }}
          direction={{ base: 'column', tablet: 'row' }}
          style={{
            gridTemplateColumns: 'repeat(auto-fill, minmax(160px, 1fr))',
          }}
        >
          {toggleData.environments.map((e) => (
            <ProjectEnvironmentUsage
              key={`${toggleKey}-${e.name}`}
              count={e.count}
              name={e.name}
              color={projectEnvironments?.[project.id]?.[e.name]?.color || '#ffffff'}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default ToggleUsageItem;
