import { Helmet } from 'react-helmet';
import { useOrganization } from '../../../providers/OrganizationProvider';
import { useGetOrganizationalToggleSummaryQuery, useGetTogglesQuery } from '../../../services/toggle';
import { Box, Button } from '@hyphen/hyphen-components';
import { ApiError } from '../../../components/ApiError';
import { Link } from 'react-router-dom';
import { useOrganizationAbilityContext } from '../../../components/auth/OrganizationAbilityProvider';
import { EntityNames } from '@hyphen/nucleus/dist/types';
import { mergeToggleData } from '../../../components/toggles/Toggles';
import ProjectToggleUsageList from '../../../components/toggles/ProjectToggleUsageList';
import { useMemo } from 'react';
import { ProjectEnvironmentsProvider } from '../../../providers/ProjectEnvironmentsProvider';
import { useProject } from '../../../providers/ProjectProvider';
import { Skeleton } from '../../../components/common/Skeleton';

export default function ProjectToggles() {
  const { project, isLoading: isProjectLoading } = useProject();
  const { id: orgId } = useOrganization();
  const ability = useOrganizationAbilityContext();

  const {
    data: { data: projectToggles } = {},
    isLoading: projectTogglesLoading,
    error: projectTogglesError,
  } = useGetTogglesQuery({
    organizationId: orgId,
    projectId: project?.alternateId || '',
  });

  const {
    data: usageData,
    isLoading: isUsageLoading,
    error: usageError,
  } = useGetOrganizationalToggleSummaryQuery({
    organizationId: orgId,
    projects: [project?.alternateId || ''],
  });

  const error = projectTogglesError || usageError;
  const isLoading = projectTogglesLoading || isUsageLoading;

  const mergedData = useMemo(() => {
    if (!isLoading) {
      return mergeToggleData(
        projectToggles?.map((toggle: any) => ({ ...toggle, project: project })) || [],
        usageData,
      );
    }
    return [];
  }, [isLoading, projectToggles, usageData, project]);

  const canCreateToggle = useMemo(
    () =>
      !isProjectLoading && project
        ? ability.can('update', project) && ability.can('create', EntityNames.Toggle)
        : ability.can('create', EntityNames.Toggle),
    [ability, isProjectLoading, project],
  );

  if (!isLoading && error) {
    return <ApiError error={error} />;
  }

  return (
    <>
      <Helmet>
        <title>{project ? `${project.name || 'Loading'} Feature Toggles` : 'Feature Toggles'}</title>
      </Helmet>
      <Box gap="xl">
        {isLoading ? (
          <Skeleton width="114px" height="32px" />
        ) : canCreateToggle ? (
          <div>
            <Button asChild size="sm">
              <Link to="create">Create a Toggle</Link>
            </Button>
          </div>
        ) : null}

        <ProjectEnvironmentsProvider>
          <ProjectToggleUsageList
            orgId={orgId}
            project={mergedData[0]?.project}
            toggles={mergedData[0]?.toggles}
            showAllToggles
            isLoading={isLoading}
          />
        </ProjectEnvironmentsProvider>
      </Box>
    </>
  );
}
