import { Box, Heading } from '@hyphen/hyphen-components';
import ProjectToggleUsageList from './ProjectToggleUsageList';
import { Link } from 'react-router-dom';
const ProjectsWithToggles = ({
  mergedData,
  orgId,
  isLoading,
}: {
  mergedData: any;
  orgId: string;
  isLoading: boolean;
}) => {
  return (
    <Box gap={{ base: '2xl', tablet: '5xl' }}>
      {mergedData.map(({ project, toggles }: any) => {
        if (Object.keys(toggles).length === 0) return null;

        return (
          <Box direction="column" gap="lg" flex="auto" key={project.id}>
            <Box minWidth="7xl" width="7xl" padding="lg 0 0 0" alignItems="flex-start">
              <Heading as="h2" size="sm">
                <Link to={`/${orgId}/${project.alternateId}/toggles`}>{project.name}</Link>
              </Heading>
            </Box>
            <ProjectToggleUsageList orgId={orgId} project={project} toggles={toggles} isLoading={isLoading} />
          </Box>
        );
      })}
    </Box>
  );
};

export default ProjectsWithToggles;
