import { Box, Button } from '@hyphen/hyphen-components';
import { Helmet } from 'react-helmet';
import PageHeader from '../../components/PageHeader';
import { MemberList } from '../../components/members/MemberList';
import { InviteMemberModal } from '../../components/members/InviteMemberModal';
import { Organization } from '../../services/organization';
import { useOrganization } from '../../providers/OrganizationProvider';
import { Route, Routes } from 'react-router-dom';
import { useOrganizationAbilityContext } from '../../components/auth/OrganizationAbilityProvider';
import { EntityNames } from '../../types/executionContext';
import { Link } from 'react-router-dom';
import OrgMemberDrawer from '../../components/members/OrgMemberDrawer';
import { BreadCrumbPortal } from '../../components/common/Breadcrumb';

export default function Members() {
  const { organization = {} as Organization } = useOrganization();
  const ability = useOrganizationAbilityContext();
  const canInviteMember = ability.can('create', EntityNames.Member);

  return (
    <Box width="100" gap="4xl" alignItems="flex-start">
      <Helmet>
        <title>Members</title>
      </Helmet>
      <PageHeader title="Members" description={`Manage who has access to this organization`} />
      <BreadCrumbPortal />
      {canInviteMember && (
        <Button asChild variant="primary" iconPrefix="add">
          <Link to="invite">Invite Member</Link>
        </Button>
      )}

      <Routes>
        <Route path="/*" element={<MemberList />}>
          <Route path="invite" element={<InviteMemberModal organization={organization} />} />
          <Route path=":memberId/*" element={<OrgMemberDrawer />} />
        </Route>
      </Routes>
    </Box>
  );
}
