import { ToggleType } from '../types/toggle';

export const domainRegex = /^(?!-)(?!.*--)(?!.*-\.)([a-zA-Z\d-]+\.)+[a-zA-Z]{2,}$/;

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const forbiddenWords = ['projects', 'apps', 'environments', 'envs'];

export const validateDefaultValue = function (this: { parent: { toggleType: ToggleType } }, value: any) {
  const { toggleType } = this.parent;

  switch (toggleType) {
    case ToggleType.Boolean:
      return value === 'true' || value === 'false';
    case ToggleType.String:
      return typeof value === 'string';
    case ToggleType.Number:
      return typeof value === 'number' && !isNaN(value);
    case ToggleType.Object:
      if (typeof value !== 'string') return false;
      try {
        JSON.parse(value);
        return true;
      } catch {
        return false;
      }
    default:
      return false; // Fallback for unknown types
  }
};
