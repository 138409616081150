import { Box, Button, FormikTextInputInset, toast } from '@hyphen/hyphen-components';
import { IntegrationType } from '../../../../types/integrations';
import { useNavigate } from 'react-router-dom';
import { useCreateIntegrationMutation, useTestIntegrationMutation } from '../../../../services/integrations';
import { Field, Form, Formik, FormikErrors } from 'formik';
import * as yup from 'yup';
import { ApiError } from '../../../ApiError';

interface FormFieldConfig {
  id: string;
  type: string;
  label: string;
  initialValue: string;
  helpText?: string;
}

interface IntegrationSetupFormProps<T> {
  organizationId: string;
  validationSchema: yup.ObjectSchema<any>;
  formConfig: FormFieldConfig[];
  configMapper: (values: T) => any;
  integrationType: IntegrationType;
}

export const IntegrationSetupForm = <T extends {}>({
  organizationId,
  validationSchema,
  formConfig,
  configMapper,
  integrationType,
}: IntegrationSetupFormProps<T>) => {
  const initialValues = Object.fromEntries(formConfig.map(field => [field.id, field.initialValue])) as T;

  const navigate = useNavigate();

  const [CreateIntegration, { error: createIntegrationError,  reset: resetCreate }] = useCreateIntegrationMutation();
  const [TestIntegration, { error: testIntegrationError, reset: resetTest }] = useTestIntegrationMutation();

  const handleConnect = async (values: T) => {
      resetTest()
      const integration = {
        type: integrationType,
        config: configMapper(values),
      };
      const { data, error } = (await CreateIntegration({
        orgId: organizationId,
        integration: integration as any,
      })) as any;
      if (!error) {
        navigate(`/${organizationId}/integrations/${data.id}`);
      }
    };

  const handleTest = async (values: T, setSubmitting: (isSubmitting: boolean) => void ) => {
      resetCreate()
      setSubmitting(true);
      const integration = {
        type: integrationType,
        config: configMapper(values),
      };
      const { error } = (await TestIntegration({
        orgId: organizationId,
        integration: integration as any,
      })) as any;
      if (!error) {
        toast.success('Test Successful!')
      }
      setSubmitting(false);
    };

  const formatErrors = (errors: FormikErrors<T>): Record<string, string> => {
    const formattedErrors: Record<string, string> = {};
    Object.keys(errors).forEach((key) => {
      if (errors[key as keyof T] !== undefined) {
        formattedErrors[key] = errors[key as keyof T] as string;
      }
    });
    return formattedErrors;
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleConnect} validateOnBlur={false}>
      {({ isSubmitting, values, validateForm, setFieldError, setFieldTouched, setSubmitting }) => (
        <Form noValidate>
          <Box gap="xl">
            {formConfig.map((field) => (
              <Field
                key={field.id}
                type={field.type}
                label={field.label}
                name={field.id}
                id={field.id}
                helpText={field.helpText}
                component={FormikTextInputInset}
                autoComplete="off"
                isDisabled={isSubmitting}
              />
            ))}
            <Box direction="row" justifyContent="space-between" gap="sm">
              <Button
                variant="secondary"
                fullWidth
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                onClick={async () => {
                  const errors = formatErrors(await validateForm());
                  Object.keys(errors).forEach((key: string) => {
                    setFieldTouched(key);
                    setFieldError(key, errors[key]);
                  });
                  if (!Object.keys(errors).length) {
                    handleTest(values, setSubmitting);
                  }
                }}
              >
                Test
              </Button>
              <Button fullWidth variant="primary" type="submit" isLoading={isSubmitting}>
                Connect
              </Button>
            </Box>
            {createIntegrationError || testIntegrationError ? <ApiError error={createIntegrationError || testIntegrationError} /> : null}
          </Box>
        </Form>
      )}
    </Formik>
  );
};
