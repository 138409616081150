import { useOpenClose, Popover, Box } from '@hyphen/hyphen-components';
import { ColorDot } from '../common/ColorDot';

export const ProjectEnvironmentUsage = ({ count, name, color }: { count?: number; name: string; color: string }) => {
  const { isOpen, handleOpen, handleClose } = useOpenClose();

  return (
    <Popover
      content={count && count > 0 ? 'Usage count in the last 30 days' : 'No usage in the last 30 days'}
      isOpen={isOpen}
      onClickOutside={handleClose}
      placement="top"
      contentContainerProps={{
        padding: 'md',
        background: 'inverse',
        color: 'inverse',
        gap: 'xs',
        fontSize: 'xs',
      }}
    >
      <Box
        as="button"
        type="button"
        textAlign="left"
        color={count && count > 0 ? 'base' : 'disabled'}
        borderWidth="0"
        width="100"
        height="100"
        padding="md md md xl"
        background="secondary"
        direction="row"
        gap="xs"
        alignItems="center"
        radius="sm"
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
      >
        <ColorDot color={color} />
        <Box fontFamily="monospace" flex="auto">
          {name}
        </Box>
        {count && <Box>{count.toLocaleString()}</Box>}
      </Box>
    </Popover>
  );
};
