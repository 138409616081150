import {
  Box,
  Button,
  Card,
  CheckboxInput,
  Heading,
  Pagination,
  Popover,
  Spinner,
  useOpenClose,
} from '@hyphen/hyphen-components';
import { ConnectionType } from '../../../types/connections';
import { EntityType } from '../../../types/executionContext';
import { useGetConnectionsQuery } from '../../../services/connections';
import EmptyList from '../../common/EmptyList';
import { ConnectionItem } from './ConnectionItem';
import { ConnectionAddButton } from './ConnectionAddButton';
import { Outlet } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { IntegrationType } from '../../../types/integrations';

const PAGE_SIZE = 10;

const CONNECTION_TYPES_OPTIONS = [
  { value: ConnectionType.DistributionList, label: 'Distribution List', isChecked: false },
  { value: ConnectionType.Channel, label: 'Channel', isChecked: false },
  { value: ConnectionType.PermissionGroup, label: 'Permission Group', isChecked: false },
  { value: ConnectionType.Folder, label: 'Folder', isChecked: false },
  { value: ConnectionType.Tag, label: 'Tag', isChecked: false },
  { value: ConnectionType.CloudWorkspace, label: 'Cloud Workspace', isChecked: false },
  { value: ConnectionType.CodeRepository, label: 'Code Repository', isChecked: false },
  { value: ConnectionType.ProjectBoard, label: 'Project Board', isChecked: false },
  { value: ConnectionType.CodeRegistry, label: 'Package Registry', isChecked: false },
  { value: ConnectionType.User, label: 'User Account', isChecked: false },
  { value: ConnectionType.IncidentManagement, label: 'Incident Management', isChecked: false },
];

interface ConnectionsListProps {
  organizationId: string;
  filter?: {
    type?: ConnectionType;
    entityType?: EntityType;
    entity?: {
      type: EntityType;
      id: string;
      name: string;
    };
    project?: {
      id: string;
      name: string;
    };
    integrationTypes?: IntegrationType[];
  };
  showManualFilter?: boolean;
  showAddButton?: boolean;
  showEntity?: boolean;
}

export const ConnectionsList = ({
  organizationId,
  filter,
  showManualFilter = true,
  showAddButton = true,
  showEntity = false,
}: ConnectionsListProps) => {
  const [pollingInterval, setPollingInterval] = useState(0);
  const { isOpen: isTypesFilterOpen, handleClose: closeTypesFilter, handleOpen: openTypesFilter } = useOpenClose();
  const [pageNum, setPageNumber] = useState<number>(1);
  const [types, setTypes] =
    useState<{ value: ConnectionType; label: string; isChecked: boolean }[]>(CONNECTION_TYPES_OPTIONS);
  const [selectedTypes, setSelectedTypes] = useState<ConnectionType[]>([]);

  const { data: connections, isLoading: isConnectionsLoading } = useGetConnectionsQuery(
    {
      organizationId,
      entityIds: filter?.entity?.id ? [filter?.entity?.id] : undefined,
      types: selectedTypes,
      integrationTypes: filter?.integrationTypes,
      pageSize: PAGE_SIZE,
      pageNum,
    },
    { pollingInterval },
  );

  useEffect(() => {
    if (
      connections &&
      connections.data.length &&
      connections.data.some((connection) => connection.status === 'Pending')
    ) {
      setPollingInterval(3000);
    } else {
      setPollingInterval(0);
    }
  }, [setPollingInterval, connections]);

  const handleOnTypeChange = useCallback(
    (typeChanged: { value: ConnectionType; label: string; isChecked: boolean }) => {
      const updatedTypesData = types.map((conn) => {
        if (conn.value === typeChanged.value) {
          const isChecked = typeChanged.isChecked;
          return { ...conn, isChecked: !isChecked };
        }
        return conn;
      });
      setTypes(updatedTypesData);
    },
    [types],
  );

  const applyTypesFilter = useCallback(() => {
    const flatSelectedTypes = types.filter((item) => item.isChecked).map((item) => item.value);
    setSelectedTypes(flatSelectedTypes);
    closeTypesFilter();
  }, [types, closeTypesFilter]);

  if (isConnectionsLoading || !connections) {
    return <Spinner />;
  }

  const typesFilterContent = (
    <Box gap="lg">
      <Box gap="sm" overflow="auto" className="scroll-bar-thin" maxHeight="7xl">
        {types.map((conn) => (
          <CheckboxInput
            id={`conn-${conn.value}`}
            label={conn.label}
            value={conn.value}
            key={conn.value}
            size="sm"
            isChecked={conn.isChecked}
            onChange={() => handleOnTypeChange(conn)}
          />
        ))}
      </Box>
      <Button variant="primary" size="sm" onClick={applyTypesFilter} disabled={isConnectionsLoading}>
        Apply
      </Button>
    </Box>
  );

  return (
    <Box width="100" gap="xl">
      {connections.data.length > 0 ? (
        <Card>
          <Card.Section>
            <Box direction="row" gap="2xl" justifyContent="space-between" alignItems="center">
              <Box gap="2xs">
                <Heading as="h4" size="sm">
                  Integration Connections
                </Heading>
                {showManualFilter && (
                  <Box display="block" margin="lg 0 0 0">
                    <Popover
                      content={typesFilterContent}
                      isOpen={isTypesFilterOpen}
                      placement="bottom-start"
                      offsetFromTarget={4}
                      onClickOutside={closeTypesFilter}
                      hasArrow={false}
                      contentContainerProps={{
                        padding: 'sm',
                        gap: 'xs',
                        borderWidth: 'sm',
                        borderColor: 'default',
                        width: '100',
                        maxWidth: '7xl',
                      }}
                    >
                      <Button
                        variant="secondary"
                        size="sm"
                        iconSuffix="caret-sm-down"
                        onClick={!isTypesFilterOpen ? openTypesFilter : closeTypesFilter}
                      >
                        types
                      </Button>
                    </Popover>
                  </Box>
                )}
              </Box>
              {showAddButton && <ConnectionAddButton organizationId={organizationId} entity={filter?.entity} />}
            </Box>
          </Card.Section>

          <Card.Section padding="0">
            {connections.data.map((connection) => (
              <ConnectionItem key={connection.id} connection={connection} showEntity={showEntity} />
            ))}
          </Card.Section>
          {connections.data.length > 0 && (connections.total ?? 0) > PAGE_SIZE && (
            <Card.Section>
              <Pagination
                activePage={pageNum}
                itemsPerPage={PAGE_SIZE}
                onChange={setPageNumber}
                totalItemsCount={connections.total ?? 0}
                isCompact
                arePagesVisible
                numberOfPagesDisplayed={3}
              />
            </Card.Section>
          )}
        </Card>
      ) : (
        <EmptyList
          title="No Connections"
          description="Connection between an integration and an entity such as a project, app, team, or member"
        >
          {showAddButton && <ConnectionAddButton organizationId={organizationId} entity={filter?.entity} />}
        </EmptyList>
      )}
      <Outlet />
    </Box>
  );
};
