import { createMongoAbility, MongoAbility } from '@casl/ability';
import { useGetExecutionContextQuery } from '../../services/executionContext';
import { createContext, PropsWithChildren, useContext } from 'react';
import { useOrganization } from '../../providers/OrganizationProvider';
import { EntityNames, EntityType } from '../../types/executionContext';

export const OrganizationAbilityContext = createContext({} as MongoAbility);

// TODO: Consider importing nucleus to take advantage of not having to redefine these things
const detectSubjectType = (obj: { id: string; __caslSubjectType__?: EntityType } & Object): EntityType => {
  if (obj.__caslSubjectType__) {
    return obj.__caslSubjectType__;
  }
  const prefix = obj.id?.split('_')[0];
  switch (prefix) {
    case 'bila': return EntityNames.BillingAccount;
    case 'evnt': return EntityNames.Event;
    case 'memb': return EntityNames.Member;
    case 'org': return EntityNames.Organization;
    case 'team': return EntityNames.Team;
    case 'api': return EntityNames.ApiKey;
    case 'scrt': return EntityNames.ApiKeySecret;
    case 'oint': return EntityNames.OrganizationIntegration;
    case 'code': return EntityNames.LinkCode;
    case `lqr`: return EntityNames.LinkQrCode;
    case 'doma': return EntityNames.Domain;
    case 'proj': return EntityNames.Project;
    case 'evr': return EntityNames.Environment;
    case 'pevr': return EntityNames.ProjectEnvironment;
    case 'env': return EntityNames.Env;
    case 'app': return EntityNames.App;
    case 'conn': return EntityNames.Connection;
    case 'acc': return EntityNames.Access;
    case 'tggl': return EntityNames.Toggle;
    default:
      console.error(`Unknown subject type for ${obj.id}`);
      return 'uknown' as any;
  }
};

export const OrganizationAbilityProvider = (props: PropsWithChildren) => {
  const { children } = props;
  const { id } = useOrganization();
  const { data } = useGetExecutionContextQuery(id);
  const rules = data?.member?.rules || [];
  const ability = createMongoAbility(rules, { detectSubjectType });

  // Debugging example - if you cannot figure out why an ability is not working as expected.
  // console.log(ability.relevantRuleFor('read', 'Article'));

  return <OrganizationAbilityContext.Provider value={ability}>{children}</OrganizationAbilityContext.Provider>;
};

export const useOrganizationAbilityContext = () => {
  return useContext(OrganizationAbilityContext);
};
