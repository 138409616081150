import { JiraIntegration } from '../../../types/integrations';
import { IntegrationCard } from './common/IntegrationCard';
import { INTEGRATION_NAME_MAP } from '../../../constants/integrations';

interface JiraDetailsProps {
  integration: JiraIntegration;
}

export const JiraDetails = ({ integration }: JiraDetailsProps) => {
  const jiraManagementUrl = `https://${integration.config.cloudId}.atlassian.net/admin`;

  return (
    <IntegrationCard
      status="Connected"
      details={[
        { label: 'Cloud ID', value: integration.config.cloudId },
        { label: 'Email', value: integration.config.email },
        { label: 'Organization ID', value: integration.config.organizationId },
      ]}
      integration={integration}
      manage={{ manageUrl: jiraManagementUrl, manageLabel: INTEGRATION_NAME_MAP.jira }}
    />
  );
};

export default JiraDetails;
