import QRCodeStyling from 'qr-code-styling';
import { Code } from '../../types/domain';
import { Box } from '@hyphen/hyphen-components';
import { useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import { CreateQRSchema } from './CreateQRForm';

type PreviewQRProps = {
  code: Code;
};

const qrCode = new QRCodeStyling({
  width: 300,
  height: 300,
  type: 'svg',
});

export const PreviewQRCode = ({ code }: PreviewQRProps) => {
  const url = `https://${code.domain}/${code.code}`;
  const qrCodeRef = useRef<HTMLElement>();
  const { values } = useFormikContext<CreateQRSchema>();
  const [logoBase64, setLogoBase64] = useState<string | undefined>(undefined);

  const fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  useEffect(() => {
    const updateLogoBase64 = async () => {
      if (values.logo instanceof File) {
        const base64 = await fileToBase64(values.logo);
        setLogoBase64(base64);
      } else {
        setLogoBase64(values.logo);
      }
    };

    updateLogoBase64();
  }, [values.logo]);

  useEffect(() => {
    qrCode.update({
      data: url,
      image: logoBase64,
      dotsOptions: {
        color: values?.color,
      },
      cornersSquareOptions: {
        color: values?.color,
      },
      cornersDotOptions: {
        color: values?.color,
      },
      backgroundOptions: {
        color: values?.backgroundColor,
      },
    });
    qrCode.append(qrCodeRef.current);
  }, [values, url, logoBase64]);

  return <Box ref={qrCodeRef}></Box>;
};
