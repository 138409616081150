import { Helmet } from 'react-helmet';
import PageHeader from '../../../components/PageHeader';
import { useParams } from 'react-router-dom';
import { useOrganization } from '../../../providers/OrganizationProvider';
import { ScrollableMainLayout } from '../../../components/ScrollableMainLayout';
import { Link } from 'react-router-dom';
import CreateToggleForm from '../../../components/toggles/CreateToggleForm';
import { useGetProjectsQuery } from '../../../services/projects';
import { Alert, Spinner } from '@hyphen/hyphen-components';
import { useOrganizationAbilityContext } from '../../../components/auth/OrganizationAbilityProvider';
import { EntityNames } from '@hyphen/nucleus/dist/types';
import { BreadCrumbPortal } from '../../../components/common/Breadcrumb';

const PAGE_SIZE = 50;

export const CreateToggle: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { organization } = useOrganization();
  const organizationId = organization?.id || '';

  const { data, isLoading } = useGetProjectsQuery({ pageSize: PAGE_SIZE, pageNum: 1, organizationId });

  // Generate project options if data is available
  const projectOptions =
    data?.data.map((project) => ({
      label: project.name,
      value: project.id,
    })) || [];

  // Find the selected project if projectId is defined
  const selectedProject = projectId ? data?.data.find((project) => project.alternateId === projectId) : undefined;

  const ability = useOrganizationAbilityContext();

  // If they can create toggles, show the form and let them pick a project first
  const canCreateProjectToggle = (() => {
    if (selectedProject) {
      return ability.can('update', selectedProject) && ability.can('create', EntityNames.Toggle);
    }
    return ability.can('create', EntityNames.Toggle);
  })();

  if (!canCreateProjectToggle) {
    const message = selectedProject
      ? `You do not have permission to create feature toggles for this project. Contact a project owner to grant you access.`
      : `You do not have permission to create feature toggles. Contact a project owner to grant you access.`;

    return (
      <ScrollableMainLayout>
        <Alert variant="danger" title="Permission Denied" hasIcon message={message} maxWidth="9xl" />
        {selectedProject ? (
          <Link to={`/${organization?.id}/${projectId}`}>Back to Project</Link>
        ) : (
          <Link to={'..'}>Go back</Link>
        )}
      </ScrollableMainLayout>
    );
  }

  return (
    <ScrollableMainLayout>
      <Helmet>
        <title>Create Feature Toggle</title>
      </Helmet>
      <PageHeader
        title="Create Feature Toggle"
        description="Control the visibility of features in your applications"
      />
      <BreadCrumbPortal entity={selectedProject} />
      {isLoading ? (
        <Spinner />
      ) : (
        <CreateToggleForm
          organizationId={organizationId}
          selectedProject={selectedProject}
          projectOptions={projectOptions}
        />
      )}
    </ScrollableMainLayout>
  );
};

export default CreateToggle;
