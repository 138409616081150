import { Box, Button, toast, FormikTextInput } from '@hyphen/hyphen-components';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { InferType } from 'yup';
import { useCallback } from 'react';
import { useCreateProjectMutation } from '../../services/projects';
import { DependentFormikField } from '../common/form/DependentFormikField';
import { ApiError } from '../ApiError';
import { useNavigate } from 'react-router-dom';
import { generateSlug } from '../../utils/generateSlug';
import { forbiddenWords } from '../../utils/validations';

const createProjectSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  alternateId: yup
    .string()
    .matches(/^[a-zA-Z0-9-_]+$/, 'Invalid Alternate ID')
    .notOneOf(forbiddenWords, `Can not contain the following words: ${forbiddenWords.join(', ')}`)
    .required('Alternate ID is required'),
});

type CreateProjectSchema = InferType<typeof createProjectSchema>;

const initialValues = {
  name: '',
  alternateId: '',
};

export const CreateProjectForm = ({ organizationId }: { organizationId: string }) => {
  const navigate = useNavigate();
  const [createProject, { error }] = useCreateProjectMutation();

  const handleCreateProject = useCallback(
    async (values: CreateProjectSchema) => {
      try {
        const body = {
          name: values.name,
          alternateId: values.alternateId,
          organizationId,
        };
        const { error, data } = await createProject(body);
        if (!error && data) {
          navigate(`/${organizationId}/${data.alternateId}`);
          toast.success('Project created');
        } else {
          console.error('Failed to create project', error);
          toast.error('Failed to create project');
        }
      } catch (e) {
        console.error(`Failed to create project`, e);
        toast.error('Failed to create project');
      }
    },
    [createProject, navigate, organizationId],
  );

  return (
    <Formik initialValues={initialValues} validationSchema={createProjectSchema} onSubmit={handleCreateProject} validateOnBlur={false}>
      {({ errors, values, isSubmitting }) => (
        <Form noValidate>
          <Box gap="4xl">
            <Field
              id="name"
              name="name"
              label="Name"
              component={FormikTextInput}
              error={errors.name}
              isRequired
              autoFocus
            />
            <DependentFormikField
              id="alternateId"
              helpText="Used as the name on the command line, alphanumeric and dashes only, no spaces"
              name="alternateId"
              label="Alternate ID"
              component={FormikTextInput}
              error={errors.alternateId}
              isRequired
              dependentField="name"
              targetField="alternateId"
              generateValue={generateSlug}
            />

            {error ? <ApiError error={error} /> : null}
            <Box direction="row" gap="sm">
              <Button variant="primary" isLoading={isSubmitting} type="submit">
                Create Project
              </Button>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
