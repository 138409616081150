import { Helmet } from 'react-helmet';
import PageHeader from '../../components/PageHeader';
import { ScrollableMainLayout } from '../../components/ScrollableMainLayout';
import { useOrganization } from '../../providers/OrganizationProvider';
import { useGetOrganizationalToggleSummaryQuery, useGetOrganizationTogglesQuery } from '../../services/toggle';
import { Box, Icon } from '@hyphen/hyphen-components';
import { ReactSVG } from 'react-svg';
import ProjectsWithToggles from './ProjectsWithToggles';
import { BreadCrumbPortal } from '../common/Breadcrumb';
import EmptyList from '../common/EmptyList';
import { Link } from 'react-router-dom';

export const mergeToggleData = (allToggles: any, usageData: any) => {
  const mergedData =
    usageData?.map((project: any) => {
      const projectToggles = allToggles?.filter((toggle: any) => toggle.project.id === project.project.id) || [];
      const mergedToggles = { ...project.toggles }; // Shallow clone project.toggles

      projectToggles.forEach((toggle: any) => {
        if (!mergedToggles[toggle.key]) {
          mergedToggles[toggle.key] = { count: 0, environments: [], type: toggle.type };
        } else {
          mergedToggles[toggle.key] = { ...mergedToggles[toggle.key], type: toggle.type };
        }
      });

      return {
        ...project,
        toggles: mergedToggles,
      };
    }) || [];

  allToggles?.forEach((toggle: any) => {
    const projectIndex = mergedData?.findIndex((project: any) => project.project.id === toggle.project.id);
    if (projectIndex === -1) {
      mergedData?.push({
        project: toggle.project,
        toggles: {
          [toggle.key]: { count: 0, environments: [], type: toggle.type },
        },
      });
    } else {
      if (mergedData && !mergedData[projectIndex].toggles[toggle.key]) {
        mergedData[projectIndex].toggles = {
          ...mergedData[projectIndex].toggles,
          [toggle.key]: { count: 0, environments: [], type: toggle.type },
        };
      } else {
        mergedData[projectIndex].toggles[toggle.key] = {
          ...mergedData[projectIndex].toggles[toggle.key],
          type: toggle.type,
        };
      }
    }
  });

  // sort by most used
  mergedData.forEach((project: any) => {
    project.toggles = Object.fromEntries(
      Object.entries(project.toggles).sort(([, a]: any, [, b]: any) => b.count - a.count),
    );
  });

  return mergedData;
};

export const Toggles = () => {
  const { id: orgId } = useOrganization();

  const { data: { data: allToggles } = {}, isLoading: allTogglesLoading } = useGetOrganizationTogglesQuery({
    organizationId: orgId,
  });

  const { data: usageData, isLoading: isUsageLoading } = useGetOrganizationalToggleSummaryQuery({
    organizationId: orgId,
  });

  const isLoading = allTogglesLoading || isUsageLoading;

  const mergedData = mergeToggleData(allToggles, usageData);

  return (
    <ScrollableMainLayout fullWidth>
      <Helmet>
        <title>Toggle Feature Flags</title>
      </Helmet>
      <PageHeader
        title={
          <Box maxHeight="5xl" width="6xl">
            <ReactSVG
              src="/images/toggle-logo.svg"
              beforeInjection={(svg) => svg.setAttribute('style', 'width: 100%; height: 100%')}
            />
          </Box>
        }
        description="A seamless feature release management system built on the OpenFeature standard"
      />
      <BreadCrumbPortal />
      {!isLoading && allToggles && allToggles.length === 0 && (
        <EmptyList
          title="No Toggles"
          description={
            <>
              Create a feature toggle within a <Link to={`/{orgId}`}>project</Link> to get started
            </>
          }
        >
          <Box
            fontSize="xs"
            background="secondary"
            padding={{ base: 'lg', tablet: 'xl', desktop: 'xl 2xl' }}
            gap="md"
            radius="md"
          >
            <Box
              direction="row"
              gap={{ base: 'xl', tablet: '2xl', desktop: '3xl' }}
              alignItems="center"
              style={{ lineHeight: 'var(--size-line-height-none)' }}
            >
              <Icon name="document" color="tertiary" size="md" />
              <a href="https://docs.hyphen.ai/docs/toggle-quickstart" target="_blank" rel="noreferrer">
                Quickstart Guide
              </a>
              <a href="https://docs.hyphen.ai/docs/toggle-concepts" target="_blank" rel="noreferrer">
                Key Concepts
              </a>
              <a href="https://docs.hyphen.ai/docs/toggle-sdks#/" target="_blank" rel="noreferrer">
                SDKs
              </a>
            </Box>
          </Box>
        </EmptyList>
      )}
      <ProjectsWithToggles mergedData={mergedData} orgId={orgId} isLoading={isLoading} />
    </ScrollableMainLayout>
  );
};

export default Toggles;
