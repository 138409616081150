import { IntegrationSetupForm } from '../common/IntegrationSetupForm';
import { IntegrationType, NewRelicSetupFormConfig } from '../../../../types/integrations';
import * as yup from 'yup';

interface NewRelicSetupFormProps {
  organizationId: string;
}

const newRelicSetupFormSchema = yup.object().shape({
  token: yup.string().required('Required'),
  accountId: yup.number().optional(),
  accountName: yup.string().optional(),
});

const newRelicFormConfig = [
  {
    id: 'token',
    type: 'password',
    label: 'New Relic API Key',
    initialValue: '',
    helperText: 'Enter your New Relic User API key',
  },
];

//TODO: should this have the optional parametter as well?
export const NewRelicSetupForm = ({ organizationId }: NewRelicSetupFormProps) => (
  <IntegrationSetupForm<NewRelicSetupFormConfig>
    organizationId={organizationId}
    validationSchema={newRelicSetupFormSchema}
    formConfig={newRelicFormConfig}
    configMapper={(values: NewRelicSetupFormConfig) => ({
      token: values.token,
    })}
    integrationType={IntegrationType.newRelic}
  />
);
