import { Box } from '@hyphen/hyphen-components';
import React, { PropsWithChildren } from 'react';
import { NavLink } from 'react-router-dom';

interface TabNavProps extends PropsWithChildren {
  className?: string;
}

export const TabNav = ({ className, children }: TabNavProps) => (
  <Box
    style={{
      marginLeft: 'calc(-1 * var(--size-spacing-3xl))',
      marginRight: 'calc(-1 * var(--size-spacing-3xl))',
      overflowX: 'auto',
    }}
    padding="0 3xl"
    className={className}
  >
    <Box
      alignSelf={{ tablet: 'flex-start' }}
      direction="row"
      fontSize="sm"
      background="secondary"
      radius="md"
      padding="sm"
      gap="sm"
      style={{ flexShrink: '0', width: 'fit-content' }}
    >
      {children}
    </Box>
  </Box>
);

interface TabNavLinkProps {
  to: string;
  children: React.ReactNode;
  countLabel?: string;
}

export const TabNavLink = ({ to, children, countLabel }: TabNavLinkProps) => {
  return (
    <NavLink
      end
      to={to}
      className="display-flex flex-auto tab-nav align-items-center g-sm justify-content-center"
      style={{ flexShrink: '0' }}
    >
      {children}{' '}
      {countLabel && (
        <Box
          background="tertiary"
          fontSize="xs"
          padding="xs sm"
          radius="sm"
          alignItems="center"
          justifyContent="center"
          fontWeight="medium"
          style={{ lineHeight: '1' }}
        >
          {countLabel}
        </Box>
      )}
    </NavLink>
  );
};
