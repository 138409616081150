import { Helmet } from 'react-helmet';
import { Box } from '@hyphen/hyphen-components';
import { useConnection } from '../../../../providers/ConnectionProvider';
import { Skeleton } from '../../../../components/common/Skeleton';
import ConnectionAccessList from '../../../../components/integrations/connections/ConnectionAccessList';
import { useLocation } from 'react-router-dom';
import { CONNECTION_NAME_MAP } from '../../../../constants/integrations';

export default function ConnectionAccess() {
  const { connection, isLoading } = useConnection();
  const location = useLocation();

  if (isLoading) {
    return (
      <Box>
        <Skeleton width="100" height="180px" />
      </Box>
    );
  }

  if (connection) {
    return (
      <>
        <Helmet
          title={`${CONNECTION_NAME_MAP[connection.type] || 'Loading'} ${
            location.pathname.includes('/access') ? 'Access' : 'Members'
          }`}
        />
        <ConnectionAccessList connection={connection} />
      </>
    );
  }

  return <Box>No connection found</Box>;
}
