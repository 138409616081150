import { Box, Button, FormikTextInput } from '@hyphen/hyphen-components';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { InferType } from 'yup';
import * as yup from 'yup';
import { ApiError } from '../ApiError';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

const createOrganizationSchema = yup.object().shape({
  name: yup.string().required('Company name is required'),
  email: yup.string().required('Email is required'),
  promoCode: yup.string().default(''),
});
export type CreateOrganizationFormSchema = InferType<typeof createOrganizationSchema>;

interface CreateOrganizationFormProps {
  handleSubmit: (values: CreateOrganizationFormSchema, options: FormikHelpers<CreateOrganizationFormSchema>) => void;
  error?: any;
}

export const CreateOrganizationForm = ({ handleSubmit, error }: CreateOrganizationFormProps) => {
  const location = useLocation();
  const query = useMemo(() => new URLSearchParams(location.search), [location.search]);

  return (
    <Box
      width="420px"
      maxWidth="100%"
      height="100"
      gap="4xl"
      alignItems="center"
      justifyContent="center"
      padding={{ base: '2xl', tablet: '0' }}
      className="zoomIn"
    >
      <Box gap="2xl">
        <Box as="h1" fontSize={{ base: 'lg', tablet: 'xl' }} textAlign="center" fontWeight="bold">
          Activate your organization
        </Box>
        <Box as="p" color="secondary" fontSize="xs" textAlign="center">
          An organization is a shared workspace for managing projects and team collaboration under one billing
          account.
        </Box>
      </Box>
      <Formik
        initialValues={{
          name: query.get('orgName') || '',
          email: query.get('billingEmail') || '',
          promoCode: query.get('code') || '',
        }}
        validationSchema={createOrganizationSchema}
        onSubmit={handleSubmit}
        validateOnBlur={false}
      >
        {({ isSubmitting }) => (
          <Form noValidate className="w-100">
            <Box gap="xl" width="100" alignItems="center">
              <Field
                type="text"
                label="Organization Name"
                hideLabel
                placeholder="Organization Name"
                name="name"
                id="name"
                component={FormikTextInput}
                autoComplete="off"
                isDisabled={isSubmitting}
              />
              <Field
                type="text"
                label="Billing Email"
                hideLabel
                placeholder="Billing Email"
                name="email"
                id="email"
                component={FormikTextInput}
                autoComplete="off"
                isDisabled={isSubmitting}
              />
              <Field
                type="text"
                label="Promo Code"
                hideLabel
                placeholder="Promo Code"
                name="promoCode"
                id="promoCode"
                component={FormikTextInput}
                autoComplete="off"
                isDisabled={isSubmitting}
              />

              {error && (
                <Box width="100">
                  <ApiError error={error} title="Error creating organization" />
                </Box>
              )}
              <Button
                fullWidth
                variant="primary"
                type="submit"
                isLoading={isSubmitting}
                className="registration-button"
              >
                Continue
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
