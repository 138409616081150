import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Prism from 'prismjs';

import 'prismjs/themes/prism-tomorrow.min.css';
import 'prismjs/components/prism-typescript';
import 'prismjs/components/prism-go';
import 'prismjs/components/prism-csharp';

const removeFrontmatter = (text: string) => {
  return text.replace(/---[\s\S]*?---/, '').trim();
};

const MarkdownRenderer = ({ filePath }: { filePath: string }) => {
  const [markdownContent, setMarkdownContent] = useState('');

  useEffect(() => {
    const loadMarkdown = async () => {
      try {
        const response = await fetch(filePath);
        const text = await response.text();
        const cleanedMarkdown = removeFrontmatter(text);
        setMarkdownContent(cleanedMarkdown);
      } catch (error) {
        console.error('Error loading markdown file:', error);
      }
    };
    loadMarkdown();
  }, [filePath]);

  useEffect(() => {
    Prism.highlightAll();
  }, [markdownContent]);

  return (
    <ReactMarkdown remarkPlugins={[remarkGfm]}>
      {markdownContent}
    </ReactMarkdown>
  );
};

export default MarkdownRenderer;